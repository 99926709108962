
import React, { useState } from "react";
import axios from "axios";
import ChatHeader from "./Chatheader";
import { Toaster, toast } from "react-hot-toast";

const Feedback = () => {
  const [rating, setRating] = useState({ accuracy: 0, clarity: 0, helpfulness: 0 });
  const [comments, setComments] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleRating = (category, value) => {
    setRating((prevRating) => ({ ...prevRating, [category]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!rating.accuracy && !rating.clarity && !rating.helpfulness) {
      toast.error("Please provide ratings for accuracy, clarity, and helpfulness.");
      return;
    }
  
    if (!rating.accuracy) {
      toast.error("Please provide a rating for accuracy.");
      return;
    }
  
    if (!rating.clarity) {
      toast.error("Please provide a rating for clarity.");
      return;
    }
  
    if (!rating.helpfulness) {
      toast.error("Please provide a rating for helpfulness.");
      return;
    }
    const formData = new FormData();
    formData.append("accuracy_rating", rating.accuracy);
    formData.append("clarity_rating", rating.clarity);
    formData.append("helpfulness_rating", rating.helpfulness);
    formData.append("comments", comments);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/api/feedback`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        console.log(response,"feedback")
        toast.success(response.data.message)
        // alert("Thank you for your feedback!");
      } else {
        // alert("Something went wrong. Please try again.");
        toast.error(response.data.message)

      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      alert("Error submitting feedback. Please try again later.");
    }
  };

  const styles = {
    wrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      backgroundColor: "white",
    },
    container: {
      maxWidth: "600px",
      padding: "20px",
      borderRadius: "8px",
      backgroundColor: " #f9f9f9",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      fontFamily: "Arial, sans-serif",
    },
    header: {
      color: "#333",
      textAlign: "center",
    },
    text: {
      textAlign: "center",
      color: "#666",
    },
    section: {
      marginTop: "20px",
    },
    ratingItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "10px 0",
    },
    textarea: {
      width: "100%",
      padding: "10px",
      borderRadius: "4px",
      border: "1px solid #ddd",
      resize: "none",
    },
    button: {
      display: "block",
      width: "100%",
      padding: "10px",
      border: "0px solid transparent",
      color: "#ffffff",
      backgroundColor: "#756cf5",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "16px",
      marginTop: "20px",
      transition: "all 0.4s ease-in-out",
    },
    buttonHover: {
      backgroundColor: "#6058c4",
    },
  };

  return (
    <div >
      <Toaster
      position="top-right"
      reverseOrder={false}
      toastOptions={{
        style: { marginTop: "50px" },
      }}
    />
      <div  className={`flex flex-col  bg-white duration-300 ${
        isSidebarOpen ? "md:ml-[350px] md:w-[calc(100%-350px)]" : "w-full"
      }`} style={{
     marginTop:"90px"
      // minHeight: "100vh",
      }}> <ChatHeader/></div>
          
 
    <div style={styles.wrapper}>
      <div style={styles.container}>
        <h3  style={styles.header}>We Value Your Feedback!</h3>
        <p className="mt-2" style={styles.text}>
          Your feedback helps us improve MedicalGPT and enhance your experience.
          Please take a moment to share your thoughts about the response you received.
        </p>

        <form onSubmit={handleSubmit} style={styles.section}>
          <div style={styles.section}>
            <h4 style={{marginTop:"40px"}}>Rate Us</h4>

            <div style={styles.ratingItem}>
              <label>1. How accurate did you find the response? <span style={{ color: "red" }}>*</span></label>
              <StarRating value={rating.accuracy} onChange={(value) => handleRating("accuracy", value)} />
            </div>

            <div style={styles.ratingItem}>
              <label>2. Was the response clear and easy to understand? <span style={{ color: "red" }}>*</span></label>
              <StarRating value={rating.clarity} onChange={(value) => handleRating("clarity", value)} />
            </div>

            <div style={styles.ratingItem}>
              <label>3. Did the response help address your question? <span style={{ color: "red" }}>*</span></label>
              <StarRating value={rating.helpfulness} onChange={(value) => handleRating("helpfulness", value)} />
            </div>
          </div>

          <div style={styles.section}>
            <label className="mt-4 font-semibold">Any other feedback or comments?</label>
            <textarea
              style={styles.textarea}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              placeholder="Type your feedback here..."
              rows="4"
            />
          </div>

          <div className="form-cta">
            <button
              type="submit"
              style={styles.button}
              onMouseOver={(e) => (e.target.style.backgroundColor = styles.buttonHover.backgroundColor)}
              onMouseOut={(e) => (e.target.style.backgroundColor = styles.button.backgroundColor)}
              aria-label="post comment"
              className="btn btn--ocotonary"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
    </div>
  );
};

const StarRating = ({ value, onChange }) => {
  const stars = Array(5).fill(0);

  const starStyles = {
    starRating: {
      display: "flex",
    },
    star: {
      fontSize: "24px",
      color: "#ddd",
      cursor: "pointer",
      transition: "color 0.3s",
    },
    filledStar: {
      color: "#ffc107",
    },
  };

  return (
    <div style={starStyles.starRating}>
      {stars.map((_, index) => (
        <span
          key={index}
          style={{ ...starStyles.star, ...(index < value ? starStyles.filledStar : {}) }}
          onClick={() => onChange(index + 1)}
        >
          ★
        </span>
      ))}
    </div>
  );
};

export default Feedback;
