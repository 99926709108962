// import React, { useState, useEffect, useRef } from "react";
// import { AiOutlineSend } from "react-icons/ai";
// import { IoMdAttach } from "react-icons/io";
// import SpeechRecognition, {
//   useSpeechRecognition,
// } from "react-speech-recognition";
// import { FaMicrophone } from "react-icons/fa";
// import "./InputBox.css";
// import { useTranslation } from "react-i18next";

// export default function InputBox({ onSubmitQuestion, result }) {
//   const [question, setQuestion] = useState("");
//   const [recording, setRecording] = useState(false);
//   const [file, setFile] = useState(null);

//   const fileInputRef = useRef(null);

//   const { t } = useTranslation();
//   const {
//     transcript,
//     listening,
//     browserSupportsSpeechRecognition,
//     startListening,
//     stopListening,
//   } = useSpeechRecognition();

//   useEffect(() => {
//     if (transcript !== "") {
//       setQuestion(transcript);
//     }
//   }, [transcript]);

//   const handleInputChange = (event) => {
//     setQuestion(event.target.value);
//   };

//   const handleInputSubmit = () => {
//     if (!question.trim()) {
//       alert("Please enter a question before submitting.");
//       return;
//     }

//     onSubmitQuestion(question);
//     console.log("Here is the question: ", question);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();

//     if (!question.trim()) {
//       alert("Please enter a question before submitting.");
//       return;
//     }

//     onSubmitQuestion(question);
//     console.log("Here is the question: ", question);
//   };

//   const handleToggleListening = () => {
//     if (listening) {
//       SpeechRecognition.stopListening();
//     } else {
//       SpeechRecognition.startListening();
//     }

//     setRecording(!recording);
//   };

//   useEffect(() => {
//     if (!listening && question.trim() !== "") {
//       // Call the API when recording has stopped and there is a question
//       onSubmitQuestion(question);
//     }
//   }, [listening]);

//   useEffect(() => {
//     if (transcript !== "") {
//       setQuestion(transcript);
//     }
//   }, [transcript]);
//   const handleFileIconClick = () => {
//     fileInputRef.current.click();
//   };
//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//   };
//   return (
//     <div className="">
//       <div className="lg:w-[900px] md:w-[600px] w-[300px]  rounded">
//         <form
//           className="border-2 rounded-xl shadow-md  rounded-xl"
//           style={{ borderRadius: "40px" }}
//           // style={{ backgroundColor: "#005EA3" }}
//           onSubmit={handleSubmit}
//         >
//           <label
//             htmlFor="default-search"
//             className="mb-2 text-sm font-medium text-red-300 sr-only dark:text-white"
//           >
//             Search
//           </label>
//           <div className="relative">
//             <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"></div>
//             <input
//               className="block lg:w-[800px] md:w-[500px]  w-200px p-2 pl-10 pr-20 text-sm text-dark outline-none"
//               style={{ border: "none", color: "#C5C6C7" }}
//               placeholder="Ask Question"
//               // {t("placholder")}
//               required
//               value={question}
//               onChange={handleInputChange}
//             />
//             <input
//               type="file"
//               ref={fileInputRef}
//               style={{ display: "none" }}
//               onChange={handleFileChange}
//             />
//             <button
//               type="button"
//               className=" absolute right-16 bottom-2.5 font-large rounded-lg lg:text-2xl md:text-2xl text-xl  "
//               onClick={handleFileIconClick}
//               style={{ color: "#C5C6C7", marginTop: "120px" }}
//             >
//               <IoMdAttach className="mt-5" />
//             </button>
//             <button
//               type="submit"
//               className="  absolute right-8 bottom-2.5 font-large rounded-lg lg:text-2xl md:text-2xl text-xl   "
//               style={{ color: "#C5C6C7" }}
//             >
//               <AiOutlineSend className="mt-5" />
//             </button>
//             <div>
//               <button
//                 type="button"
//                 className={` absolute right-1 bottom-2.5 font-large rounded-lg lg:text-2xl md:text-2xl text-xl  ${
//                   recording ? "recording" : ""
//                 }`}
//                 onClick={handleToggleListening}
//                 style={{ color: "#C5C6C7" }}
//               >
//                 <FaMicrophone sclassName="mt-5" />
//                 {/* <p className={`text-red-500 ${recording ? 'hidden' : ''}`}>
//               {t('search.voice')}
//               </p> */}
//               </button>
//             </div>
//           </div>
//         </form>
//         <div
//           className={` w-48 mt-3 rounded-lg flex justify-center m-auto ${
//             recording ? "bg-blue-200" : ""
//           }`}
//         >
//           {!result && (
//             <div
//               className={`w-48 mt-3 rounded-lg flex flex-col justify-center items-center m-auto`}
//             >
//               {/* <button
//                 type="button"
//                 className={`text-red-500 font-large rounded-lg text-xl px-4 py-2 ${recording ? 'recording' : ''}`}
//                 onClick={handleToggleListening}
//               >
//                 <FaMicrophone style={{ fontSize: "80px" }} />
//               </button> */}
//               {/* <p className={`text-red-500 ${recording ? 'hidden' : ''}`}>
//               {t('search.voice')}
//               </p> */}
//             </div>
//           )}
//         </div>
//         {file && (
//           <div className="mt-3  flex justify-center">
//             <p>Attached file: {file.name}</p>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

import React, { useState, useEffect, useRef } from "react";
import { AiOutlineSend } from "react-icons/ai";
import { IoMdAttach } from "react-icons/io";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { FaMicrophone } from "react-icons/fa";
import "./InputBox.css";
import { useTranslation } from "react-i18next";

export default function InputBox({
  onSubmitQuestion,
  result,
  setFileAttacehment,
}) {
  const [question, setQuestion] = useState("");
  const [recording, setRecording] = useState(false);
  const [file, setFile] = useState(null);

  const fileInputRef = useRef(null);

  const { t } = useTranslation();
  const {
    transcript,
    listening,
    browserSupportsSpeechRecognition,
    startListening,
    stopListening,
  } = useSpeechRecognition();

  useEffect(() => {
    if (transcript !== "") {
      setQuestion(transcript);
    }
  }, [transcript]);

  const handleInputChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleInputSubmit = () => {
    if (!question.trim()) {
      alert("Please enter a question before submitting.");
      return;
    }

    onSubmitQuestion(question);
    console.log("Here is the question: ", question);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!question.trim()) {
      alert("Please enter a question before submitting.");
      return;
    }

    onSubmitQuestion(question);
    console.log("Here is the question: ", question);
  };

  const handleToggleListening = () => {
    if (listening) {
      SpeechRecognition.stopListening();
    } else {
      SpeechRecognition.startListening();
    }

    setRecording(!recording);
  };

  useEffect(() => {
    if (!listening && question.trim() !== "") {
      // Call the API when recording has stopped and there is a question
      onSubmitQuestion(question);
    }
  }, [listening]);

  useEffect(() => {
    if (transcript !== "") {
      setQuestion(transcript);
    }
  }, [transcript]);
  const handleFileIconClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "image/png") {
      setFile(selectedFile);
      const formData = new FormData();
      formData.append("file", selectedFile);

      const token = localStorage.getItem("token");

      try {
        const response = await fetch("http://15.156.159.236:8000/ocr", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("File upload response:", data);
        setFileAttacehment(data);
        // Handle the response from the API
      } catch (error) {
        console.error("There was a problem with the file upload:", error);
      }
    } else {
      alert("Please select a PNG file.");
    }
  };

  return (
    <div className="">
      <div className="lg:w-[900px] md:w-[600px] w-[300px]  rounded">
        <form
          className="border-2 rounded-xl shadow-md  rounded-xl"
          style={{ borderRadius: "40px" }}
          // style={{ backgroundColor: "#005EA3" }}
          onSubmit={handleSubmit}
        >
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-red-300 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"></div>
            <input
              className="block lg:w-[800px] md:w-[500px]  w-200px p-3 pl-10 pr-20 text-sm text-dark outline-none"
              style={{ border: "none", color: "#C5C6C7" }}
              placeholder="Ask Question"
              // {t("placholder")}
              required
              value={question}
              onChange={handleInputChange}
            />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <button
              type="button"
              className=" absolute right-16 bottom-2.5 font-large rounded-lg lg:text-2xl md:text-2xl text-xl  "
              onClick={handleFileIconClick}
              style={{ color: "#C5C6C7", marginTop: "120px" }}
            >
              <IoMdAttach className="mt-5" />
            </button>
            <button
              type="submit"
              className="  absolute right-8 bottom-2.5 font-large rounded-lg lg:text-2xl md:text-2xl text-xl   "
              style={{ color: "#C5C6C7" }}
            >
              <AiOutlineSend className="mt-5" />
            </button>
            <div>
              <button
                type="button"
                className={` absolute right-1 bottom-2.5 font-large rounded-lg lg:text-2xl md:text-2xl text-xl  ${
                  recording ? "recording" : ""
                }`}
                onClick={handleToggleListening}
                style={{ color: "#C5C6C7" }}
              >
                <FaMicrophone sclassName="mt-5" />
                {/* <p className={`text-red-500 ${recording ? 'hidden' : ''}`}>
              {t('search.voice')}
              </p> */}
              </button>
            </div>
          </div>
        </form>
        <div
          className={` w-48 mt-3 rounded-lg flex justify-center m-auto ${
            recording ? "bg-blue-200" : ""
          }`}
        >
          {!result && (
            <div
              className={`w-48 mt-3 rounded-lg flex flex-col justify-center items-center m-auto`}
            >
              {/* <button
                type="button"
                className={`text-red-500 font-large rounded-lg text-xl px-4 py-2 ${recording ? 'recording' : ''}`}
                onClick={handleToggleListening}
              >
                <FaMicrophone style={{ fontSize: "80px" }} />
              </button> */}
              {/* <p className={`text-red-500 ${recording ? 'hidden' : ''}`}>
              {t('search.voice')}
              </p> */}
            </div>
          )}
        </div>
        {file && (
          <div className="mt-3  flex justify-center">
            <p>Attached file: {file.name}</p>
          </div>
        )}
      </div>
    </div>
  );
}
