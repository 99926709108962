// import React, { useState } from "react";

// export default function Laboratory() {
//   const [selectedLocation, setSelectedLocation] = useState(null);

//   // Combined laboratory data with additional fields
//   const dummyData = [
//     {
//       id: 1,
//       labImage: "https://images.pexels.com/photos/4035476/pexels-photo-4035476.jpeg", // ABC Diagnostic Lab image
//       labName: "ABC Diagnostic Lab",
//       address: "New York, NY",
//       contact: "+1 555-111-2222",
//       services: "Blood Tests",
//       rating: 4.5,
//       availability: "Monday to Saturday, 8 AM - 6 PM",
//       specialty: "Blood Tests",
//       profileUrl: "https://example.com/profiles/abc_lab_ny",
//     },
//     {
//       id: 2,
//       labImage: "https://images.pexels.com/photos/4050304/pexels-photo-4050304.jpeg", // XYZ Health Lab image
//       labName: "XYZ Health Lab",
//       address: "Los Angeles, CA",
//       contact: "+1 555-333-4444",
//       services: "COVID-19 Testing",
//       rating: 4.8,
//       availability: "Monday to Friday, 9 AM - 5 PM",
//       specialty: "COVID-19 Testing",
//       profileUrl: "https://example.com/profiles/xyz_lab_la",
//     },
//     {
//       id: 3,
//       labImage: "https://images.pexels.com/photos/4050051/pexels-photo-4050051.jpeg", // QuickMed Lab Services image
//       labName: "QuickMed Lab Services",
//       address: "Chicago, IL",
//       contact: "+1 555-555-6666",
//       services: "MRI Scans",
//       rating: 4.6,
//       availability: "Monday to Saturday, 7 AM - 7 PM",
//       specialty: "MRI Scans",
//       profileUrl: "https://example.com/profiles/quickmed_lab_chicago",
//     },
//     {
//       id: 4,
//       labImage: "https://images.pexels.com/photos/4050221/pexels-photo-4050221.jpeg", // Precision Lab Diagnostics image
//       labName: "Precision Lab Diagnostics",
//       address: "Houston, TX",
//       contact: "+1 555-777-8888",
//       services: "DNA Testing",
//       rating: 4.7,
//       availability: "Tuesday to Sunday, 10 AM - 4 PM",
//       specialty: "DNA Testing",
//       profileUrl: "https://example.com/profiles/precision_lab_houston",
//     },
//     {
//       id: 5,
//       labImage: "https://images.pexels.com/photos/4036228/pexels-photo-4036228.jpeg", // Global Health Lab image
//       labName: "Global Health Lab",
//       address: "San Francisco, CA",
//       contact: "+1 555-999-0000",
//       services: "X-ray Services",
//       rating: 4.5,
//       availability: "Monday to Friday, 8 AM - 4 PM",
//       specialty: "X-ray Services",
//       profileUrl: "https://example.com/profiles/global_health_lab_sf",
//     },
//   ];

//   return (
//     <div className="container mx-auto px-4">
//       {/* Heading */}
//       <h1 className="text-3xl font-bold text-center my-8">Find Your Laboratory</h1>

//       {/* Filter Section */}
//       <div className="flex justify-center items-center">
//         <div className="flex flex-col space-y-4 mb-8 w-1/3">
     
//           <div className="bg-gray-100 rounded-lg p-2 shadow-sm">
//             <input
//               type="text"
//               placeholder="Enter an Address"
//               className="w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
//             />
//           </div>

//           {/* Use Current Location option */}
//           <div className="bg-white rounded-lg p-2 shadow-sm flex items-center space-x-2 border border-gray-300">
//             <i className="fas fa-map-marker-alt text-gray-500"></i>
//             <div>
//               <p className="text-lg font-semibold">Use Current Location</p>
//               <p className="text-sm text-gray-600">345/2, Mawaththegama Road, Kurunegala.</p>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Laboratory Cards */}
//       <div className="container mx-auto" style={{ width: "90%" }}>
//         <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
//           {dummyData.map((lab) => (
//             <div
//               key={lab.id}
//               className="bg-white shadow-md rounded-lg overflow-hidden transform transition duration-300 hover:scale-105"
//             >
//               <div className="w-full h-44 overflow-hidden">
//                 <img
//                   src={lab.labImage}
//                   alt={lab.labName}
//                   className="w-full h-full object-cover"
//                 />
//               </div>
//               <div className="p-4">
//                 {/* Laboratory's Name */}
//                 <h2 className="text-xl font-bold">{lab.labName}</h2>
//                 {/* Laboratory's Info */}
//                 <p className="text-gray-700 mb-1">
//                   <strong>Specialty:</strong> {lab.specialty}
//                 </p>
//                 <p className="text-gray-700 mb-1">
//                   <strong>Address:</strong> {lab.address}
//                 </p>
//                 <p className="text-gray-700 mb-1">
//                   <strong>Contact:</strong> {lab.contact}
//                 </p>
//                 <p className="text-gray-700 mb-1">
//                   <strong>Services:</strong> {lab.services}
//                 </p>
//                 <p className="text-gray-700 mb-1">
//                   <strong>Availability:</strong> {lab.availability}
//                 </p>
//                 <p className="text-gray-700 mb-1">
//                   <strong>Rating:</strong>{" "}
//                   {Array.from({ length: 5 }, (_, index) => (
//                     <span
//                       key={index}
//                       className={
//                         index < Math.floor(lab.rating)
//                           ? "text-yellow-500"
//                           : "text-gray-300"
//                       }
//                     >
//                       &#9733; {/* Filled star */}
//                     </span>
//                   ))}{" "}
//                   {lab.rating.toFixed(1)}
//                 </p>

//                 <a
//                   href={lab.profileUrl}
//                   className="text-blue-500 hover:underline"
//                 >
//                   View Profile
//                 </a>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }


import React, { useState, useEffect } from "react";
import axios from "axios";
import ChatHeader from "./Chatheader";

export default function Laboratory() {
  const [labs, setLabs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLabs = async () => {
      try {

        const response = await axios.
        get(        `${process.env.REACT_APP_API_URL}/user/api/get_labs_near_me`
          , {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        console.log(response,"labs")
        setLabs(response.data.result); 
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch laboratories");
        setLoading(false);
      }
    };

    fetchLabs();
  }, []);

  if (loading) {
    return <p>Loading laboratories...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="container mx-auto px-4">
      <div className="container mx-auto mt-5" style={{ width: "90%" }}>  <ChatHeader/></div>
    
      {/* Heading */}
      <h1 className="text-3xl font-bold text-center my-8">Find Your Laboratory</h1>

      {/* Filter Section */}
      <div className="flex justify-center items-center">
        <div className="flex flex-col space-y-4 mb-8 w-1/3">
          <div className="bg-gray-100 rounded-lg p-2 shadow-sm">
            <input
              type="text"
              placeholder="Enter an Address"
              className="w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>

          {/* Use Current Location option */}
          <div className="bg-white rounded-lg p-2 shadow-sm flex items-center space-x-2 border border-gray-300">
            <i className="fas fa-map-marker-alt text-gray-500"></i>
            <div>
              <p className="text-lg font-semibold">Use Current Location</p>
              <p className="text-sm text-gray-600">345/2, Mawaththegama Road, Kurunegala.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Laboratory Cards */}
      <div className="container mx-auto" style={{ width: "90%" }}>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {labs.map((lab, index) => (
            <div
              key={index}
              className="bg-white shadow-md rounded-lg overflow-hidden transform transition duration-300 hover:scale-105"
            >
              <div className="w-full h-44 overflow-hidden">
                <img
                  src={lab.lab_photo_url}
                  alt={lab.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="p-4">
                {/* Laboratory's Name */}
                <h2 className="text-xl font-bold">{lab.name}</h2>
                {/* Laboratory's Info */}
                <p className="text-gray-700 mb-1">
                  <strong>Specialty:</strong> {lab.specialty}
                </p>
                <p className="text-gray-700 mb-1">
                  <strong>Address:</strong> {lab.location}
                </p>
                <p className="text-gray-700 mb-1">
                  <strong>Contact:</strong> {lab.contact}
                </p>
                <p className="text-gray-700 mb-1">
                  <strong>Availability:</strong> {lab.availability}
                </p>

                <a
                  href={lab.profile_url}
                  className="text-blue-500 hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Profile
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
