import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

export default function Decodingmedical() {
  useEffect(() => {
    // Function to dynamically load a script
    const loadScript = (src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return script;
    };

    // List of scripts to load
    const scripts = [
      "/assets/js/jquery-3.7.0.min.js",
      "/assets/js/bootstrap.bundle.min.js",

      "/assets/js/jquery.nice-select.min.js",
      "/assets/js/jquery.magnific-popup.min.js",
      "/assets/js/slick.min.js",
      "/assets/js/typed.umd.js",
      "/assets/js/wow.min.js",
      "/assets/js/plugins.js",
      "/assets/js/main.js",
    ];

    // Load all scripts and keep references to them
    const scriptElements = scripts.map((src) => loadScript(src));

    // Cleanup function to remove scripts when component unmounts
    return () => {
      scriptElements.forEach((script) => {
        if (script && script.parentNode === document.body) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);
  return (
    <div>
      <div id="preloader">
        <div id="loader"></div>
      </div>
      <body>
        {/* <!-- ==== page wrapper start ==== --> */}
        <div class="my-app home-seven">
          {/* <!-- ==== header start ==== --> */}
          <Navbar />
          {/* <!-- ==== / header end ==== -->
          
          
         
            <!-- ==== footer start ==== --> */}
          {/* <!-- ==== / footer end ==== --> */}/
          <section
            class="section cmn-banner"
            data-background="assets/images/banner/banner-bg.png"
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div
                    class="cmn-banner__content wow fadeInUp"
                    data-wow-duration="600ms"
                    data-wow-delay="300ms"
                  >
                    <h3 class="h3">
                      Decoding Medical Symptoms: When to Seek Help
                    </h3>
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb justify-content-center">
                        <li class="breadcrumb-item">
                          <a href="index.html">Home</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          Blog Details
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div class="anime">
              <img src="assets/images/anime-one.png" alt="Image" class="one" />
              <img src="assets/images/anime-two.png" alt="Image" class="two" />
            </div>
          </section>
          <section class="section blog-main blog-details pb-0">
            <div class="container">
              <div class="row items-gap">
                <div class="col-12 col-xl-8">
                  <div class="blog-details__content">
                    <div
                      class="bd-thumb wow fadeInUp"
                      data-wow-duration="600ms"
                      data-wow-delay="600ms"
                    >
                      <img src="assets/images/news/nine.png" alt="Image" />
                    </div>
                    <div class="bd-content">
                      <div class="bd-meta">
                        <div class="meta__left">
                          <p>
                            <strong>Written by:</strong>
                            Marry biden
                          </p>
                          <span></span>
                          <p>10/01/2023</p>
                        </div>
                      </div>
                      <div
                        class="bd-content-info wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="600ms"
                      >
                        <h4 class="h4">
                          Decoding Medical Symptoms: When to Seek Help{" "}
                        </h4>
                        <div class="paragraph">
                          <p>
                            Understanding when to seek medical advice can often
                            be a challenging task, especially in an era where
                            information is readily available at our fingertips.
                            The internet is flooded with articles, blogs, and
                            forums that offer advice on various health issues,
                            but this abundance of information can sometimes be
                            overwhelming or even misleading. With so much
                            content to sift through, how can you be sure that
                            the information you’re relying on is accurate and
                            that you’re taking the right steps to manage your
                            health? This is where MedicalGPT comes in as a
                            reliable and trustworthy resource.
                          </p>
                          <p>
                            MedicalGPT is designed to help users navigate the
                            complexities of their health by providing clear,
                            accurate, and personalized advice. Whether you’re
                            experiencing common symptoms like headaches or
                            fatigue, or something more specific like chest pain
                            or shortness of breath, MedicalGPT can help you
                            understand what these symptoms might indicate and
                            when it’s time to seek professional medical advice.
                          </p>
                        </div>
                        {/* <h4 class="h4">Where can I get some?</h4> */}
                      </div>
                    </div>
                    {/* <div
                      class="bd-group wow fadeInUp"
                      data-wow-duration="600ms"
                      data-wow-delay="900ms"
                    >
                      <img src="assets/images/news/group-one.png" alt="Image" />
                      <img src="assets/images/news/group-two.png" alt="Image" />
                    </div> */}
                    <div
                      class="bd-content wow fadeInUp"
                      style={{ marginTop: "-100px" }}
                      data-wow-duration="600ms"
                      data-wow-delay="1200ms"
                    >
                      <div class="bd-content__alt">
                        <p>
                          One of the most valuable features of MedicalGPT is its
                          ability to analyze symptoms in the context of your
                          overall health. For instance, a headache might be a
                          minor issue for one person, but if combined with other
                          symptoms or a specific medical history, it could be a
                          sign of something more serious. MedicalGPT considers
                          these factors, offering advice that is tailored to
                          your unique situation.
                        </p>
                        {/* <ul>
                          <li>Mauris maximus diam ac imperdiet dictum.</li>
                          <li>
                            Maecenas eget ipsum dapibus, rutrum mi non,
                            ultricies massa.
                          </li>
                          <li>Nam non purus porta risus tincidunt cursus.</li>
                          <li>
                            Quisque blandit lacus vel urna pellentesque mattis.
                          </li>
                          <li>
                            Maecenas vehicula tortor et consectetur faucibus.
                          </li>
                        </ul> */}
                      </div>
                    </div>
                    <div class="bd-quote">
                      <blockquote class="h4">
                        <q class="h4">
                          Understanding mental health is the first step toward a
                          balanced, healthier life.
                        </q>
                      </blockquote>
                    </div>
                    <div
                      class="bd-content wow fadeInUp"
                      data-wow-duration="600ms"
                      data-wow-delay="900ms"
                    >
                      <div class="bd-content__alt mt-0 mt-4">
                        <p>
                          In addition to symptom analysis, MedicalGPT provides
                          guidance on when to consult a healthcare professional.
                          For example, if you’re experiencing symptoms like
                          persistent pain, unexplained weight loss, or
                          difficulty breathing, MedicalGPT will advise you to
                          seek medical attention promptly. This feature is
                          particularly useful for those who may be unsure about
                          the severity of their symptoms and whether they
                          warrant a visit to the doctor.
                        </p>
                        <p className="mt-3">
                          It’s important to note that while MedicalGPT offers
                          valuable insights, it should not replace the advice of
                          a healthcare professional. Instead, it serves as a
                          first step, helping you make informed decisions about
                          your health. If your symptoms persist or worsen,
                          it’scrucial to consult with a healthcare provider who
                          can provide a thorough examination and diagnosis.
                        </p>
                        <p className="mt-3">
                          MedicalGPT also addresses the issue of health
                          misinformation, which is a growing concern in today’s
                          digital age. With so many sources of information
                          available, it can be difficult to discern what is
                          credible and what is not. MedicalGPT draws from a vast
                          database of up-to-date medical knowledge, ensuring
                          that the advice you receive is based on the latest
                          research and best practices in healthcare.
                        </p>
                        <p className="mt-3">
                          In conclusion, navigating the world of medical
                          symptoms can be daunting, but with the help of
                          MedicalGPT, you can make informed decisions about your
                          health. By providing personalized advice, helping you
                          understand when to seek medical attention, and
                          offering reliable information, MedicalGPT is a
                          valuable tool for anyone looking to take control of
                          their health. Remember, while MedicalGPT is a powerful
                          resource, it’s always important to consult with a
                          healthcare professional if you’re unsure about your
                          symptoms or if they persist.
                        </p>
                      </div>
                    </div>
                    <div class="bd-tags">
                      <div class="tags-left">
                        <p>Tags:</p>
                        <div class="tags-content">
                          <a href="blog">Nature</a>
                          <a href="blog">Health</a>
                        </div>
                      </div>
                      <div class="tags-right">
                        <p>Share:</p>
                        <ul class="social">
                          <li>
                            <a href="index.html" aria-label="social media">
                              <i class="fa-brands fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a href="index.html" aria-label="social media">
                              <i class="fa-brands fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="index.html" aria-label="social media">
                              <i class="fa-brands fa-youtube"></i>
                            </a>
                          </li>
                          <li>
                            <a href="index.html" aria-label="social media">
                              <i class="fa-brands fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="blog-details__pagination">
                    <div class="row items-gap">
                      <div class="col-md-6">
                        <div class="single">
                          <a href="blog-single">
                            <i class="fa-solid fa-arrow-left-long"></i>
                            Previous Blog
                          </a>
                          <div class="latest-single">
                            <div class="latest-thumb">
                              <a href="blog-single">
                                <img
                                  src="assets/images/news/eleven.png"
                                  alt="Image"
                                />
                              </a>
                            </div>
                            <div class="latest-content">
                              <p>10/01/2023</p>
                              <p>
                                <a href="blog-single">
                                  Guide dog shortage: The blind peo ple who
                                  train their own guide
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="single single--alt">
                          <a href="telemedicine">
                            Next Blog
                            <i class="fa-solid fa-arrow-right-long"></i>
                          </a>
                          <div class="latest-single">
                            <div class="latest-thumb">
                              <a href="telemedicine">
                                <img
                                  src="assets/images/news/ten.png"
                                  alt="Image"
                                />
                              </a>
                            </div>
                            <div class="latest-content">
                              <p>10/01/2023</p>
                              <p>
                                <a href="blog-single">
                                  Guide dog shortage: The blind peo ple who
                                  train their own guide
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
                      class="section pb-0 comment-form wow fadeInUp"
                      data-wow-duration="600ms"
                      data-wow-delay="600ms"
                    >
                      <div class="section__header">
                        <h2 class="h2 text-start">Leave a comment</h2>
                      </div>
                      <form action="#" method="post">
                        <div class="form-group-wrapper">
                          <div class="form-group-single">
                            <input
                              type="text"
                              name="comment-name"
                              id="commentName"
                              placeholder="Name"
                            />
                          </div>
                          <div class="form-group-single">
                            <input
                              type="email"
                              name="comment-email"
                              id="commentemail"
                              placeholder="Email"
                            />
                          </div>
                        </div>
                        <div class="form-group-single">
                          <textarea
                            name="comment-message"
                            id="commentMessage"
                            placeholder="Write Comment..."
                          ></textarea>
                        </div>
                        <div class="cta__group">
                          <button type="submit" class="btn btn--ocotonary">
                            post comment
                            <i class="fa-solid fa-arrow-right-long"></i>
                          </button>
                        </div>
                      </form>
                    </div> */}
                  </div>
                </div>
                <div class="col-12 col-xl-4">
                  <div class="blog-main__sidebar">
                    {/* <div
                      class="widget wow fadeInUp"
                      data-wow-duration="600ms"
                      data-wow-delay="600ms"
                    >
                      <div class="widget__head">
                        <h5 class="h5">Search</h5>
                      </div>
                      <div class="widget-search">
                        <form action="#" method="post">
                          <div class="form-group-input">
                            <input
                              type="search"
                              name="blog-search"
                              id="blogSearch"
                              placeholder="Search here. . ."
                            />
                            <button type="submit">
                              <i class="fa-solid fa-magnifying-glass"></i>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div
                      class="widget wow fadeInUp"
                      data-wow-duration="600ms"
                      data-wow-delay="900ms"
                    >
                      <div class="widget__head">
                        <h5 class="h5">Categories</h5>
                      </div>
                      <div class="widget__list">
                        <ul>
                          <li>
                            <a href="blog">Business</a>
                          </li>
                          <li>
                            <a href="blog">Job Market</a>
                          </li>
                          <li>
                            <a href="blog">Marketing</a>
                          </li>
                          <li>
                            <a href="blog">News</a>
                          </li>
                          <li>
                            <a href="blog">Social Media</a>
                          </li>
                          <li>
                            <a href="blog">Trends</a>
                          </li>
                          <li>
                            <a href="blog">Writing</a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                    <div class="widget">
                      <div class="widget__head">
                        <h5 class="h5">Recent Posts</h5>
                      </div>
                      <div class="widget__latest">
                        <div
                          class="latest-single wow fadeInUp"
                          data-wow-duration="600ms"
                          data-wow-delay="600ms"
                        >
                          <div class="latest-thumb">
                            <a href="blog-single">
                              <img
                                src="assets/images/news/ten.png"
                                alt="Image"
                              />
                            </a>
                          </div>
                          <div class="latest-content">
                            <p>10/01/2023</p>
                            <p>
                              <a href="blog-single">
                                Guide dog shortage: The blind peo ple who train
                                their own guide
                              </a>
                            </p>
                          </div>
                        </div>
                        <div
                          class="latest-single wow fadeInUp"
                          data-wow-duration="600ms"
                          data-wow-delay="900ms"
                        >
                          <div class="latest-thumb">
                            <a href="blog-single">
                              <img
                                src="assets/images/news/eleven.png"
                                alt="Image"
                              />
                            </a>
                          </div>
                          <div class="latest-content">
                            <p>10/01/2023</p>
                            <p>
                              <a href="blog-single">
                                Guide dog shortage: The blind peo ple who train
                                their own guide
                              </a>
                            </p>
                          </div>
                        </div>
                        <div
                          class="latest-single wow fadeInUp"
                          data-wow-duration="600ms"
                          data-wow-delay="1200ms"
                        >
                          <div class="latest-thumb">
                            <a href="blog-single">
                              <img
                                src="assets/images/news/twelve.png"
                                alt="Image"
                              />
                            </a>
                          </div>
                          <div class="latest-content">
                            <p>10/01/2023</p>
                            <p>
                              <a href="blog-single">
                                Guide dog shortage: The blind peo ple who train
                                their own guide
                              </a>
                            </p>
                          </div>
                        </div>
                        <div
                          class="latest-single wow fadeInUp"
                          data-wow-duration="600ms"
                          data-wow-delay="1500ms"
                        >
                          <div class="latest-thumb">
                            <a href="blog-single">
                              <img
                                src="assets/images/news/thirteen.png"
                                alt="Image"
                              />
                            </a>
                          </div>
                          <div class="latest-content">
                            <p>10/01/2023</p>
                            <p>
                              <a href="blog-single">
                                Guide dog shortage: The blind peo ple who train
                                their own guide
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="widget">
                      <div class="widget__head">
                        <h5 class="h5">Tags</h5>
                      </div>
                      <div class="widget__tags">
                        <ul>
                          <li>
                            <a href="blog">nature</a>
                          </li>
                          <li>
                            <a href="blog">health</a>
                          </li>
                          <li>
                            <a href="blog">galaxy</a>
                          </li>
                          <li>
                            <a href="blog">creative</a>
                          </li>
                          <li>
                            <a href="blog">art</a>
                          </li>
                          <li>
                            <a href="blog">business</a>
                          </li>
                          <li>
                            <a href="blog">space</a>
                          </li>
                          <li>
                            <a href="blog">biology</a>
                          </li>
                          <li>
                            <a href="blog">environemnt</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class="widget widget-big wow fadeInUp"
                      data-wow-duration="600ms"
                      data-wow-delay="600ms"
                    >
                      <a href="blog-single">
                        <img
                          src="assets/images/news/fourteen.png"
                          alt="Image"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>

        <div class="progress-wrap">
          <svg
            class="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
        {/* <!-- ==== js dependencies start ==== -->
        <!-- jquery --> */}
        <script src="/assets/js/jquery-3.7.0.min.js"></script>
        {/* <!-- bootstrap five js --> */}
        <script src="/assets/js/bootstrap.bundle.min.js"></script>
        {/* <!-- nice select js --> */}
        <script src="/assets/js/jquery.nice-select.min.js"></script>
        {/* <!-- magnific popup js --> */}
        <script src="/assets/js/jquery.magnific-popup.min.js"></script>
        {/* <!-- slick js --> */}
        <script src="/assets/js/slick.min.js"></script>
        {/* <!-- typed js --> */}
        <script src="/assets/js/typed.umd.js"></script>
        {/* <!-- wow js --> */}
        <script src="/assets/js/wow.min.js"></script>
        {/* <!-- ==== / js dependencies end ==== --> */}
        {/* <!-- plugins js --> */}
        <script src="/assets/js/plugins.js"></script>
        {/* <!-- main js --> */}
        <script src="/assets/js/main.js"></script>
      </body>
    </div>
  );
}
