
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Toaster, toast } from "react-hot-toast";

// export default function Upgrade() {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(true);
//   const [planType, setPlanType] = useState("monthly");
//   const [pricingData, setPricingData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const spinnerContainerStyles = {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: '100vh', // Full viewport height
//   };

//   const spinnerStyles = {
//     width: '5rem',
//     height: '5rem',
//     color: '#005477',
//   };
//   useEffect(() => {
//     const fetchPlans = async () => {
//       const formData = new FormData();
//       formData.append("plan_name", planType); // Append plan type as form data

//       try {
//         setLoading(true); 
//         const token = localStorage.getItem("token");
//         const response = await axios.post(
//           `${process.env.REACT_APP_API_URL}/client_handler/subscription_plan`,
//           formData,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//               "Content-Type": "multipart/form-data", // Ensure content type is correct for form data
//             },
//           }
//         );

//         if (response.data.status === 200) {
//           setPricingData(response.data.result);
//         } else {
//           toast.error("Failed to load subscription plans.");
//         }
//       } catch (error) {
//         toast.error(`Error: ${error.message}`);
//       }
//       finally {
//         setLoading(false); 
//       }
//     };

//     fetchPlans();
//   }, [planType]);

//   const handlePlanChange = (type) => {
//     setPlanType(type);
//   };

//   const getButtonStyle = (type) => ({
//     backgroundColor: "#111827",
//     transform: planType === type ? "scale(1.1)" : "scale(1)",
//     transition: "transform 0.3s ease",
//     fontWeight: planType === type ? "bold" : "normal",
//   });

//   const handleSubmit = async (price_id, title) => {
//     const token = localStorage.getItem("token");

//     if (token) {
//       try {
//         const response = await axios.post(
//           `${process.env.REACT_APP_API_URL}/client_handler/create_checkout_session`,
//           {
//             price_id,
//             title,
//           },
//           {
//             headers: { Authorization: `Bearer ${token}` },
//           }
//         );
// console.log(response,"checkout")
//         if (response.data.status === 200) {
//           window.location.href = response.data.url;
//         } else {
//           toast.error("Cannot proceed with payment");
//         }
//       } catch (error) {
//         toast.error(`Error: ${error.message}`);
//       }
//     } else {
//       window.location.href = "/auth/login";
//     }
//   };

//   return (
//     <div>
//       <Toaster
//         position="top-right"
//         reverseOrder={false}
//         toastOptions={{
//           style: {
//             marginTop: "50px",
//           },
//         }}
//       />
//       {/* <div
//         className="flex flex-col justify-center items-center mt-2"
      
//         style={{
//           width: isSidebarOpen ? "calc(100% - 250px)" : "100%",
//           marginLeft: isSidebarOpen ? "250px" : "0",
//           transition: "width 0.3s, margin-left 0.3s",
//           backgroundColor: "white",
//           borderRadius: "20px",
//           padding: "20px",
//           maxWidth: "1200px", 
//           margin: "0 auto",   
//         }}
//       > */}
//         <div
//       className={`flex flex-col mt-2 p-5 justify-center items-center ${
//         isSidebarOpen ? "md:ml-[350px] md:w-[calc(100%-350px)]" : "w-full"
//       }`}
   
//       style={{
//         // width: isSidebarOpen ? "calc(100% - 250px)" : "100%",
//         // marginLeft: isSidebarOpen ? "250px" : "0",
//         transition: "width 0.3s, margin-left 0.3s",
//         backgroundColor: "white",
//         borderRadius: "20px",
      
//         // maxWidth: "1200px", 
       
//       }}
//     > 
//          {loading ? (
//            <div style={spinnerContainerStyles}>
//            <div style={spinnerStyles}>
//              {/* Assuming you're using an SVG or CSS spinner */}
//              <span className="spinner-border" role="status" style={spinnerStyles} aria-hidden="true" ></span>            
          
//            </div>
//          </div>
//                           // <span className="spinner-border" role="status" style={spinnerStyles} aria-hidden="true" ></span>
//                         ) : (
//                           <section
//                           className="section pricing-two bg-img"
//                           style={{ backgroundColor: "white" }}
//                         >
//                           <div className="container">
//                             <div className="row justify-content-center">
//                               <div>
//                                 <div className="section__header">
//                                   <h3 style={{ width: "100%" }} className="h3">
//                                     Ready to Get Started?
//                                   </h3>
//                                 </div>
//                               </div>
//                             </div>
                
//                             {/* Plan Type Buttons */}
//                             <div className="d-flex flex-wrap justify-content-center mt-4">
//                               <button
//                                 onClick={() => handlePlanChange("monthly")}
//                                 style={getButtonStyle("monthly")}
//                                 className="btn btn--septenary me-2"
//                               >
//                                 Monthly Plan
//                               </button>
//                               <button
//                                 onClick={() => handlePlanChange("yearly")}
//                                 style={getButtonStyle("yearly")}
//                                 className="btn btn--septenary me-2"
//                               >
//                                 Yearly Plan
//                               </button>
//                             </div>
                
//                             {/* Pricing Cards */}
//                             <div className="row items-gap mt-4">
//                               {pricingData.map((plan, index) => (
//                                 <div key={plan.plan_id} className="col-12 col-md-8 col-lg-6">
//                                   <div
//                                     className="pricing-two__single wow fadeInUp"
//                                     data-wow-duration="600ms"
//                                     data-wow-delay="1200ms"
//                                     style={{ minWidth: "350px", maxWidth: "400px" }}
//                                   >
//                                     <h5 className={`h5 ${index === 0 ? "pri" : "tri"}`}>
//                                       {plan.plane_name_ui} 
//                                     </h5>
//                                     <div className="meta">
//                                       <div className="content">
//                                         <h2 className="h2 title" style={{ color: "white" }}>
//                                           {plan.price} 
//                                         </h2> 
//                                         <p>paid {planType === "monthly" ? "per month" : "per year"}</p>
//                                       </div>
//                                     </div>
//                                     <hr />
//                                     <ul>
//                                       <li><i className="fa-solid fa-check"></i> {plan.user_access}</li>
//                                       {/* <li><i className="fa-solid fa-check"></i> {plan.query_limit}</li>
//                                       <li><i className="fa-solid fa-check"></i> {plan.ai_tools}</li> */}
//                                       <li><i className="fa-solid fa-check"></i> {plan.recommendations}</li>
//                                       <li><i className="fa-solid fa-check"></i> {plan.data_export}</li>
                                   
                
//                                     </ul>
//                                     <hr />
//                                     <p style={{color:"white"}}>Expire Date : {plan.expiry_date}</p>
//                                     <button
//                                       onClick={() => handleSubmit(plan.price_id, plan.plane_name_ui)}
//                                       className="btn btn--septenary mt-2"
//                                     >
//                                       Buy now
//                                     </button>
//                                   </div>
//                                 </div>
//                               ))}
//                             </div>
//                           </div>
//                         </section>
//                         )}
       
//       </div>
//     </div>
//   );
// }





import React, { useState, useEffect } from "react";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import Skeleton from "@mui/material/Skeleton";

export default function Upgrade() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [planType, setPlanType] = useState("monthly");
  const [pricingData, setPricingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const spinnerContainerStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Full viewport height
  };

  const spinnerStyles = {
    width: '5rem',
    height: '5rem',
    color: '#005477',
  };
  useEffect(() => {
    const fetchPlans = async () => {
      const formData = new FormData();
      formData.append("plan_name", planType); // Append plan type as form data

      try {
        setLoading(true); 
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/client_handler/subscription_plan`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data", 
            },
          }
        );

        if (response.data.status === 200) {
          console.log(response.data ,"Upgrade")
          setPricingData(response.data.result);
        } else {
          toast.error("Failed to load subscription plans.");
        }
      } catch (error) {
        toast.error(`Error: ${error.message}`);
      }
      finally {
        setLoading(false); 
      }
    };

    fetchPlans();
  }, [planType]);

  const handlePlanChange = (type) => {
    setPlanType(type);
  };

  const getButtonStyle = (type) => ({
    backgroundColor: "#111827",
    transform: planType === type ? "scale(1.1)" : "scale(1)",
    transition: "transform 0.3s ease",
    fontWeight: planType === type ? "bold" : "normal",
  });

  const handleSubmit = async (price_id, title) => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/client_handler/create_checkout_session`,
          {
            price_id,
            title,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
console.log(response,"checkout")
        if (response.data.status === 200) {
          window.location.href = response.data.url;
        } else {
          toast.error("Cannot proceed with payment");
        }
      } catch (error) {
        toast.error(`Error: ${error.message}`);
      }
    } else {
      window.location.href = "/auth/login";
    }
  };

  return (
    <div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            marginTop: "50px",
          },
        }}
      />
    
        <div
      className={`flex flex-col mt-2 p-5 justify-center items-center ${
        isSidebarOpen ? "md:ml-[350px] md:w-[calc(100%-350px)]" : "w-full"
      }`}
   
      style={{
        transition: "width 0.3s, margin-left 0.3s",
        backgroundColor: "white",
        // borderRadius: "20px",
      
        // maxWidth: "1200px", 
       
      }}
    > 
         {loading ? (
            <div
            style={{
              width: "100%",
              maxWidth: "1200px", 
              borderRadius: "20px",
              border: "1px solid #ddd", // Border to match your UI
              padding: "20px", // Padding for content spacing
              marginBottom: "20px",
              display: "flex", // Flexbox to center the skeleton cards
              justifyContent: "center", 
              alignItems: "center", // Centering vertically
              flexWrap: "wrap", // Allows wrapping of cards
            }}
          >
            {[...Array(2)].map((_, index) => (
              <div
                key={index}
                style={{
                  width: "400px", // Width for each skeleton card
                  margin: "10px", // Spacing between cards
                
                  padding: "20px",
                }}
              >
                {/* Skeleton for the card background */}
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="100%"
                  height={300}
                  style={{
                    // borderRadius: "20px",
                    marginBottom: "20px",
                  }}
                />
        
                {/* Skeletons for wave effects */}
                {[...Array(6)].map((_, waveIndex) => (
                  <Skeleton
                    key={waveIndex}
                    variant="text"
                    animation="wave"
                    width="100%" // Full width to match container
                    height={20}
                    style={{
                      marginBottom: "10px",
                      // borderRadius: "5px",
                    }}
                  />
                ))}
              </div>
            ))}
          </div>
            
                        ) : (
                          <section
                          className="section pricing-two bg-img"
                          style={{ backgroundColor: "white" }}
                        >
                          <div className="container">
                            <div className="row justify-content-center">
                              <div>
                                <div className="section__header">
                                  <h3 style={{ width: "100%" }} className="h3">
                                    Ready to Get Started?
                                  </h3>
                                </div>
                              </div>
                            </div>
                
                            {/* Plan Type Buttons */}
                            <div className="d-flex flex-wrap justify-content-center mt-4">
                              <button
                                onClick={() => handlePlanChange("monthly")}
                                style={getButtonStyle("monthly")}
                                className="btn btn--septenary me-2"
                              >
                                Monthly Plan
                              </button>
                              <button
                                onClick={() => handlePlanChange("yearly")}
                                style={getButtonStyle("yearly")}
                                className="btn btn--septenary me-2"
                              >
                                Yearly Plan
                              </button>
                            </div>
                
                            {/* Pricing Cards */}
                            <div className="row items-gap mt-4">
                              {pricingData.map((plan, index) => (
                                <div key={plan.plan_id} className="col-12 col-md-8 col-lg-6">
                                  <div
                                    className="pricing-two__single wow fadeInUp"
                                    data-wow-duration="600ms"
                                    data-wow-delay="1200ms"
                                    style={{ minWidth: "350px", maxWidth: "400px" }}
                                  >
                                    <h5 className={`h5 ${index === 0 ? "pri" : "tri"}`}>
                                      {plan.plane_name_ui} 
                                    </h5>
                                    <div className="meta">
                                      <div className="content">
                                        <h2 className="h2 title" style={{ color: "white" }}>
                                          {plan.price} <span style={{fontSize:"18px"}}>{plan.discount}</span>
                                        </h2> 
                                        <p>paid {planType === "monthly" ? "per month" : "per year"}</p>
                                      </div>
                                    </div>
                                    <hr />
                                    <ul>
                                      <li><i className="fa-solid fa-check"></i> {plan.user_access}</li>
                                      {/* <li><i className="fa-solid fa-check"></i> {plan.query_limit}</li>
                                      <li><i className="fa-solid fa-check"></i> {plan.ai_tools}</li> */}
                                      <li><i className="fa-solid fa-check"></i> {plan.recommendations}</li>
                                      <li><i className="fa-solid fa-check"></i> {plan.data_export}</li>
                                   
                
                                    </ul>
                                    <hr />
                                    <p style={{color:"white"}}>Expire Date : {plan.expiry_date}</p>
                                    <button
                                      onClick={() => handleSubmit(plan.price_id, plan.plane_name_ui)}
                                      className="btn btn--septenary mt-2"
                                    >
                                      Buy now
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </section>
                        )}
       
      </div>
    </div>
  );
}