
// RouteWrappers.js
import React from "react";

import { Navigate } from "react-router-dom";

export function PublicRoute({ isAuthenticated, children }) {
  if (isAuthenticated) {
    return <Navigate to="/" />; 
  }
  return children;
}

export function PrivateRoute({ isAuthenticated, children }) {
  if (!isAuthenticated) {
    return <Navigate to="/" />; 
  }
  return children;
}
