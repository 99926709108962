


import React, { useState, useEffect } from "react";
import axios from "axios";
import ChatHeader from "./Chatheader";

export default function Doctors() {
  const [selectedSpecialist, setSelectedSpecialist] = useState(null);
  const [specialists, setSpecialists] = useState([]);
    const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSpecialists = async () => {
      try {
       
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/api/get_specialists`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setSpecialists(response.data.result || []);
      } catch (error) {
        console.error("Error fetching specialists:", error);
      }
    };
    fetchSpecialists();
  }, []);

  const fetchDoctors = async (specialistId) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/api/get_doctors_near_me/${specialistId}`
        ,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data && Array.isArray(response.data.result)) {
        setDoctors(response.data.result || []); 
      } else {
        setDoctors([]); 
      }
    } catch (error) {
      console.error("Error fetching doctors:", error);
      setDoctors([]); 
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDoctors(0);
  }, []);

  const handleSpecialistClick = (specialistId) => {
    setSelectedSpecialist(specialistId);
    fetchDoctors(specialistId);
  };

  if (loading) {
    return <div>Loading doctors...</div>;
  }

  return (
    <div className="container mx-auto px-4">
      <div className="container mx-auto mt-5" style={{ width: "90%" }}>  <ChatHeader/></div>
      {/* Heading */}
      <h1 className="text-3xl font-bold text-center my-8">Recommended Specialists</h1>

      {/* Specialist Buttons */}
      <div className="flex justify-center space-x-4 mb-8">
        {specialists.map((specialist) => (
          <button
            key={specialist.specialist_id}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={() => handleSpecialistClick(specialist.specialist_id)}
          >
            {specialist.specialist_name.charAt(0).toUpperCase() +
              specialist.specialist_name.slice(1)}
          </button>
        ))}
        <button
          className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
          onClick={() => handleSpecialistClick(0)}
        >
          Show All
        </button>
      </div>

      {/* Doctor Cards */}
      <div className="container mx-auto" style={{ width: "90%" }}>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {doctors.length > 0 ? (
            doctors.map((doctor, index) => (
              <div
                key={index}
                className="bg-white shadow-md rounded-lg overflow-hidden transform transition duration-300 hover:scale-105"
              >
                {/* Hospital Info */}
                <div className="p-4">
                  <h2 className="text-lg font-bold">{doctor.hospital.name}</h2>
                  <p className="text-gray-500">{doctor.location}</p>
                  <p className="text-gray-500">{doctor.availability}</p>
                </div>

                {/* Hospital Image */}
                <div className="w-full h-44 overflow-hidden">
                  <img
                    src={doctor.hospital.cover_photo_url}
                    alt="Hospital"
                    className="w-full h-full object-cover"
                  />
                </div>

                {/* Doctor Info */}
                <div className="p-4">
                  <div className="flex items-center mb-4">
                    {/* Doctor's Profile Picture */}
                    <img
                      src={doctor.profile_photo_url}
                      alt={doctor.name}
                      className="w-16 h-16 rounded-full mr-4"
                    />
                    <div>
                      <h2 className="text-xl font-bold">{doctor.name}</h2>
                      <p className="text-gray-500">{doctor.specialty}</p>
                      <p className="text-black font-bold">{doctor.contact}</p>
                    </div>
                  </div>

                  {/* Schedule */}
                  <div>
                    <p className="font-bold mb-2">Schedule:</p>
                    <p>{doctor.availability}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>No doctors available for this specialty</div> 
          )}
        </div>
      </div>
    </div>
  );
}
