// Skeleton



import React from "react";
import Skeleton from "@mui/material/Skeleton";

const SkeletonLoader = ({ tooltipStyle }) => {
  return (
    <div className="skeleton-loader-container w-full mt-5">
      {/* Content Section */}
      <div className="content-section w-full p-5">
        {/* Date */}
        <div className="date mb-3 flex items-center">
          <Skeleton variant="text" width="20%" height={20} style={{ marginRight: "5px" }} />
          <Skeleton variant="text" width="60%" height={20} />
        </div>

        {/* Title */}
        <div className="title mb-3">
          <Skeleton variant="text" width="80%" height={30} style={{ marginBottom: "10px" }} />
        </div>

        {/* Sources Section */}
        <div className="sources mb-5">
          <div className="source-title mb-3">
            <Skeleton variant="text" width="10%" height={20} />
          </div>
          <div className="source-card flex gap-3 mb-4">
            <Skeleton variant="rectangular" width={350} height={80} style={{ borderRadius: "5px" }} />
            <Skeleton variant="rectangular" width={150} height={80} style={{ borderRadius: "5px" }} />
          </div>
          <div className="flex items-center mt-3 gap-2">
            <Skeleton variant="rectangular" width={700} height={450} style={{ borderRadius: "5px" }} />
            {/* <Skeleton variant="rectangular" width={400} height={30} style={{ borderRadius: "5px" }} /> */}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* Skeleton for Icon Button (Explore) */}
          <div className="relative inline-block" style={tooltipStyle}>
            <Skeleton
              variant="rectangular"
              width={120}
              height={40}
              style={{
                borderRadius: "5px",
                marginBottom: "10px",
                marginRight: "5px",
              }}
            />
          </div>

          {/* Skeleton for Like Button */}
          <div style={tooltipStyle}>
            <Skeleton
              variant="rectangular"
              width={50}
              height={40}
              style={{
                borderRadius: "5px",
                marginBottom: "10px",
                marginRight: "5px",
              }}
            />
          </div>

          {/* Skeleton for Dislike Button */}
          <div style={tooltipStyle}>
            <Skeleton
              variant="rectangular"
              width={50}
              height={40}
              style={{
                borderRadius: "5px",
                marginBottom: "10px",
                marginRight: "5px",
              }}
            />
          </div>

          {/* Skeleton for Copy Button */}
          <div style={tooltipStyle}>
            <Skeleton
              variant="rectangular"
              width={50}
              height={40}
              style={{
                borderRadius: "5px",
                marginBottom: "10px",
                marginRight: "5px",
              }}
            />
          </div>

          {/* Skeleton for Download Button */}
          <div style={tooltipStyle}>
            <Skeleton
              variant="rectangular"
              width={50}
              height={40}
              style={{
                borderRadius: "5px",
                marginBottom: "10px",
                marginRight: "5px",
              }}
            />
          </div>
        </div>
    
<div >
       

        {/* <hr /> to separate the skeleton card */}
        <hr style={{ borderColor: "#e8e9e8", borderWidth: "1px", width: "100%" }} />
        {/* <div className="fixed bottom-0 left-0 w-full flex justify-center p-4" style={{ zIndex: 10 }}>
        <div className="border p-3 bg-gray-100 rounded-lg shadow-md" style={{ width: "90%", maxWidth: "600px", height: "70px" }}>
          <Skeleton variant="text" width="100%" height="100%" style={{ borderRadius: "10px" }} />
        </div>
      </div> */}
      </div>
      {/* Bottom Bar Section */} </div>
     
    
    </div>
  );
};

export default SkeletonLoader;