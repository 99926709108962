import React, { useState, useEffect, useRef } from "react";

import { BiSolidSend } from "react-icons/bi";


import { useTranslation } from "react-i18next";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { FaBrain } from "react-icons/fa";
import { FaHeartbeat } from "react-icons/fa";
import { FaCandyCane } from "react-icons/fa";
import { FaAppleAlt } from "react-icons/fa";
import { CgAttachment } from "react-icons/cg";
import { IoDocumentAttachSharp } from "react-icons/io5";


export default function Firstchat({ onSubmit })  {
  const [question, setQuestion] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const textareaRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();


  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };
  // const handleSubmit = () => {
  //   // Check if the question is empty or no file is selected
  //   if (!question.trim() && !selectedFile) {
  //     toast.error("Please enter a question or attach a file before submitting.");
  //     return;
  //   }
  
  //   // If valid, navigate with the question and selected file
  //   navigate("/chat", { state: { question, selectedFile } });
  
  //   // Reset the inputs
  //   setQuestion("");
  //   setSelectedFile(null);
  // };
  
  const handleSubmit = ({ question = "", selectedFile }) => {
    // Make sure question is a string (even if empty)
    console.log("Question being passed:", question);
    console.log("File being passed to handleSubmit:", selectedFile);
  
    if (!question.trim() && !selectedFile) {
      toast.error("Please enter a question or attach a file before submitting.");
      return;
    }
  
    // Navigate with the question and file
    console.log("Navigating with state:", { question, selectedFile });
    navigate("/chat", { state: { question, selectedFile } });
  
    // Reset inputs
    setQuestion("");
    setSelectedFile(null);
  };
  
  // const handleSubmit = (input) => {
  //   const file = input?.selectedFile || selectedFile;
  
  //   // Check what is being passed
  //   console.log("Question being passed:", question);
  //   console.log("File being passed to handleSubmit:", file);
  
  //   if (input?.preventDefault) {
  //     input.preventDefault();
  //   }
  
  //   // Check if both question and file are empty
  //   if (!question.trim() && !file) {
  //     toast.error("Please enter a question or attach a file before submitting.");
  //     return;
  //   }
  
  //   // Navigate with the question and file
  //   console.log("Navigating with state:", { question, selectedFile: file });
  //   navigate("/chat", { state: { question, selectedFile: file } });
  
  //   // Reset inputs
  //   setQuestion("");
  //   setSelectedFile(null);
  // };
  

  // const handleSubmit = (input) => {
  //   const file = input?.selectedFile || selectedFile;

  //   // Check what is being passed
  //   console.log("Question being passed:", question);
  //   console.log("File being passed to handleSubmit:", file);

  //   if (input?.preventDefault) {
  //     input.preventDefault();
  //   }

  //   if (!question.trim() && !file) {
  //     toast.error("Please enter a question or attach a file before submitting.");
  //     return;
  //   }

  //   // Navigate with the question and file
  //   console.log("Navigating with state:", { question, selectedFile: file });
  //   navigate("/chat", { state: { question, selectedFile: file } });

  //   // Reset inputs
  //   setQuestion("");
  //   setSelectedFile(null);
  // };

  
  
  
  // const handleSubmit = (e) => {
  //   if (typeof e === "string") {
  //     setQuestion(e);
  //     onSubmit(e);
  //     onSubmit(e, selectedFile);

  //     navigate("/chat", { state: { question: e } });
  //   } else {
  //     e.preventDefault();
  //     if (question) {
  //       onSubmit(question, selectedFile);
  //       navigate("/chat", { state: { question } });
  //     }
  //   }
  // };
  
  const handleSuggestedQuestionClick = (suggestedQuestion) => {
    if (suggestedQuestion) {
      handleSubmit({ question: suggestedQuestion, selectedFile });
      // handleSubmit();
      console.log(suggestedQuestion,"suggestedQuestion")
    }
  };
  
  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };


  useEffect(() => {
    const intervalId = setInterval(changeText, 2000);
    return () => clearInterval(intervalId);
  }, []);
  const texts = ["Healthcare", "Biology", "Medicine"];
  const [slideIndex, setSlideIndex] = useState(0);

  const changeText = () => {
    setSlideIndex((prevIndex) => (prevIndex + 1) % 3);
  };
  const resizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };
  const handleFileIconClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const allowedFileTypes = [
      "image/jpeg",
      "image/png",
      "application/pdf",
      "text/csv",
      "application/msword",
      "text/plain",
    ];
  
    if (file) {
      console.log("File selected:", file);
      if (!allowedFileTypes.includes(file.type)) {
        toast.error(
          "Invalid file type. Please upload a valid text, image, PDF, CSV, Word, or TXT file."
        );
        setSelectedFile(null);
        return;
      }
  
      setSelectedFile(file);
      toast.success("File attached successfully.");
  
      // Call handleSubmit with empty question and selected file
      handleSubmit({ question: "", selectedFile: file });
    }
  };
  
  
  // const handleFileChange = async (e) => {
  //   const file = e.target.files[0];
  //   const allowedFileTypes = [
  //     "image/jpeg",
  //     "image/png",
  //     "application/pdf",
  //     "text/csv",
  //     "application/msword",
  //     "text/plain",
  //   ];

  //   if (file) {
  //     if (!allowedFileTypes.includes(file.type)) {
  //       toast.error(
  //         "Invalid file type. Please upload a valid text, image, PDF, CSV, Word, or TXT file."
  //       );
  //       setSelectedFile(null);
  //       return;
  //     }

  //     setSelectedFile(file);
  //     toast.success("File attached successfully.");
  //   }
  // };
 

  const getGridStyles = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1024) {
      return {
        display: "grid",
        gap: "10px",
        gridTemplateColumns: "1fr 1fr",
      };
    } else {
      return {
        display: "grid",
        gap: "10px",
        gridTemplateColumns: "1fr",
      };
    }
  };


  return (
    <div className="flex flex-col">
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            marginTop: "50px",
          },
        }}
      />

<div
      className={`flex flex-col mt-2 p-5 bg-white rounded-lg transition-all duration-300 ${
        isSidebarOpen ? "md:ml-[450px] md:w-[calc(100%-450px)]" : "w-full"
      }`}
    >
    
          <div
            className="flex justify-center items-center"
            style={{ height: "100vh", width: "80%" }}
          >
            <div className="sm:w-12/11 md:w-3/4 lg:w-2/3 ">
              <p className="h3 text-xl mt-10 md:mt-0 lg:mt-0 text-center font-semibold">
                Ask Questions About <span>{texts[slideIndex]}</span>
              </p>
            
              <div className="mt-5">
                <div
                  className="border flex justify-between items-center"
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    backgroundColor: "white",
                    height:"120px"

                  }}
                >
                  <textarea
                    ref={textareaRef}
                    className=" p-2 text-sm text-dark resize-none flex-grow"
                    style={{
                      width: "100%",
                      color: "black",
                      overflowY: "auto",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                      textTransform: "none",
                      paddingRight: "5px",
                      // height:"80px"
                    }}
                    placeholder="Ask Question"
                    required
                    value={question}
                    onChange={handleQuestionChange}
                    rows={1}
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <button
                    type="button"
                    className="  bottom-2.5 font-large rounded-lg lg:text-2xl md:text-2xl text-xl"
                    style={{ color: "#4569e7" }}
                    onClick={handleFileIconClick}
                  >
                    {selectedFile ? (
                      <IoDocumentAttachSharp  style={{  marginTop:"54px" }} />
                    ) : (
                      <CgAttachment
                        style={{ color: "#C5C6C7", marginTop:"54px" }}
                      />
                    )}
                  </button>

                  <button
                    type="submit"
                    className="font-large text-2xl mt-14"
                    style={{
                      // color: "#C5C6C7",
                      padding: "5px",
                    }}
                    onClick={handleSubmit}
                  >
                    {/* <AiOutlineSend className="hover:text-blue-500" /> */}
                    <BiSolidSend className={question ? "text-gray-900" : "text-gray-300"} />

                  </button>
                </div>
              </div>

              <div className="mt-10" style={getGridStyles()}>
                {[
                  {
                    question: "How to lower high blood pressure naturally?",
                    icon: <FaHeartbeat color="#E74C3C" size={30} />,
                  },
                  {
                    question: "What are the signs of early diabetes?",
                    icon: <FaCandyCane color="#F39C12" size={30} />,
                  },
                  {
                    question: "How to boost mental health quickly?",
                    icon: <FaBrain color="#3498DB" size={30} />,
                  },
                  {
                    question: "What foods help reduce cholesterol levels?",
                    icon: <FaAppleAlt color="#27AE60" size={30} />,
                  },
                ].map(({ question, icon }, index) => (
                  <div
                    key={index}
                    className="question-item"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#C5C6C7",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="border rounded-xl p-2 flex items-center gap-2"
                      style={{
                        width: "100%",
                        color: "#C5C6C7",
                      }}
                    >
                      <div className="icon-container mr-2">{icon}</div>
                      <p
                        className="text-start flex-1 text-sm"
                        style={{
                          marginBottom: "0",
                          marginTop: "0",
                        }}
                        onClick={() => handleSuggestedQuestionClick(question)}
                      >
                        {question}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
       
        
      </div>
    </div>
  );
}
