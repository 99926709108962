import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { GoHome } from "react-icons/go";
import { BsThreeDots } from 'react-icons/bs';
import {
  MdOutlineLocationSearching,
  MdOutlineLocalLibrary,
} from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import { AiOutlinePlus } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";

import { LuArrowLeftFromLine } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaArrowRightToBracket } from "react-icons/fa6";
import { AiOutlineFileText } from "react-icons/ai";
import { IoSettingsOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { BiHistory } from 'react-icons/bi';
import axios from 'axios';
const SidebarContainer = styled.div`
  height: 100vh;
  width: ${(props) => (props.isOpen ? "300px" : "0")};
  position: fixed;
  top: 0;
  left: 0;
overflow-y: auto; /* Allow vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  background-color: rgb(242, 248, 250);
  overflow-x: hidden;
  transition: width 0.3s;
  z-index: 1000; 
`;
const Header = styled.div`
  display: ${(props) => (props.isOpen ? "flex" : "block")};
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
`;
const Logo = styled.div`
  padding: 10px 0;
  color: blach;
  font-size: 20px;
`;
const CloseButton = styled.span`
  font-size: 34px;
  cursor: pointer;
  color: black;
  margin-left: ${(props) => (props.isOpen ? "auto" : "0")};
  margin-top: ${(props) => (props.isOpen ? "0" : "20px")};
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    display: ${(props) => (props.isOpen ? "none" : "block")};
  }
  .icon-open {
    display: ${(props) => (props.isOpen ? "block" : "none")};
  }
`;

const SidebarLink = styled.a`
  padding: 8px 8px 8px ${(props) => (props.isOpen ? "25px" : "8px")};
  text-decoration: none;
  font-size: 15px;
  color: #005477;
  display: flex;
  align-items: center;
  transition: 0.3s;
  white-space: nowrap;
  margin-top: ${(props) => (props.isOpen ? "0" : "10px")}; 
  &:hover {
    color: black;
  }
  span {
    display: ${(props) => (props.isOpen ? "inline" : "none")};
    margin-left: 10px;
    color: #005477;
  }
`;

const HistoryLink = styled.div`
  padding: 5px 5px 5px ${(props) => (props.isOpen ? "20px" : "5px")}; 
  text-decoration: none;
  font-size: 8px;
  color: #005477;
  align-items: center;
  transition: 0.3s;
  white-space: nowrap;
  // margin-bottom: 5px; 
  margin-top: 5px; 
  cursor: pointer; 
  background-color: ${(props) => (props.selected ? "#e0e0e0" : "transparent")}; 
  border-left: ${(props) => (props.selected ? "3px solid #005477" : "none")}; 
  padding-left: ${(props) => (props.selected ? "22px" : "20px")}; 
  &:hover {
    color: black;
  }
`;

const ChatHistoryContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 5px;
  margin-top: 40px; 
  height:55%;
`;

const FooterContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const OpenButton = styled.button`
  font-size: 24px;
  cursor: pointer;
  // background-color: #111;
  color: black;
  border: none;
  position: absolute;
  top: 20px;
   left: ${(props) => (props.isOpen ? "-100px" : "20px")};
  // left: ${(props) => (props.isOpen ? "200px" : "60px")};
  transition: left 0.3s;
`;
const Sidebar = ({ onUpgradeClick, onPlanClick, onSettingClick ,onHistoryClick}) => {
  const navigate = useNavigate();
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(true);
  console.log("Sidebar props:", { onUpgradeClick });
  const [selectedChat, setSelectedChat] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const handleChatClick = (chatId) => {
    setSelectedChat(chatId);
    navigate(`/oldchat?id=${chatId}`);
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 750) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [isOpen]);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // Function to fetch chat history
    const fetchChatHistory = async () => {
      try {

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/api/get_all_chats`
          ,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setChatHistory(response.data.result || []); 
                console.log(response,"chat history")
      } catch (error) {
        console.error("Error fetching specialists:", error);
      }
    };
    fetchChatHistory();

    const handleSidebarUpdate = () => {
      fetchChatHistory(); 
    };

    window.addEventListener("sidebarUpdate", handleSidebarUpdate);

    return () => {
      window.removeEventListener("sidebarUpdate", handleSidebarUpdate);
    };
  }, []);

  const handleUpgrade = () => {
    console.log("Handling upgrade");
    setSelectedChat(null); 
    if (typeof onUpgradeClick === "function") {
      onUpgradeClick();
    } else {
      console.error("onUpgradeClick is not a function");
    }
  };
  const handleHistory = () => {
    setSelectedChat(null); 
    console.log("Handling upgrade");
    if (typeof onHistoryClick === "function") {
      onHistoryClick();
    } else {
      console.error("onHistoryClick is not a function");
    }
  };
  const handlePlan = () => {
    setSelectedChat(null); 
    console.log("Handling upgrade");
    if (typeof onPlanClick === "function") {
      onPlanClick();
    } else {
      console.error("onUpgradeClick is not a function");
    }
  };
  const handleSetting = () => {
    setSelectedChat(null); 
    console.log("Handling Setting ");
    if (typeof onSettingClick === "function") {
      onSettingClick();
    } else {
      console.error("Setting is not a function");
    }
  };
  const handleSignOut = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
        toast.error("No token found, please login again.");
        navigate("/signin"); // Redirect to /signin
        return;
    }

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/user/api/sign_out`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (response.status === 200) {
            localStorage.removeItem("token"); // Remove token
            toast.success("Logout successfully");
            setIsAuthenticated(false);
            navigate("/signin"); // Redirect to /signin after logout
        } else {
            const errorData = await response.json();
            toast.error(`Sign-out failed: ${errorData.message}`);
        }
    } catch (error) {
        console.error("Error signing out:", error);
        toast.error("An error occurred while signing out. Please try again.");
    }
};

  // const handleSignOut = async () => {
  //   setSelectedChat(null); 
  //   const token = localStorage.getItem("token");

  //   if (!token) {
  //     toast.error("No token found, please login again.");
  //     navigate("/signin");
  //     return;
  //   }
  //   setLoading(true); 

  //   try {

  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/user/api/sign_out`
  //       ,
  //       {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     console.log(response, "logout");
  //     if (response.status === 200) {
  //       localStorage.removeItem("token");
  //       toast.success("Logout successfully");
  //       navigate("/signin");
  //     } else {
  //       const errorData = await response.json();
  //       toast.error(`Sign-out failed: ${errorData.message}`);
  //     }
  //   } catch (error) {
  //     console.error("Error signing out:", error);
  //     toast.error("An error occurred while signing out. Please try again.");
  //   }
  //   finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <>
  {!isOpen && (
        <OpenButton onClick={toggleSidebar} isOpen={isOpen}>
          <FaArrowRightToBracket   className="icon"
             />
        </OpenButton>
      )}
 <SidebarContainer isOpen={isOpen}>
        <Header isOpen={isOpen}>
          {/* Logo and close button */}
          <Logo>
            <a href="/">
              <img
                style={{ width: isOpen ? "130px" : "40px" }}
                src={
                  isOpen
                    ? "/assets/images/logo.png"
                    : "/assets/images/logoicon.png"
                }
                alt="Logo"
              />
            </a>
          </Logo>
          <CloseButton onClick={toggleSidebar}>
          {/* <FaArrowRightToBracket
              className="icon"
              style={{ fontSize: "24px" }}
            /> */}
            <IoMdClose className="icon"
              style={{ fontSize: "24px" }}/>
            <div className="icon-open"> &times;</div>          </CloseButton>
        </Header>
        <Link to="/"        onClick={() => setSelectedChat(null)} 
        >
<SidebarLink className="mt-3" isOpen={isOpen} >
  <AiOutlinePlus style={{ fontSize: "25px" }} />
  <span className="font-semibold ">New Chat</span>
</SidebarLink>
</Link>
<ChatHistoryContainer>
        {Object.entries(chatHistory).map(([period, chats]) => (
          <div key={period} >
            <p style={{fontSize:"13px"}} className="font-semibold p-3">{period.replace(/_/g, ' ').toUpperCase()}</p>
            {chats.length > 0 ? (
              <ul>
                {chats.map((chat) => (
                  <HistoryLink
                    key={chat.chat_id}
                    selected={selectedChat === chat.chat_id}
                    onClick={() => handleChatClick(chat.chat_id)}
                    className="flex gap-2"
                  >
                    <span>{chat.chat_title.length > 18 ? chat.chat_title.slice(0, 28) + "" : chat.chat_title}</span>
                    {selectedChat === chat.chat_id && <BsThreeDots style={{ fontSize: "14px" }} />}
                  </HistoryLink>
                ))}
              </ul>
            ) : (
              <p></p>
            )}
          </div>
        ))}
      </ChatHistoryContainer>



        <FooterContainer>
<div style={{marginTop:"50px"}}><Link to="/upgrade">
<SidebarLink isOpen={isOpen} onClick={handleUpgrade} >
          <MdOutlineLocationSearching  style={{ fontSize: "25px" }} />
          <span className="font-semibold " style={{ fontSize: "15px" }}>Upgrade</span>
        </SidebarLink>
        </Link>
        <Link to="/plan">

        <SidebarLink isOpen={isOpen} onClick={handlePlan} >
          <AiOutlineFileText style={{ fontSize: "25px" }} />
          <span className="font-semibold " style={{ fontSize: "15px" }}>Plan Details</span>
        </SidebarLink>
        </Link>
        <Link to="/setting">
        <SidebarLink isOpen={isOpen} onClick={handleSetting} > 
          <IoSettingsOutline style={{ fontSize: "25px" }} />
          <span className="font-semibold " style={{ fontSize: "15px" }}>Setting</span>
        </SidebarLink>
        </Link>
        <SidebarLink isOpen={isOpen} onClick={handleSignOut}>
        {loading ? (
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ color: "#005477" }}></span>
                        ) : (
                          <GoSignOut style={{ fontSize: "25px" }} />
                        )}
          
          <span className="font-semibold "style={{ fontSize: "15px" }}>Sign Out</span>
        </SidebarLink></div>

        </FooterContainer>
      </SidebarContainer>
      {/* <SidebarContainer isOpen={isOpen}>
        <Header isOpen={isOpen}>
          <Logo>
            <a href="/">
              <img
                style={{ width: isOpen ? "130px" : "40px" }}
                src={
                  isOpen
                    ? "/assets/images/logo.png"
                    : "/assets/images/logoicon.png"
                }
                alt="Logo"
              />
            </a>
          </Logo>

          <CloseButton isOpen={isOpen} onClick={toggleSidebar}>
            <FaArrowRightToBracket
              className="icon"
              style={{ fontSize: "24px" }}
            />
            <div className="icon-open"> &times;</div>
          </CloseButton>
        </Header>
        <Link to="/dashboard/chat">

        <SidebarLink className="mt-3" isOpen={isOpen} >
          <AiOutlinePlus style={{ fontSize: "25px" }} />
          <span className="font-semibold ">New Chat</span>
        </SidebarLink>
        </Link>
       
   
<ChatHistoryContainer>
        {Object.entries(chatHistory).map(([period, chats]) => (
          <div key={period} >
            <p style={{fontSize:"13px"}} className="font-semibold p-3">{period.replace(/_/g, ' ').toUpperCase()}</p>
            {chats.length > 0 ? (
              <ul>
                {chats.map((chat) => (
                  <HistoryLink
                    key={chat.chat_id}
                    selected={selectedChat === chat.chat_id}
                    onClick={() => handleChatClick(chat.chat_id)}
                    className="flex gap-2"
                  >
                    <span>{chat.chat_title.length > 18 ? chat.chat_title.slice(0, 28) + "" : chat.chat_title}</span>
                    {selectedChat === chat.chat_id && <BsThreeDots style={{ fontSize: "14px" }} />}
                  </HistoryLink>
                ))}
              </ul>
            ) : (
              <p></p>
            )}
          </div>
        ))}
      </ChatHistoryContainer>



        <FooterContainer>
<div style={{marginTop:"50px"}}><Link to="/dashboard/upgrade">
<SidebarLink isOpen={isOpen} onClick={handleUpgrade} >
          <MdOutlineLocationSearching  style={{ fontSize: "25px" }} />
          <span className="font-semibold " style={{ fontSize: "15px" }}>Upgrade</span>
        </SidebarLink>
        </Link>
        <Link to="/dashboard/plan">

        <SidebarLink isOpen={isOpen} onClick={handlePlan} >
          <AiOutlineFileText style={{ fontSize: "25px" }} />
          <span className="font-semibold " style={{ fontSize: "15px" }}>Plan Details</span>
        </SidebarLink>
        </Link>
        <Link to="/dashboard/setting">
        <SidebarLink isOpen={isOpen} onClick={handleSetting} > 
          <IoSettingsOutline style={{ fontSize: "25px" }} />
          <span className="font-semibold " style={{ fontSize: "15px" }}>Setting</span>
        </SidebarLink>
        </Link>
        <SidebarLink isOpen={isOpen} onClick={handleSignOut}>
        {loading ? (
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ color: "#005477" }}></span>
                        ) : (
                          <GoSignOut style={{ fontSize: "25px" }} />
                        )}
          
          <span className="font-semibold "style={{ fontSize: "15px" }}>Sign Out</span>
        </SidebarLink></div>

        </FooterContainer>

      </SidebarContainer> */}
    </>
  );
};
export default Sidebar;