import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsAuthenticated(!!token);
  }, []);

  const handleGetStartedClick = () => {
    if (isAuthenticated) {
      localStorage.removeItem("token");
      setIsAuthenticated(false);
      toast.success("Logout Successfully");
      navigate("/signin");
    } else {
      navigate("/signin");
    }
  };
  const handleScrollClick = (to) => {
    if (location.pathname !== "/") {
      navigate("/", { state: { scrollTo: to } });
    } else {
      const element = document.getElementById(to);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  const hide = () => {
    const windowWidth = window.innerWidth;
    return windowWidth < 768 ? { display: "none" } : { display: "flex" };
  };

  return (
    <div>
      {" "}
      <header class="header header--light">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav class="nav">
                <div class="nav__content">
                  <div class="nav__logo">
                    <a href="/">
                      <img
                        style={{ width: "200px" }}
                        src="/assets/images/logo.png"
                        alt="Logo"
                      />
                    </a>
                  </div>
                  <div class="nav__menu">
                    <ul class="nav__menu-items">
                      <li className="nav__menu-item ">
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleScrollClick("howitwork");
                          }}
                          className="nav__menu-link hide-nav"
                        >
                          How it works
                        </a>
                      </li>
                      <li class="nav__menu-item">
                        <a href="/aboutus" class="nav__menu-link hide-nav">
                          About MedicalGPT
                        </a>
                      </li>
                      <li className="nav__menu-item nav__menu-item--dropdown">
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleScrollClick("keyfeatuers");
                          }}
                          className="nav__menu-link hide-nav"
                        >
                          Features{" "}
                        </a>
                      </li>

                      <li className="nav__menu-item nav__menu-item--dropdown  hidden md:flex">
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleScrollClick("ourmission");
                          }}
                          className="nav__menu-link hide-nav hidden md-display xl-display lg-display"
                        >
                          Our Vision{" "}
                        </a>
                      </li>

                      <li class="nav__menu-item">
                        <a href="/contactus" class="nav__menu-link hide-nav">
                          Contact Us
                        </a>
                      </li>
                      <li class="nav__menu-item">
                        <a href="/blog" class="nav__menu-link hide-nav">
                          Blog
                        </a>
                      </li>
                    </ul>
                    <div class="social">
                      <a href="index.html" aria-label="social media">
                        <i class="fa-brands fa-facebook-f"></i>
                      </a>
                      <a href="index.html" aria-label="social media">
                        <i class="fa-brands fa-twitter"></i>
                      </a>
                      <a href="index.html" aria-label="social media">
                        <i class="fa-brands fa-linkedin-in"></i>
                      </a>
                      <a href="index.html" aria-label="social media">
                        <i class="fa-brands fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                  <div class="nav__uncollapsed">
                    <div class="nav__uncollapsed-item d-none d-md-flex">
                      <a
                        onClick={handleGetStartedClick}
                        class="btn btn--secondary"
                      >
                        {isAuthenticated ? "Sign Out" : "Sign In"}
                        <i class="fa-solid fa-arrow-right-to-bracket"></i>
                      </a>
                    </div>
                    <button class="nav__bar d-block d-xl-none">
                      <span class="icon-bar top-bar"></span>
                      <span class="icon-bar middle-bar"></span>
                      <span class="icon-bar bottom-bar"></span>
                    </button>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div class="backdrop"></div>
      </header>
      <div class="mobile-menu d-block d-xl-none">
        <nav class="mobile-menu__wrapper">
          <div class="mobile-menu__header">
            <div class="nav__logo">
              <a href="/" aria-label="home page" title="logo">
                <img
                  // style={{ width: "200px" }}
                  src="/assets/images/logo.png"
                  alt="Logo"
                />{" "}
              </a>
            </div>
            <button aria-label="close mobile menu" class="close-mobile-menu">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          {/* <div class="mobile-menu__list"></div> */}
          <ul class="nav__menu-items">
            <li
              className="nav__menu-item close-mobile-menu"
              aria-label="close mobile menu"
            >
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleScrollClick("howitwork");
                }}
                className="nav__menu-link hide-nav"
              >
                How it works
              </a>
            </li>
            <li class="nav__menu-item">
              <a href="/aboutus" class="nav__menu-link hide-nav">
                About MedicalGPT
              </a>
            </li>
            {/* <li className="nav__menu-item nav__menu-item--dropdown"> */}
            <li
              className="nav__menu-item close-mobile-menu"
              aria-label="close mobile menu"
            >
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleScrollClick("keyfeatuers");
                }}
                className="nav__menu-link hide-nav"
              >
                Features{" "}
              </a>
            </li>

            <li
              className="nav__menu-item close-mobile-menu"
              aria-label="close mobile menu"
            >
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleScrollClick("ourmission");
                }}
                class="nav__menu-link hide-nav"
              >
                Our Vision{" "}
              </a>
            </li>

            <li class="nav__menu-item">
              <a href="/contactus" class="nav__menu-link hide-nav">
                Contact Us
              </a>
            </li>
            <li class="nav__menu-item">
              <a href="/blog" class="nav__menu-link hide-nav">
                Blog
              </a>
            </li>
          </ul>
          <div class="mobile-menu__social social">
            <a href="index.html" aria-label="social media">
              <i class="fa-brands fa-facebook-f"></i>
            </a>
            <a href="index.html" aria-label="social media">
              <i class="fa-brands fa-twitter"></i>
            </a>
            <a href="index.html" aria-label="social media">
              <i class="fa-brands fa-linkedin-in"></i>
            </a>
            <a href="index.html" aria-label="social media">
              <i class="fa-brands fa-instagram"></i>
            </a>
          </div>
        </nav>
      </div>
    </div>
  );
}
