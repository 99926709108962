// import React, { useState, useEffect } from "react";
// import { useTranslation } from "react-i18next";
// import Result from "../../Result";

// export default function Mainsection() {
//   const [submittedQuestion, setSubmittedQuestion] = useState("");
//   const { t, i18n } = useTranslation();
//   const [showIntro, setShowIntro] = useState(true);

//   const handleQuestionSubmit = (question) => {
//     setSubmittedQuestion(question);
//     setShowIntro(false);
//   };

//   const [selectedLanguage, setSelectedLanguage] = useState("en");
//   const handleLanguageChange = (language) => {
//     setSelectedLanguage(language);
//     changeLanguage(language);
//   };

//   const changeLanguage = (language) => {
//     i18n.changeLanguage(language);
//   };

//   const handleReset = () => {
//     setSubmittedQuestion("");
//     setShowIntro(true);
//   };

//   useEffect(() => {
//     const intervalId = setInterval(changeText, 2000);
//     return () => clearInterval(intervalId);
//   }, []);

//   const texts = ["Healthcare", "Biology", "Medicine"];
//   const [slideIndex, setSlideIndex] = useState(0);

//   const changeText = () => {
//     setSlideIndex((prevIndex) => (prevIndex + 1) % 3);
//   };

//   return (
//     <div className="flex flex-col min-h-screen ">
//       <div className="flex justify-end  pr-8"></div>

//       <div className="  top-0 left-0 w-full h-full flex flex-col items-center justify-center+ ">
//         {showIntro && (
//           <div
//             className="lg:text-2xl gap-3 mb-5  text-2xl md:w-[400px] lg:w-[900px] text-center   md:p-6 font-semibold m-auto w-[300px]"
//             style={{ fontSize: "60px", marginTop: "200px" }}
//           >
//             {/* <div className="main  " style={{ color: "#005EA3" }}>
//               {t("main.subtitle")}
//             </div> */}

//             <div
//               id="textContainer"
//               class=" main flex  gap-3 text-center justify-center fs-5 pxitems-center mb-4 text-red-500"
//               style={{ marginTop: "50px" }}
//             >
//               <h3 className="h1"> {texts[slideIndex]}</h3>
//             </div>
//           </div>
//         )}

//         <div class="nav__uncollapsed">
//           <div class="nav__uncollapsed-item d-md-flex ">
//             <a
//               href="/signin"
//               class="btn btn--secondary"
//               style={{ backgroundColor: "#0275d8" }}
//             >
//               Get Started
//               <i class="fa-solid fa-arrow-right-to-bracket"></i>
//             </a>
//           </div>
//           <button class="nav__bar d-block d-xl-none">
//             <span class="icon-bar top-bar"></span>
//             <span class="icon-bar middle-bar"></span>
//             <span class="icon-bar bottom-bar"></span>
//           </button>
//         </div>

//         <div
//           className="main flex justify-center   rounded"
//           // style={{ backgroundColor: "red" }}
//         >
//           <Result
//             question={submittedQuestion}
//             onSubmitQuestion={handleQuestionSubmit}
//             onReset={handleReset}
//           />
//         </div>
//       </div>
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Result from "../../Result";
import { useNavigate } from "react-router-dom";

export default function Mainsection() {
  const [submittedQuestion, setSubmittedQuestion] = useState("");
  const { t, i18n } = useTranslation();
  const [showIntro, setShowIntro] = useState(true);
  const navigate = useNavigate();

  const handleQuestionSubmit = (question) => {
    setSubmittedQuestion(question);
    setShowIntro(false);
  };

  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    changeLanguage(language);
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const handleReset = () => {
    setSubmittedQuestion("");
    setShowIntro(true);
  };

  useEffect(() => {
    const intervalId = setInterval(changeText, 2000);
    return () => clearInterval(intervalId);
  }, []);

  const texts = ["Healthcare", "Biology", "Medicine"];
  const [slideIndex, setSlideIndex] = useState(0);

  const changeText = () => {
    setSlideIndex((prevIndex) => (prevIndex + 1) % 3);
  };
  const handleButtonClick = () => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    } else {
      navigate("/signup");
    }
  };

  const token = localStorage.getItem("token");

  return (
    <div>
      {" "}
      <section class="banner-seven">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12">
              <div
                class="banner-seven__content text-center wow fadeInUp"
                data-wow-duration="600ms"
                data-wow-delay="300ms"
              >
                <h1 class="text-uppercase fw-8">MedicalGPT</h1>
                <p class="text-lg fw-5">
                MedicalGPT for Accurate and Reliable Medical Information, Powered by Advanced AI Technology.

                </p>
                <div class="nav__uncollapsed-item d-md-flex  justify-center items-center mt-8 ">
                  <a
                    class="btn btn--secondary"
                    style={{ backgroundColor: "#4569e7", borderRadius: "10px" }}
                    onClick={handleButtonClick}
                  >
                    {token ? "Dashboard" : "Get Started"}
                    <i
                      style={{ marginLeft: "8px" }}
                      class="fa-solid fa-arrow-right-to-bracket"
                    ></i>
                  </a>
                </div>
                {/* <div class="mail-group-two">
                  <select class="styles">
                    <option data-display="Style">Styles</option>
                    <option value="1">Style One</option>
                    <option value="2">Style Two</option>
                  </select>
                  <input
                    type="email"
                    name="search"
                    id="search-field"
                    placeholder="describe what you want to see"
                    required
                  />
                  <button
                    type="submit"
                    aria-label="subscribe to our newsletter"
                    class="btn btn--nonary"
                  >
                    <span class="d-block">Generate now</span>
                    <img src="assets/images/home-six/ser.png" alt="Image" />
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="flex flex-col min-h-screen ">
        <div className="flex justify-end  pr-8"></div>

        <div className="  top-0 left-0 w-full h-full flex flex-col items-center justify-center+ ">
          {showIntro && (
            <div
              className="lg:text-2xl gap-3   text-2xl md:w-[400px] lg:w-[900px] text-center   md:p-6 font-semibold m-auto w-[300px] flex"
              style={{ fontSize: "60px" }}
            >
              <h4 style={{ fontSize: "65px" }} className="h2">
                ASK QUESTIONS ABOUT
              </h4>
              <div
                id="textContainer"
                class=" main flex  gap-3 text-center justify-center fs-5 pxitems-center mb-4 text-red-500"
                style={{ marginTop: "50px" }}
              >
                <h4
                  style={{ marginTop: "-30px", fontSize: "50px" }}
                  className="h2"
                >
                  {texts[slideIndex]}
                </h4>
              </div>
            </div>
          )}

          <div class="nav__uncollapsed">
            <div class="nav__uncollapsed-item d-md-flex ">
              <a
                class="btn btn--secondary"
                style={{ backgroundColor: "#0275d8" }}
                onClick={handleGetStartedClick}
              >
                Get Started
                <i class="fa-solid fa-arrow-right-to-bracket"></i>
              </a>
            </div>
            <button class="nav__bar d-block d-xl-none">
              <span class="icon-bar top-bar"></span>
              <span class="icon-bar middle-bar"></span>
              <span class="icon-bar bottom-bar"></span>
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
}
