import React from "react";
import { RxAccessibility } from "react-icons/rx";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { BiCrown } from "react-icons/bi";
import { GiPencilRuler } from "react-icons/gi";
// import {empowerment} from "assets/images/generate/empowerment.png"
import empowerment from "../../../assets/images/generate/empowerment.png"

export default function Ourmission() {
  return (
    <div>
      {" "}
      <section class="section generate pb-0">
        <div class="container">
          <div class="row items-gap">
            <div class="col-12 col-lg-6 col-xl-5">
              <div
                class="generate__content wow fadeInUp"
                data-wow-duration="600ms"
                data-wow-delay="300ms"
              >
                <div class="section__header text-start">
                  <h2 class="h2">
                    {/* Generate content in seconds using */}
                    <span class="grd">Our Vision</span>
                  </h2>
                </div>
                <div>
                  <div class="generate__content-btn generate__content-btn-active">
                    <div class="thumb">
                      <i
                      //   class="tech-pen"
                      >
                        <RxAccessibility />
                      </i>
                    </div>
                    <div class="content">
                      <h5 class="h5">Accessibility</h5>
                      <p>
                        Making medical information easy and available for
                        everyone.
                      </p>
                    </div>
                  </div>
                  <div class="generate__content-btn">
                    <div class="thumb">
                      <i
                      //   class="tech-cart"
                      >
                        <VscWorkspaceTrusted />
                      </i>
                    </div>
                    <div class="content">
                      <h5 class="h5">Reliability</h5>
                      <p>
                        Offering accurate, trustworthy, and current healthcare
                        insights.
                      </p>
                    </div>
                  </div>
                  <div class="generate__content-btn">
                    <div class="thumb">
                      <i
                      //   class="tech-mike"
                      >
                        {" "}
                        <BiCrown />
                      </i>
                    </div>
                    <div class="content">
                      <h5 class="h5">Empowerment</h5>
                      <p>
                        Empowering individuals with cutting-edge technology for
                        better health outcomes.
                      </p>
                    </div>
                  </div>
                  <div class="generate__content-btn">
                    <div class="thumb">
                      <i
                      // class="tech-globe"
                      >
                        <GiPencilRuler />
                      </i>
                    </div>
                    <div class="content">
                      <h5 class="h5">Innovation</h5>
                      <p>
                        Leveraging advanced AI to offer smart, intuitive medical
                        solutions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-7">
              <div
                class="generate__thumb wow fadeInUp"
                data-wow-duration="600ms"
                data-wow-delay="600ms"
              >
                <div>
                  <div class="tab-content active">
                    <img
                      src="/assets/images/generate/accessability.png"
                      alt="image"
                    />
                  </div>
                  <div class="tab-content">
                    <img
                      src="/assets/images/generate/relaibility.png"
                      alt="image"
                    />
                  </div>
                  <div class="tab-content">
                  <img
                      src={empowerment}
                      alt="image"
                    />
                    {/* <img
                      src="/assets/images/generate/empowerment.png"
                      alt="image"
                    /> */}
                  </div>
                  <div class="tab-content">
                    <img
                      src="/assets/images/generate/innovation.png"
                      alt="image"
                    />
                  </div>
                </div>
                <div class="anime">
                  <img src="/assets/images/anime-four.png" alt="Image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
