// import React, { useState, useEffect } from "react";
// import Sidebar from "./Sidebar";
// import Chatbot from "./Chatbot";
// import Upgrade from "./Upgrade";
// import Plandetails from "./Plandetails";
// import { Modal, Box, Button } from "@mui/material";
// import Setting from "./Setting";
// import { Outlet, useNavigate } from "react-router-dom";
// import axios from "axios";

// export default function Dashboardlayout() {
//   const [activeComponent, setActiveComponent] = useState("chatbot");
//   const [openModal, setOpenModal] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const checkToken = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_API_URL}/user/api/check-token/`,
//           { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
//         );
        

//         console.log(response.data ,"token"); 

//         if (response.status === 200) {
//         }
//       } catch (error) {
//         if (
//           error.response &&
//           error.response.status === 401 &&
//           error.response.data?.messages?.[0]?.message ===
//             "Token is invalid or expired"
//         ) {
//           navigate("/"); // Redirect to /signin
//         }
//       }
//     };

//     checkToken();

//     // Open modal if popup setting is set in local storage
//     const displayPopup = window.localStorage.getItem("display_popup");
//     if (displayPopup === "true") {
//       setOpenModal(true);
//     }
//   }, [navigate]);


//   const handleUpgradeClick = () => {
//     setActiveComponent("upgrade");
//   };
//   const handleHistoryClick = () => {
//     setActiveComponent("history");
//   };
//   const handlePlanClick = () => {
//     setActiveComponent("plan");
//   };
//   const handleSettingClick = () => {
//     setActiveComponent("setting");
//   };
//   const handleCloseModal = (openUpgrade = false) => {
//     setOpenModal(false);
//     window.localStorage.removeItem("display_popup");
//     if (openUpgrade) {
//       setActiveComponent("upgrade");
//     }
//   };
//   return (
//     <div style={{ display: "flex", width: "100%" }}>
//       <Sidebar
//         onUpgradeClick={handleUpgradeClick}
//         onHistoryClick={handleHistoryClick}

//         onPlanClick={handlePlanClick}
//         onSettingClick={handleSettingClick}
//       />
//       <div
//         style={{
//           flexGrow: 1,
//           // display: "flex",
//           // justifyContent: "center",
//           // alignItems: "center",
//           paddingLeft: "30px", // Adjust padding to prevent overlap with sidebar
//         }}
//       >
//         <Outlet />
//       </div>

//       <Modal
//         open={openModal}
//         onClose={handleCloseModal}
//         aria-labelledby="modal-title"
//         aria-describedby="modal-description"
//       >
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             width: 1200,
//             bgcolor: "background.paper",
//             border: "none",
//             borderRadius: "16px",
//             boxShadow: 24,
//             borderColor: "transparent",
//             p: 4,
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//           }}
//         >
//           <div style={{ flex: 1 }}>
//             <div style={{ marginTop: "20px" }}>
//               <h4 className="fs-2">Upgrade Premium with Medical GPT</h4>

//               <h4 style={{ fontSize: "120%" }}>
//                 Elevate your content creation experience and unlock additional
//                 features:
//               </h4>
//               <ul style={{ marginTop: "5%", marginBottom: "5%" }}>
//                 <li className="list1contact" style={{ fontSize: "17px" }}>
//                   <i className="bi bi-check2-circle"></i> Create High-Quality
//                   Short Videos
//                 </li>
//                 <li className="list1contact" style={{ fontSize: "17px" }}>
//                   <i className="bi bi-check2-circle"></i> Effortlessly Access
//                   Advanced Voiceover and Transcription Tools
//                 </li>
//                 <li className="list1contact" style={{ fontSize: "17px" }}>
//                   <i className="bi bi-check2-circle"></i> Seamlessly Compare and
//                   Analyze Web Pages and PDFs
//                 </li>
//                 <li className="list1contact" style={{ fontSize: "17px" }}>
//                   <i className="bi bi-check2-circle"></i> Utilize AI-Powered
//                   Resume Screening and Document Generation
//                 </li>
//                 <li className="list1contact" style={{ fontSize: "17px" }}>
//                   <i className="bi bi-check2-circle"></i> Export Data for
//                   Enhanced Productivity
//                 </li>
//                 <li
//                   className="list1contact"
//                   style={{ fontSize: "17px", marginBottom: "4%" }}
//                 >
//                   <i className="bi bi-check2-circle"></i> Choose the premium
//                   plan and take your content creation to the next level!
//                 </li>
//               </ul>
//             </div>
//             <div id="modal-description">
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={() => handleCloseModal(false)}
//                 style={{ marginRight: "10px" }}
//               >
//                 <a style={{ color: "white" }}> Free Trial</a>
//               </Button>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={() => handleCloseModal(true)}
//               >
//                 <a style={{ color: "white" }}> Upgrade</a>
//               </Button>
//             </div>
//           </div>
//           <div style={{ flex: 1, textAlign: "center" }}>
//             <img
//               src="/assets/images/popup.png"
//               alt="Success"
//               style={{ maxWidth: "100%", maxHeight: "100%" }}
//             />
//           </div>
//         </Box>
//       </Modal>
//     </div>
//   );
// }








// Dashboard layout



import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Chatbot from "./Chatbot";
import Upgrade from "./Upgrade";
import Plandetails from "./Plandetails";
import { Modal, Box, Button } from "@mui/material";
import Setting from "./Setting";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import { BiDiamond, BiCube } from "react-icons/bi";
import { Link } from "react-router-dom";

export default function Dashboardlayout() {
  const [activeComponent, setActiveComponent] = useState("chatbot");
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/api/check-token/`,
          { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
        );
        

        console.log(response.data ,"token"); 

        if (response.status === 200) {
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data?.messages?.[0]?.message ===
            "Token is invalid or expired"
        ) {
          navigate("/"); // Redirect to /signin
        }
      }
    };

    checkToken();

    // Open modal if popup setting is set in local storage
    const displayPopup = window.localStorage.getItem("display_popup");
    if (displayPopup === "true") {
      setOpenModal(true);
    }
  }, [navigate]);


  const handleUpgradeClick = () => {
    setActiveComponent("upgrade");
  };
  const handleHistoryClick = () => {
    setActiveComponent("history");
  };
  const handlePlanClick = () => {
    setActiveComponent("plan");
  };
  const handleSettingClick = () => {
    setActiveComponent("setting");
  };
  const handleCloseModal = (openUpgrade = false) => {
    setOpenModal(false);
    window.localStorage.removeItem("display_popup");
    if (openUpgrade) {
      setActiveComponent("upgrade");
    }
  };
  return (
    <div style={{ display: "flex", width: "100%" }}>
      <Sidebar
        onUpgradeClick={handleUpgradeClick}
        onHistoryClick={handleHistoryClick}

        onPlanClick={handlePlanClick}
        onSettingClick={handleSettingClick}
      />
      <div
        style={{
          flexGrow: 1,
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          paddingLeft: "30px", // Adjust padding to prevent overlap with sidebar
        }}
      >
        <Outlet />
      </div>

      <Modal
  open={openModal}
  onClose={handleCloseModal}
  aria-labelledby="modal-title"
  aria-describedby="modal-description"
>
  <Box
    sx={{
      position: "absolute",
      top: "10%",
      left: "50%",
      transform: "translate(-50%, 0)",
      width: { xs: "90%", sm: "80%", md: "60%" }, // Responsive width
      maxHeight: "80vh", // Height limit with space from top and bottom
      overflowY: "auto", // Scrollbar for overflow
      bgcolor: "background.paper",
      border: "none", 
      borderRadius: "16px",
      boxShadow: 24,
      p: 4,
    }}
  >
    <div style={{ textAlign: "center", marginBottom: "20px" }}>
      <h2 style={{ fontSize: "2rem", fontWeight: "bold", color: "black" }}>
        Unlock the Full Power of MedicalGPT with Premium Plans!
      </h2>
    </div>
    <h5 style={{ fontWeight: "bold", marginTop: "10px" }}>Premium Features Include:</h5>
    <ul style={{ marginTop: "20px", marginBottom: "20px", listStyleType: "none", padding: 0 }}>
      {[
        { text: "Unlimited doctor, pharmacy, and lab recommendations", icon: "bi bi-heart" },
        { text: "4500 medical query credits per month for detailed insights", icon: "bi bi-credit-card" },
        { text: "Export answers and data in professional PDF format", icon: "bi bi-file-earmark-pdf" },
        { text: "Comprehensive symptom analysis ", icon: "bi bi-graph-up" },
        { text: "Up to 10-user access for family ", icon: "bi bi-people" },
      ].map((feature, index) => (
        <li
          key={index}
          style={{ fontSize: "17px", display: "flex", alignItems: "center", marginBottom: "10px" }}
        >
          <span style={{ color: "green", fontWeight: "bold", marginRight: "10px" }}>✔</span>
          <i className={`${feature.icon}`} style={{ marginRight: "10px", color: "#444" }}></i>
          {feature.text}
        </li>
      ))}
    </ul>
    <h5 style={{ fontWeight: "bold", marginTop: "10px" }}>Plan Options:</h5>
    <ul style={{ marginTop: "20px", listStyleType: "none", padding: 0 }}>
    <li style={{ fontSize: "17px", display: "flex", alignItems: "center", marginBottom: "10px" }}>
        <span style={{ marginRight: "10px", display: "flex", alignItems: "center", color: "#3498db" }}>
          <BiDiamond size={20} />
        </span>
        <strong>Monthly Premium:</strong> $50/month
      </li>
      <li style={{ fontSize: "17px", display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "10px", display: "flex", alignItems: "center", color: "#e74c3c" }}>
          <BiCube  size={20} />
        </span>
        <strong>Yearly Premium:</strong> $540/year (Save 10%)
      </li>
    </ul>
    <div style={{ textAlign: "center", marginTop: "30px" }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleCloseModal(false)}
        sx={{ marginRight: "10px" }}
      >
        Free Trial
      </Button>
      <Link to="/upgrade">
      <Button variant="contained" color="success" onClick={() => handleCloseModal(true)}>
        Upgrade
      </Button>
      </Link>
    </div>
  </Box>
</Modal>


    </div>
  );
}
