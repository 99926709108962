import React, { useState, useEffect } from "react";
import NavBar from "./Navbar";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import Footer from "./Footer";

export default function Enable2fa() {
  const [formDataToSend, setFormDataToSend] = useState(new FormData());
  const navigate = useNavigate();
  const location = useLocation();
  const [is2FAEnabled, setIs2FAEnabled] = useState(null); 
  // useEffect(() => {
  //   const urlParams = new URLSearchParams(location.search);
  //   const api_token = urlParams.get("token");
  //   const enable_or_disable_2fa = urlParams.get("enable_or_disable_2fa");

  //   // Log extracted values for debugging
  //   console.log("Extracted api_token from URL:", api_token);
  //   console.log("Enable or Disable 2FA:", enable_or_disable_2fa);

  //   // Retrieve token from local storage
  //   const token = localStorage.getItem("token");
  //   console.log("Token from local storage:", token); // Log the token for debugging

  //   // Set the form data
  //   const formData = new FormData();
  //   const enable2FAValue = enable_or_disable_2fa === "true" ? "True" : "False";
  //   setIs2FAEnabled(enable_or_disable_2fa === "true");
  //   // Update state
  //   setFormDataToSend({ formData, token, api_token });
  // }, [location.search]);
  
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const { formData, token, api_token } = formDataToSend;

  //   try {
  //     const response = await axios.post(
  //       `http://192.168.10.18:8003/user/api/verify_email_for_2fa?token=${api_token}`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     // console.log(response.data, "enable2fa");
  //     if (response.data.status === 200) {
  //       toast.success(response.data.message);
  //       setTimeout(() => {
  //         navigate("/chat");
  //       }, 1000);
  //     } else if (response.data.status === 400) {
  //       console.log(response.data, "enable2fa");
  //       // Handle errconsole.log(response.data, "enable2fa");or with proper message display
  //       toast.error(response.data.message || "An error occurred.");
  //     } else {
  //       // Handle non-200 responses
  //       const errorMessage = response.data.message || "An error occurred";
  //       toast.error(errorMessage, {
  //         duration: 4000,
  //         style: {
  //           whiteSpace: "pre-wrap",
  //         },
  //       });
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       console.log("Full error response:", error.response);
  //       console.log("Error status:", error.response.status);
  //       console.log("Error data:", error.response.data);
  //       // Handle specific error status codes and log the full error for clarity
  //       console.log("Error response:", error.response);

  //       if (error.response.status === 401) {
  //         toast.error("Unauthorized access: Token might be invalid or expired.");
  //       } else if (error.response.status === 400) {
  //         // Extract the error message directly from the response data
  //         const errorMessage = error.response.data.message || "Bad request: Something went wrong.";
  //         toast.error(errorMessage);
  //       } else {
  //         toast.error(error.response.data.message || "An unexpected error occurred.");
  //       }
  //     } else {
  //       toast.error("Something went wrong. Please try again.");
  //     }
  //   }
  // };


  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const api_token = urlParams.get("token");
    const enable_or_disable_2fa = urlParams.get("enable_or_disable_2fa");
  
    // Log extracted values for debugging
    console.log("Extracted api_token from URL:", api_token);
    console.log("Enable or Disable 2FA:", enable_or_disable_2fa); 
  
    const token = localStorage.getItem("token");
    console.log("Token from local storage:", token); 
  
    const isEnabled = enable_or_disable_2fa && enable_or_disable_2fa.toLowerCase() === "enable";
    setIs2FAEnabled(isEnabled); 
  
    console.log("Is 2FA Enabled (boolean):", isEnabled); 
  
    const formData = new FormData();
    formData.append("enable_or_disable_2fa", isEnabled ? "enable" : "disable"); 
  
    formData.forEach((value, key) => {
      console.log(`FormData key: ${key}, value: ${value}`);
    });
  
    // Update state with formData and tokens
    setFormDataToSend({ formData, token, api_token });
  }, [location.search]);
  
  
  useEffect(() => {
    if (formDataToSend) {
      const { formData, token, api_token } = formDataToSend;

      const handleSubmit = async () => {
            formData.forEach((value, key) => {
              console.log(`FormData key: ${key}, value: ${value}`);
            });
            
    
        try {

          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/user/api/verify_email_for_2fa?token=${api_token}`
            ,
            formData,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.data.status === 200) {
            toast.success(response.data.message);
            setTimeout(() => {
              // navigate("/chat");
            }, 1000);
          } else if (response.data.status === 400) {
            console.log(response.data, "enable2fa");
            toast.error(response.data.message || "An error occurred.");
          } else {
            const errorMessage = response.data.message || "An error occurred";
            toast.error(errorMessage, {
              duration: 4000,
              style: {
                whiteSpace: "pre-wrap",
              },
            });
          }
        } catch (error) {
          if (error.response) {
            console.log("Full error response:", error.response);
            if (error.response.status === 401) {
              toast.error("Unauthorized access: Token might be invalid or expired.");
            } else if (error.response.status === 400) {
              const errorMessage = error.response.data.message || "Bad request: Something went wrong.";
              toast.error(errorMessage);
            } else {
              toast.error(error.response.data.message || "An unexpected error occurred.");
            }
          } else {
            toast.error("Something went wrong. Please try again.");
          }
        }
      };

      handleSubmit(); // Call the API automatically
    }
  }, [formDataToSend, navigate]);
  useEffect(() => {
    // Function to dynamically load a script
    const loadScript = (src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return script;
    };

    // List of scripts to load
    const scripts = [
      "/assets/js/jquery-3.7.0.min.js",
      "/assets/js/bootstrap.bundle.min.js",
      "/assets/js/jquery.nice-select.min.js",
      "/assets/js/jquery.magnific-popup.min.js",
      "/assets/js/slick.min.js",
      "/assets/js/typed.umd.js",
      "/assets/js/wow.min.js",
      "/assets/js/plugins.js",
      "/assets/js/main.js",
    ];

    const scriptElements = scripts.map((src) => loadScript(src));

    return () => {
      scriptElements.forEach((script) => {
        if (script && script.parentNode === document.body) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);
  return (
    <div style={{ backgroundColor: "#f9f9f9", minHeight: "100vh" }}>
    <Toaster
      position="top-right"
      reverseOrder={false}
      toastOptions={{
        style: {
          marginTop: "50px",
        },
      }}
    />
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div
       style={{
        backgroundColor: "#fff",
        padding: "20px 30px",
        borderRadius: "10px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1), 0 8px 20px rgba(0, 0, 0, 0.15), 0 12px 30px rgba(0, 0, 0, 0.2)",
        border: "1px solid #e0e0e0",
        maxWidth: "500px",
        textAlign: "center",
      }}
      
      >
        <h2 style={{ fontWeight: "bold", color: "#333" }}>
          {is2FAEnabled
            ? "2FA Enabled Successfully"
            : "2FA Disabled Successfully"}
        </h2>
        {/* <button
          className="btn btn--secondary mt-4"
          style={{
            backgroundColor: "#4569e7",
            borderRadius: "10px",
            marginTop: "20px",
            color: "#fff",
            padding: "10px 20px",
            border: "none",
            cursor: "pointer",
            transition: "all 0.3s ease-in-out",
          }}
          onClick={() => navigate("/")}
        >
          Go to Dashboard
          <i
            className="fa-solid fa-arrow-right-to-bracket"
            style={{ marginLeft: "8px" }}
          ></i>
        </button> */}
      </div>
    </div>
  </div>
  );
}




// import React, { useState, useEffect } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import axios from "axios";
// import { Toaster, toast } from "react-hot-toast";

// export default function Enable2fa() {
//   const [formDataToSend, setFormDataToSend] = useState(new FormData());
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [is2FAEnabled, setIs2FAEnabled] = useState(null);

//   useEffect(() => {
//     const urlParams = new URLSearchParams(location.search);
//     const api_token = urlParams.get("token");
//     const enable_or_disable_2fa = urlParams.get("enable_or_disable_2fa");
//     const token = localStorage.getItem("token");
//     const isEnabled =
//       enable_or_disable_2fa && enable_or_disable_2fa.toLowerCase() === "enable";
//     setIs2FAEnabled(isEnabled);

//     const formData = new FormData();
//     formData.append("enable_or_disable_2fa", isEnabled ? "enable" : "disable");

//     setFormDataToSend({ formData, token, api_token });
//   }, [location.search]);

//   useEffect(() => {
//     if (formDataToSend) {
//       const { formData, token, api_token } = formDataToSend;

//       const handleSubmit = async () => {
//         try {
//           const response = await axios.post(
//             `${process.env.REACT_APP_API_URL}/user/api/verify_email_for_2fa?token=${api_token}`,
//             formData,
//             {
//               headers: {
//                 "Content-Type": "application/json",
//                 Authorization: `Bearer ${token}`,
//               },
//             }
//           );

//           if (response.data.status === 200) {
//             toast.success(response.data.message);
//             setTimeout(() => {
//               navigate("/chat");
//             }, 1000);
//           } else if (response.data.status === 400) {
//             toast.error(response.data.message || "An error occurred.");
//           } else {
//             toast.error(
//               response.data.message || "An unexpected error occurred."
//             );
//           }
//         } catch (error) {
//           if (error.response) {
//             if (error.response.status === 401) {
//               toast.error(
//                 "Unauthorized access: Token might be invalid or expired."
//               );
//             } else if (error.response.status === 400) {
//               toast.error(
//                 error.response.data.message || "Bad request: Something went wrong."
//               );
//             } else {
//               toast.error(
//                 error.response.data.message || "An unexpected error occurred."
//               );
//             }
//           } else {
//             toast.error("Something went wrong. Please try again.");
//           }
//         }
//       };

//       handleSubmit();
//     }
//   }, [formDataToSend, navigate]);

//   return (
//     <div style={{ backgroundColor: "#f9f9f9", minHeight: "100vh" }}>
//       <Toaster
//         position="top-right"
//         reverseOrder={false}
//         toastOptions={{
//           style: {
//             marginTop: "50px",
//           },
//         }}
//       />
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "100vh",
//         }}
//       >
//         <div
//           style={{
//             backgroundColor: "#fff",
//             padding: "20px 30px",
//             borderRadius: "10px",
//             boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
//             border: "1px solid #e0e0e0",
//             maxWidth: "500px",
//             textAlign: "center",
//           }}
//         >
//           <h2 style={{ fontWeight: "bold", color: "#333" }}>
//             {is2FAEnabled
//               ? "2FA Enabled Successfully"
//               : "2FA Disabled Successfully"}
//           </h2>
//           <button
//             className="btn btn--secondary mt-4"
//             style={{
//               backgroundColor: "#4569e7",
//               borderRadius: "10px",
//               marginTop: "20px",
//               color: "#fff",
//               padding: "10px 20px",
//               border: "none",
//               cursor: "pointer",
//               transition: "all 0.3s ease-in-out",
//             }}
//             onClick={() => navigate("/")}
//           >
//             Go to Dashboard
//             <i
//               className="fa-solid fa-arrow-right-to-bracket"
//               style={{ marginLeft: "8px" }}
//             ></i>
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

