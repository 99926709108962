import React, { useState } from "react";

export default function Fqa() {
  const [openAccordion, setOpenAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  return (
    <div className="">
      <section className="section faq-two faq-light pb-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <div
                className="section__header text-center wow fadeInUp"
                data-wow-duration="600ms"
                data-wow-delay="300ms"
              >
                <h2 className="h2">Frequently Asked Questions</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="accordion" id="accordion">
                <div className="row items-gap">
                  <div className="col-12 col-lg-6">
                    <div className="faq__two-left">
                      <div
                        className="accordion-item wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="600ms"
                      >
                        <h5 className="accordion-header" id="headingOne">
                          <button
                            className={`accordion-button ${
                              openAccordion === 1 ? "open" : ""
                            }`}
                            type="button"
                            onClick={() => toggleAccordion(1)}
                            style={{
                              color: openAccordion === 1 ? "blue" : "black",
                            }}
                          >
                            How do I start using MedicalGPT?
                          </button>
                        </h5>
                        {openAccordion === 1 && (
                          <div className="accordion-collapse">
                            <div className="accordion-body">
                              <p style={{ color: "black" }}>
                                Simply visit our website and either enter your
                                medical queries directly or upload relevant
                                documents for analysis. You’ll receive detailed
                                responses and recommendations based on your
                                input.
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="accordion-item wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="900ms"
                      >
                        <h5 className="accordion-header" id="headingTwo">
                          <button
                            className={`accordion-button ${
                              openAccordion === 2 ? "open" : ""
                            }`}
                            type="button"
                            onClick={() => toggleAccordion(2)}
                            style={{
                              color: openAccordion === 2 ? "blue" : "black",
                            }}
                          >
                            What services does this website offer?
                          </button>
                        </h5>
                        {openAccordion === 2 && (
                          <div className="accordion-collapse">
                            <div className="accordion-body">
                              <p className="p">
                                MedicalGPT offers advanced medical query
                                processing, personalized recommendations for
                                tests and treatments, and curated referrals to
                                doctors, pharmacies, and labs. Available 24/7,
                                the platform provides accurate medical insights
                                and expert guidance to support informed
                                healthcare decisions.
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="faq__two-right">
                      <div
                        className="accordion-item content__space wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="1500ms"
                      >
                        <h5 className="accordion-header" id="headingFour">
                          <button
                            className={`accordion-button ${
                              openAccordion === 4 ? "open" : ""
                            }`}
                            type="button"
                            onClick={() => toggleAccordion(4)}
                            style={{
                              color: openAccordion === 4 ? "blue" : "black",
                            }}
                          >
                            Are my personal details safe on this website?
                          </button>
                        </h5>
                        {openAccordion === 4 && (
                          <div className="accordion-collapse">
                            <div className="accordion-body">
                              <p className="p">
                                Yes, MedicalGPT is committed to protecting your
                                privacy. We collect personal and health-related
                                information to provide accurate medical
                                insights, and use encryption and
                                industry-standard security measures to safeguard
                                your data. Personal information is retained only
                                as long as necessary and is handled with strict
                                confidentiality. For any privacy concerns, you
                                can contact us at [info@datalyticsai.com].
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="accordion-item content__space wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="1500ms"
                      >
                        <h5 className="accordion-header" id="headingFive">
                          <button
                            className={`accordion-button ${
                              openAccordion === 5 ? "open" : ""
                            }`}
                            type="button"
                            onClick={() => toggleAccordion(5)}
                            style={{
                              color: openAccordion === 5 ? "blue" : "black",
                            }}
                          >
                            How does MedicalGPT utilize AI technology?
                          </button>
                        </h5>
                        {openAccordion === 5 && (
                          <div className="accordion-collapse">
                            <div className="accordion-body">
                              <p className="p">
                                MedicalGPT employs advanced AI algorithms to
                                analyze and interpret complex medical data,
                                ensuring that users receive accurate and
                                relevant medical information.
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section__content-cta">
            <hr id="horizon-break" />
          </div>
        </div>
      </section>
    </div>
  );
}
