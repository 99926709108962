import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleScrollClick = (to) => {
    if (location.pathname !== "/") {
      navigate("/", { state: { scrollTo: to } });
    } else {
      const element = document.getElementById(to);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  return (
    <div class=" home-two-light mt-12">
      {" "}
      <footer
        class="footer-two section bg-img"
      >
        <div class="container">
          <div class="row items-gap-two">
            <div class="col-12 col-sm-6 col-xl-4 col-xxl-5">
              <div
                class="footer-two__single bt wow fadeInUp"
                data-wow-duration="600ms"
              >
                <a href="/" className="logo" style={{width:"40%"}}>
                  <img src="/assets/images/logo.png" alt="Image" />
                </a>
                <p>
                  MedicalGPT for Accurate and Reliable Medical Information,
                  Powered by Advanced AI Technology.
                </p>
                <div class="social">
                  <a href="index.html" aria-label="social media">
                    <i class="fa-brands fa-facebook-f"></i>
                  </a>
                  <a href="index.html" aria-label="social media">
                    <i class="fa-brands fa-twitter"></i>
                  </a>
                  <a href="index.html" aria-label="social media">
                    <i class="fa-brands fa-linkedin-in"></i>
                  </a>
                  <a href="index.html" aria-label="social media">
                    <i class="fa-brands fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-xl-2 col-xxl-2">
              <div
                class="footer-two__single wow fadeInUp"
                data-wow-duration="600ms"
                data-wow-delay="200ms"
              >
                <h5 class="h5">About MedicalGPT</h5>
                <ul>
                  <li>
                    <a href="/aboutus">About</a>
                  </li>
                 
                  <li>
                    <a href="/signin">Sign in</a>
                  </li>
                  <li>
                    <a href="/signup">Register</a>
                  </li>
                  <li>
                    <a href="/contactus">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-xl-2 col-xxl-2">
              <div
                class="footer-two__single wow fadeInUp"
                data-wow-duration="600ms"
                data-wow-delay="400ms"
              >
                <h5 class="h5">Explore Us</h5>
                <ul>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleScrollClick("howitwork");
                      }}
                    >
                      How it works
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleScrollClick("keyfeatuers");
                      }}
                    >
                      Key Features
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleScrollClick("ourmission");
                      }}
                    >
                      Our Vision
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-xl-4 col-xxl-3">
              <div
                class="footer-two__single wow fadeInUp"
                data-wow-duration="600ms"
                data-wow-delay="200ms"
              >
                <h5 class="h5">Support</h5>
                <ul>
                  <li>
                    <a href="/privacypolicy">Privacy & Policy</a>
                  </li>
                  {/* <li>
                    <a href="blog">Blog</a>
                  </li> */}
                  {/* <li>
                    <a href="/signin">Sign in</a>
                  </li>
                  <li>
                    <a href="/signup">Register</a>
                  </li>
                  <li>
                    <a href="/contactus">Contact</a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="copyright text-center">
                  <p>
                    Copyright &copy;
                    <span id="copyYear"></span>
                    <a style={{ marginLeft: "5px" }}>MedicalGPT</a>. Design By
                    <a
                      style={{ marginLeft: "5px" }}
                      href="https://www.datalyticsai.com/"
                    >
                      DatalyticsAI
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
