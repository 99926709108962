// import React, { useState } from "react";

// export default function Pharmacy() {
//   const [address, setAddress] = useState(""); // State to hold the entered address

//   const dummyData = [
//     {
//       name: "CVS Pharmacy",
//       specialty: "Prescription Medications",
//       location: "New York, NY",
//       availability: "Monday to Sunday, 9 AM - 9 PM",
//       contact: "+1 555-123-9999",
//       store_photo_url: "https://img.freepik.com/free-photo/modern-pharmacy-store_23-2148488261.jpg", // CVS pharmacy image
//       profile_url: "https://example.com/profiles/cvs_pharmacy_ny",
//     },
//     {
//       name: "Walgreens",
//       specialty: "Over-the-Counter Medications",
//       location: "Los Angeles, CA",
//       availability: "Monday to Sunday, 8 AM - 10 PM",
//       contact: "+1 555-987-1234",
//       store_photo_url: "https://img.freepik.com/free-photo/young-pharmacist-assisting-customers_23-2148488270.jpg", // Walgreens pharmacy image
//       profile_url: "https://example.com/profiles/walgreens_la",
//     },
//     {
//       name: "Rite Aid",
//       specialty: "Health and Wellness Products",
//       location: "Chicago, IL",
//       availability: "Monday to Saturday, 9 AM - 8 PM",
//       contact: "+1 555-222-4444",
//       store_photo_url: "https://img.freepik.com/free-photo/pharmacy-store-with-medicine-products_23-2148488263.jpg", // Rite Aid pharmacy image
//       profile_url: "https://example.com/profiles/riteaid_chicago",
//     },
//     {
//       name: "Costco Pharmacy",
//       specialty: "Generic Medications",
//       location: "Houston, TX",
//       availability: "Monday to Friday, 10 AM - 7 PM",
//       contact: "+1 555-444-6666",
//       store_photo_url: "https://img.freepik.com/free-photo/pharmacist-giving-medicine-to-customer_23-2148488271.jpg", // Costco pharmacy image
//       profile_url: "https://example.com/profiles/costco_pharmacy_houston",
//     },
//     {
//       name: "Walmart Pharmacy",
//       specialty: "Medical Supplies",
//       location: "San Francisco, CA",
//       availability: "Monday to Sunday, 9 AM - 9 PM",
//       contact: "+1 555-555-7777",
//       store_photo_url: "https://img.freepik.com/free-photo/pharmacist-helping-patient_23-2148488272.jpg", // Walmart pharmacy image
//       profile_url: "https://example.com/profiles/walmart_pharmacy_sf",
//     },
//   ];

//   return (
//     <div className="container mx-auto px-4">
//       {/* Heading */}
//       <h1 className="text-3xl font-bold text-center my-8">Find Your Pharmacy</h1>

//      {/* Filter Section */}
//      <div className="flex justify-center items-center">
//         <div className="flex flex-col space-y-4 mb-8 w-1/3">
     
//           <div className="bg-gray-100 rounded-lg p-2 shadow-sm">
//             <input
//               type="text"
//               placeholder="Enter an Address"
//               className="w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
//             />
//           </div>

//           {/* Use Current Location option */}
//           <div className="bg-white rounded-lg p-2 shadow-sm flex items-center space-x-2 border border-gray-300">
//             <i className="fas fa-map-marker-alt text-gray-500"></i>
//             <div>
//               <p className="text-lg font-semibold">Use Current Location</p>
//               <p className="text-sm text-gray-600">345/2, Mawaththegama Road, Kurunegala.</p>
//             </div>
//           </div>
//         </div>
//       </div>


//       {/* Pharmacy Cards */}
//       <div className="container mx-auto" style={{ width: "90%" }}>
//       <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">   
//                  {dummyData.map((pharmacy, index) => (
//           <div key={index} 
//           className="bg-white shadow-md rounded-lg overflow-hidden transform transition duration-300 hover:scale-105"
// >
//             <div className="w-full h-44 overflow-hidden">
//               <img
//                 src={pharmacy.store_photo_url}
//                 alt={`${pharmacy.name} Store`}
//                 className="w-full h-full object-cover"
//               />
//             </div>
//             <div className="p-4">
//               {/* Pharmacy's Name */}
//               <h2 className="text-xl font-bold">{pharmacy.name}</h2>
//               {/* Pharmacy's Info */}
//               <p className="text-gray-700 mb-1">
//                 <strong>Specialty:</strong> {pharmacy.specialty}
//               </p>
//               <p className="text-gray-700 mb-1">
//                 <strong>Location:</strong> {pharmacy.location}
//               </p>
//               <p className="text-gray-700 mb-1">
//                 <strong>Availability:</strong> {pharmacy.availability}
//               </p>
//               <p className="text-gray-700 mb-1">
//                 <strong>Contact:</strong> {pharmacy.contact}
//               </p>
//               <a href={pharmacy.profile_url} className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
//                 View Profile
//               </a>
//             </div>
//           </div>
//         ))}
//       </div>
//       </div>
//     </div>
//   );
// }





import React, { useState, useEffect } from "react";
import axios from "axios";
import ChatHeader from "./Chatheader";

export default function Pharmacy() {
  const [address, setAddress] = useState(""); 
        



  
  const [pharmacies, setPharmacies] = useState([]); 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPharmacies = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/api/get_pharmacy_near_me`
          ,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setPharmacies(response.data.result); // Set the fetched pharmacies data
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching pharmacies:", error);
        setLoading(false); // Stop loading even if there's an error
      }
    };
    fetchPharmacies();
  }, []);

  if (loading) {
    return <div>Loading pharmacies...</div>; // Show a loading state while fetching
  }

  return (
    <div className="container mx-auto px-4">
      <div className="container mx-auto mt-5" style={{ width: "90%" }}>  <ChatHeader/></div>
      {/* Heading */}
      <h1 className="text-3xl font-bold text-center my-8">Find Your Pharmacy</h1>

      {/* Filter Section */}
      <div className="flex justify-center items-center">
        <div className="flex flex-col space-y-4 mb-8 w-1/3">
          <div className="bg-gray-100 rounded-lg p-2 shadow-sm">
            <input
              type="text"
              placeholder="Enter an Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)} // Update address state
              className="w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>

          {/* Use Current Location option */}
          <div className="bg-white rounded-lg p-2 shadow-sm flex items-center space-x-2 border border-gray-300">
            <i className="fas fa-map-marker-alt text-gray-500"></i>
            <div>
              <p className="text-lg font-semibold">Use Current Location</p>
              <p className="text-sm text-gray-600">345/2, Mawaththegama Road, Kurunegala.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Pharmacy Cards */}
      <div className="container mx-auto" style={{ width: "90%" }}>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {pharmacies.length > 0 ? (
            pharmacies.map((pharmacy, index) => (
              <div
                key={index}
                className="bg-white shadow-md rounded-lg overflow-hidden transform transition duration-300 hover:scale-105"
              >
                <div className="w-full h-44 overflow-hidden">
                  <img
                    src={pharmacy.store_photo_url}
                    alt={`${pharmacy.name} Store`}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-4">
                  {/* Pharmacy's Name */}
                  <h2 className="text-xl font-bold">{pharmacy.name}</h2>
                  {/* Pharmacy's Info */}
                  <p className="text-gray-700 mb-1">
                    <strong>Specialty:</strong> {pharmacy.specialty}
                  </p>
                  <p className="text-gray-700 mb-1">
                    <strong>Location:</strong> {pharmacy.location}
                  </p>
                  <p className="text-gray-700 mb-1">
                    <strong>Availability:</strong> {pharmacy.availability}
                  </p>
                  <p className="text-gray-700 mb-1">
                    <strong>Contact:</strong> {pharmacy.contact}
                  </p>
                  <a
                    href={pharmacy.profile_url}
                    className="text-blue-500 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Profile
                  </a>
                </div>
              </div>
            ))
          ) : (
            <div>No pharmacies available</div>
          )}
        </div>
      </div>
    </div>
  );
}
