import React, { useState } from "react";
import logo from "../images/new-logo.png";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Link } from "react-router-dom";

function Navbar() {
  const [showMenu, setShowMenu] = useState(false);
  const { t } = useTranslation();
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <nav className="bg-white dark:bg-gray-900 fixed w-screen z-20 top-0 left-0 pt-1.5 pb-1.5 border-gray-200 dark:border-gray-600">
      <div className=" flex flex-wrap items-center w-screen justify-center mx-auto xl:p-6 p-4 inner-parent">
        <div className="relative w-[100%] flex flex-wrap items-center justify-center inner-child">
          <a href="#" className="flex items-center logo-img xl:p-4">
          <Link to="/" className="flex items-center logo-img xl:p-4">
            {!showMenu && (
              <img
                src={logo}
                className="w-48 mt-12 mr-3 fixed pt-0.2"
                alt="SaaS Weekly Logo"
              />
            )}
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"></span>
          </Link>
          </a>

          <div className="flex md:order-2 xl:p-4">
            <button
              className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="menu"
              aria-expanded={showMenu}
              onClick={toggleMenu}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div
            className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${
              showMenu ? "" : "hidden"
            }`}
            id="menu"
          >
            <ul className="flex flex-col  p-4 md:p-0 mt-4 gap-6 font-normal border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
         
              <li>
                <Link
                  to="/"
                  className="block py-2 pl-3 pr-4 text-red-500 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  {t("navbar.home")}
                </Link>
              </li>
             
              <li>
                <a
                  href="https://datalyticsai.com/about-us"
                  target="_blank"
                  class="block py-2 pl-3 pr-4 text-red-500 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  {" "}
                  {t("navbar.about")}
                
                </a>
              </li>

              <li>
                <a
                  href="https://datalyticsai.com/contact"
                  target="_blank"
                  class="block py-2 pl-3 pr-4 text-red-500 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  {" "}
                  {t("navbar.contact")}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
