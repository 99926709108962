import React, { useEffect } from "react";
import Main from "../../Main";
import logo from "../../../images/logo.png";
import Navbar from "./Navbar";
import Faq from "./Faq";
import Mainsection from "./Mainsection";
import Howitwork from "./Howitwork";
import Ourmission from "./Ourmission";
import Keyfeatuers from "./Keyfeatuers";
import { Element } from "react-scroll";
import { useNavigate, useLocation } from "react-router-dom";
import Testimonial from "./Testimonial";
import Footer from "./Footer";
export default function MainLandingpage() {
  const navigate = useNavigate();
  const location = useLocation();
  const handleGetStartedClick = () => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    } else {
      navigate("/signin");
    }
  };
  const handleScrollClick = (to) => {
    if (location.pathname !== "/") {
      navigate("/", { state: { scrollTo: to } });
    } else {
      const element = document.getElementById(to);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  useEffect(() => {
    // Function to dynamically load a script
    const loadScript = (src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return script;
    };

    const scripts = [
      "/assets/js/jquery-3.7.0.min.js",
      "/assets/js/bootstrap.bundle.min.js",

      "/assets/js/jquery.nice-select.min.js",
      "/assets/js/jquery.magnific-popup.min.js",
      "/assets/js/slick.min.js",
      "/assets/js/typed.umd.js",
      "/assets/js/wow.min.js",
      "/assets/js/plugins.js",
      "/assets/js/main.js",
    ];

    // Load all scripts and keep references to them
    const scriptElements = scripts.map((src) => loadScript(src));

    // Cleanup function to remove scripts when component unmounts
    return () => {
      scriptElements.forEach((script) => {
        if (script && script.parentNode === document.body) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);

  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const hide = () => {
    const windowWidth = window.innerWidth;
    return windowWidth < 768 ? { display: "none" } : { display: "flex" };
  };

  return (
    <div>
      {" "}
      {/* <!-- preloader --> */}
      <div id="preloader">
        <div id="loader"></div>
      </div>
      {/* <!-- ==== page wrapper start ==== --> */}
      <div class="my-app home-seven">
        {/* <!-- ==== header start ==== --> */}
        <Navbar />
        {/* <!-- ==== / header end ==== -->
        
            <!-- ==== banner start ==== --> */}
        <Mainsection />

        {/* <!-- ==== /banner  end==== -->
            <!-- ==== language start ==== --> */}
        <div class="language pb-0">
          <div class="language__slider">
            <div class="language__slider-single">
              <a>Smart Diagnostic</a>
            </div>
            <div class="language__slider-single">
              <a>MediGen</a>
            </div>
            <div class="language__slider-single">
              <a>Medical Research</a>
            </div>
            <div class="language__slider-single">
              <a>AI-assisted Care</a>
            </div>
            <div class="language__slider-single">
              <a>Health Foresight</a>
            </div>
            <div class="language__slider-single">
              <a>Virtual Doctor</a>
            </div>
            <div class="language__slider-single">
              <a>AI Consultation</a>
            </div>
            <div class="language__slider-single">
              <a>Digital Medicine</a>
            </div>
            <div class="language__slider-single">
              <a>Biotech Innovations</a>
            </div>
            <div class="language__slider-single">
              <a>Expert Solutions</a>
            </div>
            <div class="language__slider-single">
              <a>BioBot</a>
            </div>
            <div class="language__slider-single">
              <a>BioLogic</a>
            </div>
            <div class="language__slider-single">
              <a>Efficient Queries</a>
            </div>
            <div class="language__slider-single">
              <a>Recommendations</a>
            </div>
            {/* <div class="language__slider-single">
                <a href="use-case.html">Games</a>
              </div> */}
          </div>
          <div dir="rtl">
            <div class="language__slider-rtl">
              {/* <div class="language__slider-single">
                  <a href="use-case.html">Food and Drink</a>
                </div> */}
              <div class="language__slider-single">
                <a>Smart Diagnostic</a>
              </div>
              <div class="language__slider-single">
                <a>MediGen</a>
              </div>
              <div class="language__slider-single">
                <a>Medical Research</a>
              </div>
              <div class="language__slider-single">
                <a> AI-assisted Care</a>
              </div>
              <div class="language__slider-single">
                <a>Health Foresight</a>
              </div>
              <div class="language__slider-single">
                <a>Virtual Doctor</a>
              </div>
              <div class="language__slider-single">
                <a>AI Consultation</a>
              </div>
              <div class="language__slider-single">
                <a>Digital Medicine</a>
              </div>
              <div class="language__slider-single">
                <a>Biotech Innovations</a>
              </div>
              <div class="language__slider-single">
                <a>Expert Solutions</a>
              </div>
              <div class="language__slider-single">
                <a>BioBot</a>
              </div>
              <div class="language__slider-single">
                <a>BioLogic</a>
              </div>
              <div class="language__slider-single">
                <a> Efficient Queries</a>
              </div>
              <div class="language__slider-single">
                <a> Recommendations</a>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- ==== / language end ==== -->
         dddddd
            <!-- ==== collection start ==== --> */}
        {/* how it work satrt */}
        {/* <Element name="howitwork">
          {" "}
          <Howitwork />
        </Element> */}
        <section id="howitwork">
          {" "}
          <Howitwork />
        </section>
        {/* <!-- ==== / publish end ==== --> */}
        <hr id="horizon-break" />
        <div class="section tour tour-two pb-0">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div
                  class="tour__content wow fadeInUp"
                  data-wow-duration="600ms"
                  data-wow-delay="300ms"
                  data-before-image="assets/images/newframe.png"
                >
                  <video muted loop autoplay controls>
                    <source
                      src="assets/images/video/video.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
          </div>
          <div class="anime">
            <img src="assets/images/anime-five.png" alt="Image" />
          </div>
        </div>
        {/* our missin start */}
        <section id="ourmission">
          {" "}
          <Ourmission />
        </section>
        <section id="keyfeatuers">
          {" "}
          <Keyfeatuers />
        </section>
        {/* <Element name="ourmission">
          {" "}
          <Ourmission />
        </Element>
        <Element name="keyfeatuers">
          <Keyfeatuers />
        </Element> */}
        {/* <!-- ==== / broadcast end ==== -->
   
          <Faq />
          {/* <!-- ==== / faq end ==== --> */}
        <Testimonial />
        <section id="faq">
          {" "}
          <Faq />
        </section>

        <Footer />

        <div
          dangerouslySetInnerHTML={{
            __html: `
            <style>
              @media (max-width: 550.98px) {
                .footer-two__single ul {
                  padding-left: 0;
                }
                .footer-two__single ul li {
                  display: inline;
                  margin: 0 10px;
                }
              }
            </style>
          `,
          }}
        />

        {/* <!-- ==== / footer end ==== --> */}
      </div>
      {/* <!-- ==== / page wrapper end ==== -->
        <!-- scroll to top --> */}
      <div class="progress-wrap">
        <svg
          class="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
      {/* <!-- ==== js dependencies start ==== -->
        <!-- jquery --> */}
      <script src="/assets/js/jquery-3.7.0.min.js"></script>
      {/* <!-- bootstrap five js --> */}
      <script src="/assets/js/bootstrap.bundle.min.js"></script>
      {/* <!-- nice select js --> */}
      <script src="assets/js/jquery.nice-select.min.js"></script>
      {/* <!-- magnific popup js --> */}
      <script src="/assets/js/jquery.magnific-popup.min.js"></script>
      {/* <!-- slick js --> */}
      <script src="/assets/js/slick.min.js"></script>
      {/* <!-- typed js --> */}
      <script src="/assets/js/typed.umd.js"></script>
      {/* <!-- wow js --> */}
      <script src="/assets/js/wow.min.js"></script>
      {/* <!-- ==== / js dependencies end ==== --> */}
      {/* <!-- plugins js --> */}
      <script src="/assets/js/plugins.js"></script>
      {/* <!-- main js --> */}
      <script src="/assets/js/main.js"></script>
    </div>
  );
}
