import React, { useState, useEffect } from "react";
import NavBar from "./Navbar";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import Footer from "./Footer";

export default function Verify() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get("token");

    if (token) {
      const verifyEmail = async () => {
        try {

          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/api/user_email_verification?token=${token}`

          );
          console.log(response, "verify");

          if (response.data.status === 200) {
            toast.success(response.data.message);
            setTimeout(() => {
            }, 1000);
          } else {
            const errorMessage = response.data.message || "An error occurred";
            toast.error(errorMessage, {
              duration: 4000,
              style: {
                whiteSpace: "pre-wrap",
              },
            });
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 401) {
              toast.error(
                "Unauthorized access: Token might be invalid or expired."
              );
            } else if (error.response.status === 400) {
              toast.error("Bad request: " + error.response.data.message);
            } else {
              toast.error(error.response.data.message || "An error occurred.");
            }
          } else {
            toast.error("Something went wrong. Please try again.");
          }
        }
      };

      verifyEmail();
    }
  }, [location.search, navigate]);
  useEffect(() => {
    // Function to dynamically load a script
    const loadScript = (src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return script;
    };

    // List of scripts to load
    const scripts = [
      "assets/js/jquery-3.7.0.min.js",
      "assets/js/bootstrap.bundle.min.js",
      "assets/js/jquery.nice-select.min.js",
      "assets/js/jquery.magnific-popup.min.js",
      "assets/js/slick.min.js",
      "assets/js/typed.umd.js",
      "assets/js/wow.min.js",
      "assets/js/plugins.js",
      "assets/js/main.js",
    ];

    // Load all scripts and keep references to them
    const scriptElements = scripts.map((src) => loadScript(src));

    // Cleanup function to remove scripts when component unmounts
    return () => {
      scriptElements.forEach((script) => {
        if (script && script.parentNode === document.body) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);
  return (
    <div>
        <div id="preloader">
        <div id="loader"></div>
      </div>
    <div class="my-app home-seven">
      <NavBar />
      <section
          class="section cmn-banner"
          data-background="assets/images/banner/banner-bg.png"
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div
                  class="cmn-banner__content wow fadeInUp"
                  data-wow-duration="600ms"
                  data-wow-delay="300ms"
                >
                  <h3 class="h3"> Verification</h3>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb justify-content-center">
                      <li class="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                       Verify
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="anime">
            <img src="assets/images/anime-one.png" alt="Image" class="one" />
            <img src="assets/images/anime-two.png" alt="Image" class="two" />
          </div>
        </section>
      <section className="section pb-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
          
              <div className="text-center">
                <div className="section__header">
                  <p className="h5" style={{ color: "#111827" }}>
                          
                    Your Account is Created Successfully Feel free to Use
                    MedicalGPT and Explore Exciting features
                  </p>
                </div>
                <div className="flex justify-center items-center ">
                      <img
                        src="/assets/images/verify.png"
                        alt="Image"
                        className="wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="900ms"
                      />
                    </div>
                <button
                  className="btn btn--secondary mt-4"
                  style={{ backgroundColor: "#4569e7", borderRadius: "10px" }}
                  onClick={() => navigate("/signin")}
                >
                  Sig In
                  <i
                    className="fa-solid fa-arrow-right-to-bracket"
                    style={{ marginLeft: "8px" }}
                  ></i>
                </button>
              </div>
              
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Toaster />
    </div>
    </div>
  );
}
