

import React, { useState, useEffect, useRef } from "react";
import Answer from "./Answer";
import InputBox from "./InputBox";
import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "axios";
import { SlReload } from "react-icons/sl";
import { useTranslation } from "react-i18next";
import { RxAllSides } from "react-icons/rx";
import { SiAnswer } from "react-icons/si";
import one from "./Landingpage/landing/doctr.jpg";
import two from "./Landingpage/landing/slide1.jpg";
import three from "./Landingpage/landing/slide2.jpg";
import Firstinbox from "./Firstinbox";
import { BiMessageAdd } from "react-icons/bi";
export default function Result({ onReset, onSubmitQuestion }) {
  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const [received, setReceived] = useState(false);
  const [history, setHistory] = useState([]);
  const [hasReceivedResponse, setHasReceivedResponse] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const [hoveredSource, setHoveredSource] = useState(null);
  const [showInitialInputBox, setShowInitialInputBox] = useState(true);
  const [fileAttachement, setFileAttacehment] = useState([]);
  const { t } = useTranslation();
  const containerRef = useRef(null);

  const handleQuestionSubmit = (question) => {
    setQuery(question);
    onSubmitQuestion(question);
    setShowInitialInputBox(false);
  };

  useEffect(() => {
    if (query) {
      setLoading(true);
      setAnswer("");
      setReceived(false);
      const fetchData = async () => {
        try {
          const { data: ip } = await axios.get(
            "https://api.ipify.org?format=json"
          );

          // Retrieve the token from local storage
          const token = localStorage.getItem("token");

          // Define the request body
          const requestBody = {
            question: query,
          };

          const request = await fetch("http://15.156.159.236:8000/get-llm", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Include the token in the Authorization header
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestBody),
          });

          if (request.ok) {
            const response = await request.json();
            setData(response.data);
            setAnswer(response.answer);
            setReceived(true);
            setHasReceivedResponse(true);
            setHistory((prevHistory) => [
              ...prevHistory,
              { question: query, answer: response.answer, data: response.data },
            ]);
          } else {
            setLoading(false);
            console.error("Error fetching data:", request.statusText);
            throw new Error(request.statusText);
          }
        } catch (error) {
          setLoading(false);
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [query]);

  useEffect(() => {
    if (loading || received) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [loading, received]);

  const handleReset = () => {
    setQuery("");
    setData([]);
    setAnswer("");
    setLoading(false);
    setReceived(false);
    setShowInitialInputBox(true);
    onReset();
  };

  const handleSuggestedQuestionClick = (suggestedQuestion) => {
    handleQuestionSubmit(suggestedQuestion);
  };

  const handleViewMore = () => {
    setViewMore(!viewMore);
  };

  const handleMouseEnter = (index) => {
    setHoveredSource(index);
  };

  const handleMouseLeave = () => {
    setHoveredSource(null);
  };

  return (
    <div className="lg:w-[950px] md:w-[600px] sm:w-[500px] sm:p-0 xl:pt-0 response-section mt-10">
      <div
        ref={containerRef}
        className="mt-10"
        style={{
          ...(hasReceivedResponse && { height: "600px" }),
          overflowY: "auto",
          width: "930px",
          overflowX: "hidden",
        }}
      >
        <div className="flex flex-col items-start justify-start">
          {history.map((item, index) => (
            <div key={index} className="mt-4 w-full">
              <p className="h4 font-semibold mt-2">{item.question}</p>
              {item.data.length > 0 && (
                <div className="mt-5 ">
                  <div className="flex gap-2">
                    <RxAllSides />
                    <div className="font-semibold mt-2">{t("Sources")}</div>
                  </div>
                  <div className="flex gap-4">
                    <div className="gap-3 mt-4 mb-3" style={{ width: "30%" }}>
                      {item.data
                        .slice(0, viewMore ? item.data.length : 1)
                        .map((source, i) => (
                          <div
                            key={i}
                            className="response-container rounded gap-2 mb-4"
                            onMouseEnter={() => handleMouseEnter(i)}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => window.open(source.url, "_blank")}
                            style={{ position: "relative" }}
                          >
                            <h2 className="text-sm">
                              <a
                                href={source.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {source.journal}
                              </a>
                            </h2>
                            {hoveredSource === i && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "100%",
                                  left: "0",
                                  color: "black",
                                  backgroundColor: "white",
                                  border: "1px solid black",
                                  padding: "10px",
                                  zIndex: "10",
                                }}
                              >
                                <p style={{ fontSize: "12px" }}>
                                  <strong>Title:</strong> {source.title}
                                </p>
                                <p style={{ fontSize: "12px" }}>
                                  <strong>Authors:</strong>{" "}
                                  {source.authors
                                    ? source.authors.join(", ")
                                    : "Unknown"}{" "}
                                </p>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                    <div className="mt-3">
                      {item.data.length > 1 && (
                        <button
                          className="response-container rounded mt-2 font-semibold"
                          style={{ fontSize: "14px", height: "35px" }}
                          onClick={handleViewMore}
                        >
                          {viewMore ? "View Less" : "View More"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className=" flex gap-2 ">
                <div className="font-semibold  ">
                  {" "}
                  <BiMessageAdd />
                </div>
                <div className="font-semibold mt-2"> {t("Answer")} </div>
              </div>
              <div className="flex gap-2">
                <div className="mt-2">
                  <Answer text={item.answer} />
                </div>
                <div className="mt-2 mb-5">
                  <div style={{ width: "280px" }}>
                    <img className="rounded-xl" src={one} alt="" />
                  </div>
                  <div className="flex gap-2 justify-center mt-2">
                    <img
                      style={{ width: "130px" }}
                      className="rounded-xl"
                      src={two}
                      alt=""
                    />
                    <img
                      style={{ width: "130px" }}
                      className="rounded-xl"
                      src={three}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              {Array.isArray(item.instructions) && (
                <div>
                  <h2>Instructions:</h2>
                  <ul>
                    {item.instructions.map((instruction, index) => (
                      <li key={index}>{instruction}</li>
                    ))}
                  </ul>
                </div>
              )}
              {Array.isArray(item.medicines) && (
                <div>
                  <h2>Medicines:</h2>
                  <ul>
                    {item.medicines.map((medicine, index) => (
                      <li key={index}>{medicine.name}</li>
                    ))}
                  </ul>
                </div>
              )}
              {item.personalized_treatment_plan && (
                <div>
                  <h2>Personalized Treatment Plan:</h2>
                  <p>{item.personalized_treatment_plan.Diagnosis}</p>
                  <p>{item.personalized_treatment_plan["Treatment Plan"]}</p>
                </div>
              )}
              {item.test_reports && (
                <div>
                  <h2>Test Reports:</h2>
                  <ul>
                    {Object.entries(item.test_reports).map(
                      ([test, result], index) => (
                        <li key={index}>
                          <strong>{test}:</strong> {result}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
              {Array.isArray(item.tests) && (
                <div>
                  <h2>Tests:</h2>
                  <ul>
                    {item.tests.map((test, index) => (
                      <li key={index}>{test.name}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="mt-2">
                <hr />
              </div>
            </div>
          ))}
        </div>
        {loading && !received ? (
          <div className="lg:w-[900px] md:w-[600px] w-[300px]">
            <div className="font-bold text-indigo-800 text-2xl mt-6">
              {t("waiting")}
            </div>
            <div className="animate-pulse mt-2">
              <div
                style={{ backgroundColor: "#1480b7" }}
                className="h-4 bg-red-300 rounded"
              ></div>
              <div
                style={{ backgroundColor: "#1480b7" }}
                className="h-4 rounded mt-2"
              ></div>
              <div
                style={{ backgroundColor: "#1480b7" }}
                className="h-4 rounded mt-2"
              ></div>
              <div
                style={{ backgroundColor: "#1480b7" }}
                className="h-4 rounded mt-2"
              ></div>
              <div
                style={{ backgroundColor: "#1480b7" }}
                className="h-4 rounded mt-2"
              ></div>
              <div
                style={{ backgroundColor: "#1480b7" }}
                className="h-4 rounded mt-2"
              ></div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>

      <div className="flex justify-center items-center">
        {/* {showInitialInputBox ? (
          <Firstinbox
            setFileAttacehment={setFileAttacehment}
            onSubmit={handleQuestionSubmit}
          />
        ) : (
          <InputBox
            setFileAttacehment={setFileAttacehment}
            onSubmit={handleQuestionSubmit}
          />
        )} */}

        <InputBox
          setFileAttacehment={setFileAttacehment}
          onSubmit={handleQuestionSubmit}
        />

        {/* <button
          onClick={handleReset}
          className="bg-indigo-500 p-3 rounded-md hover:bg-indigo-700 hover:text-white"
          style={{ height: "50px", marginLeft: "20px" }}
        >
          <SlReload />
        </button> */}
      </div>
      <div className="response-main">
        <div className="flex text-sm justify-center items-center flex-wrap gap-2">
          {[
            "What are the suggestions for maintaining proper health fitness?",
            "Feeling nausea and stomach pain what medicines to use?",
            "What are the common side effects of taking antibiotics?",
            "Reason behind high blood pressure?",
            "What is hypertension?",
          ].map((question, index) => (
            <div key={index}>
              <p
                className="border border-black rounded-xl text-center p-1 cursor-pointer"
                style={{
                  width: "fit-content",
                  fontSize: "12px",
                  marginBottom: "10px",
                  marginTop: "-8px",
                }}
                onClick={() => handleSuggestedQuestionClick(question)}
              >
                {question}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
