



// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Toaster, toast } from "react-hot-toast";
// import { Link } from "react-router-dom";

// export default function Plandetails({ onUpgradeClick }) {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(true);
//   const [planType, setPlanType] = useState("monthly");
//   const [creditsData, setCreditsData] = useState(null);
//   const [loading, setLoading] = useState(false);

 

//   const handlePlanChange = (type) => {
//     setPlanType(type);
//   };
//   const overlayStyles = {
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     width: '100vw',
//     height: '100vh',
//     backgroundColor: 'rgba(255, 255, 255, 0.8)', 
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     zIndex: 1000,
//   };
  
//   const spinnerStyles = {
//     width: '5rem',
//     height: '5rem',
//     color: '#005477',
//   };
//   const getButtonStyle = (type) => ({
//     backgroundColor: "#111827",
//     transform: planType === type ? "scale(1.1)" : "scale(1)",
//     transition: "transform 0.3s ease",
//     fontWeight: planType === type ? "bold" : "normal",
//   });


//   const handleSubmit = async (price_id, title) => {
//     const token = "90a6538b480ee9c033b7deba15a0a1636bb0396c";

//     if (token) {
//       try {
//         const response = await axios({
//           method: "POST",

//           url:`${process.env.REACT_APP_API_URL}/client_handler/create_checkout_session`
//           ,
//           headers: { Authorization: `Token ${token}` },
//           data: {
//             price_id: price_id,
//             title: title,
//           },
//         });
//         if (response.data.status === 200) {
//           window.location.href = response.data.url;
//         } else {
//           toast.error("Cannot proceed with payment");
//         }
//       } catch (error) {
//         toast.error(`Error: ${error.message}`);
//       }
//     } else {
//       window.location.href = "/auth/login";
//     }
//   };

//   useEffect(() => {
//     const fetchCreditsData = async () => {
//       const token = localStorage.getItem("token");
  
//       if (token) {
//         setLoading(true); 

//         try {

//           const response = await axios({
//             method: "GET",
//             url:`${process.env.REACT_APP_API_URL}/client_handler/get_credits`
//             ,
//             headers: { Authorization: `Bearer ${token}` },
//           });
//           console.log(response ,"plan")

//           if (response.data.status === 200) {
//             setCreditsData(response.data.result);
//           } else {
//             toast.error("Unable to fetch credits data");
//           }
//         } catch (error) {
//           toast.error(`Error: ${error.message}`);
//         }
//         finally {
//           setLoading(false);
//         }
//       } 
//        else {
//         window.location.href = "/auth/login";
//       }
      
//     };
  
//     fetchCreditsData();
//   }, []); 
//   const cardStyles = {
//     container: {
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       width: '100vw',
//       height: '100vh',
//       padding: '20px',
//     },
//     card: {
//       minWidth: '450px',  
//       maxWidth: '800px', 
//       width: '100%',      
//       borderRadius: '20px',
//       paddingRight: '220px',
//       backgroundColor: 'white',
//       boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
//     },
//     mediaQueryStyles: `
//       @media (max-width: 768px) {
//         .pricing-card-responsive {
//           min-width: 100% !important;
//           max-width: 800px !important;
//           padding: 10px;
//           margin-left: 10px;  /* Prevent card from sticking to the left edge */
//           margin-right: 10px; /* Add padding on the right side */
//         }
//       }
//     `,
//   };

//   return (
//     <div>
//       <Toaster
//         position="top-right"
//         reverseOrder={false}
//         toastOptions={{
//           style: {
//             marginTop: "50px",
//           },
//         }}
//       />
//       <div
//       style={cardStyles.container}
//         // className="flex justify-center items-center"
//         // style={{
//         //   width: "100vw",
//         //   height: "100vh",
//         //   // backgroundColor: "white",
//         // }}
//       >
//          <section
//           className="section pricing-two bg-img"
//           style={{ backgroundColor: "white" }}
//         >
//              {loading ? (
//                           <span className="spinner-border" role="status" style={spinnerStyles} aria-hidden="true" ></span>
//                         ) : (
//                           <div
//                           className="flex flex-col justify-center items-center  "
//                           style={{
//                             maxWidth: "500px",
//                             width: "100%",
//                             // backgroundColor: "white",
//                             borderRadius: "20px",
//                           }}
//                         >
//                           {creditsData && (
//                         <div
//                         className="pricing-two__single wow fadeInUp"          data-wow-duration="600ms"
//                           data-wow-delay="1200ms"
//                           style={{
//                             minWidth: "350px",
//                             maxWidth: "400px",
//                             width: "100%",
//                             borderRadius: "20px",
//                             padding: "20px",
//                           }}
//                         >
//                           <h5 className="h5 pri" style={{ textAlign: "center", marginBottom: "20px" }}>
//                             Your Subscription Details
//                           </h5>
//                           <div className="meta">
//                             <div className="content">
//                             <h2 className="h2 title" style={{ color: "white" ,textAlign:"center" }}>
//                                 {creditsData.subscription_plan}
//                               </h2>
//                               <p style={{ textAlign: "center", marginBottom: "20px" }}>Current Plan</p>
//                             </div>
//                           </div>
//                           <hr />
//                           <ul className="text-left">
//                             <li><i className="fa-solid fa-check"></i> Credits Given: {creditsData.credits_given}</li>
//                             <li><i className="fa-solid fa-check"></i> Credits Used: {creditsData.credits_used}</li>
//                             <li><i className="fa-solid fa-check"></i> Credits Remaining: {creditsData.credits_remaining}</li>
//                             <li><i className="fa-solid fa-check"></i> Expiry Date: {creditsData.expiry_date}</li>
//                             <li><i className="fa-solid fa-check"></i> Expiration in Days: {creditsData.expiration_in_days} days</li>
//                           </ul>
//                           <hr />
//                           <div className="flex justify-center mt-4">
//                             <Link to="/upgrade">
//                               <button className="btn btn--septenary">Upgrade</button>
//                             </Link>
//                           </div>
//                         </div>
//                       )}
                         
//                         </div>                        )}
      
//         </section>
//       </div>
//     </div>
//   );
// }


import React, { useState, useEffect } from "react";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";

export default function Plandetails({ onUpgradeClick }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [planType, setPlanType] = useState("monthly");
  const [creditsData, setCreditsData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handlePlanChange = (type) => {
    setPlanType(type);
  };

  const getButtonStyle = (type) => ({
    backgroundColor: "#111827",
    transform: planType === type ? "scale(1.1)" : "scale(1)",
    transition: "transform 0.3s ease",
    fontWeight: planType === type ? "bold" : "normal",
  });

  const handleSubmit = async (price_id, title) => {
    const token = "90a6538b480ee9c033b7deba15a0a1636bb0396c";

    if (token) {
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/client_handler/create_checkout_session`,
          headers: { Authorization: `Token ${token}` },
          data: {
            price_id: price_id,
            title: title,
          },
        });
        if (response.data.status === 200) {
          window.location.href = response.data.url;
        } else {
          toast.error("Cannot proceed with payment");
        }
      } catch (error) {
        toast.error(`Error: ${error.message}`);
      }
    } else {
      window.location.href = "/auth/login";
    }
  };

  useEffect(() => {
    const fetchCreditsData = async () => {
      const token = localStorage.getItem("token");

      if (token) {
        setLoading(true);

        try {
          const response = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_API_URL}/client_handler/get_credits`,
            headers: { Authorization: `Bearer ${token}` },
          });

          if (response.data.status === 200) {
            setCreditsData(response.data.result);
          } else {
            toast.error("Unable to fetch credits data");
          }
        } catch (error) {
          toast.error(`Error: ${error.message}`);
        } finally {
          setLoading(false);
        }
      } else {
        window.location.href = "/auth/login";
      }
    };

    fetchCreditsData();
  }, []);

  const cardStyles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      height: "100vh",
      padding: "20px",
    },
    card: {
      minWidth: "450px",
      maxWidth: "800px",
      width: "100%",
      borderRadius: "20px",
      paddingRight: "220px",
      backgroundColor: "white",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    },
  };

  return (
    <div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            marginTop: "50px",
          },
        }}
      />
      <div style={cardStyles.container}>
        <section
          className="section pricing-two bg-img"
          style={{ backgroundColor: "white" }}
        >
          {loading ? (
         <div
         style={{
           width: "400px",
           maxWidth: "500px",
           borderRadius: "20px",
           border: "1px solid #ddd", // Add border to match your UI
           padding: "20px", // Padding to ensure content doesn't touch the border
           marginBottom: "20px",
         }}
       >
         {/* Skeleton for the card background */}
         <Skeleton
           variant="rectangular"
           animation="wave"
           width="100%"
           height={300}
           style={{
             borderRadius: "20px",
             marginBottom: "20px",
           }}
         />
         
         {/* Skeletons for wave effects */}
         {[...Array(6)].map((_, index) => (
           <Skeleton
             key={index}
             variant="text"
             animation="wave"
             width="100%" // Full width to match container
             height={20}
             style={{
               marginBottom: "20px",
               borderRadius: "5px", // Optional: add border-radius to the wave skeletons
             }}
           />
         ))}
       </div>
       
          ) : (
            <div
              className="flex flex-col justify-center items-center"
              style={{
                maxWidth: "500px",
                width: "100%",
                borderRadius: "20px",
              }}
            >
              {creditsData && (
                <div
                  className="pricing-two__single wow fadeInUp"
                  data-wow-duration="600ms"
                  data-wow-delay="1200ms"
                  style={{
                    minWidth: "350px",
                    maxWidth: "400px",
                    width: "100%",
                    borderRadius: "20px",
                    padding: "20px",
                  }}
                >
                  <h5
                    className="h5 pri"
                    style={{ textAlign: "center", marginBottom: "20px" }}
                  >
                    Your Subscription Details
                  </h5>
                  <div className="meta">
                    <div className="content">
                      <h2
                        className="h2 title"
                        style={{ color: "white", textAlign: "center" }}
                      >
                        {creditsData.subscription_plan}
                      </h2>
                      <p
                        style={{
                          textAlign: "center",
                          marginBottom: "20px",
                        }}
                      >
                        Current Plan
                      </p>
                    </div>
                  </div>
                  <hr />
                  <ul className="text-left">
                    <li>
                      <i className="fa-solid fa-check"></i> Credits Given:{" "}
                      {creditsData.credits_given}
                    </li>
                    <li>
                      <i className="fa-solid fa-check"></i> Credits Used:{" "}
                      {creditsData.credits_used}
                    </li>
                    <li>
                      <i className="fa-solid fa-check"></i> Credits Remaining:{" "}
                      {creditsData.credits_remaining}
                    </li>
                    <li>
                      <i className="fa-solid fa-check"></i> Expiry Date:{" "}
                      {creditsData.expiry_date}
                    </li>
                    <li>
                      <i className="fa-solid fa-check"></i> Expiration in Days:{" "}
                      {creditsData.expiration_in_days} days
                    </li>
                  </ul>
                  <hr />
                  <div className="flex justify-center mt-4">
                    <Link to="/upgrade">
                      <button className="btn btn--septenary">Upgrade</button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          )}
        </section>
      </div>
    </div>
  );
}
