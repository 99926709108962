import React, { useState, useEffect } from "react";
import NavBar from "./Navbar";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { IoMdEye } from "react-icons/io";
import { FaEyeSlash } from "react-icons/fa";
import { Toaster, toast } from "react-hot-toast";
import Footer from "./Footer";

export default function ChangePassword() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    new_password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");

  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);
  const toggleConfirmPasswordVisibility = () =>
    setConfirmPasswordVisible(!confirmPasswordVisible);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const urlParams = new URLSearchParams(location.search);

  const token = urlParams.get("token");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.new_password !== formData.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    try {

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/api/forget_password?token=${token}`
        ,
        {
          new_password: formData.new_password,
        }
      );

      console.log(response.data, "changepas");

      if (response.data.status === 200) {
        const { message } = response.data;
        toast.success(message);

        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else if (response.data.status === 400) {
        const errorMessage =
          response.data.message ||
          "Provided link is invalid. Please regenerate link again.";
        toast.error(errorMessage, {
          duration: 4000,
          style: {
            whiteSpace: "pre-wrap",
          },
        });

        // Redirect the user to /forgetpassword
        setTimeout(() => {
          navigate("/forgetpassword");
        }, 1000);
      } else {
        const unexpectedMessage =
          response.data.message || "An unexpected error occurred.";
        toast.error(unexpectedMessage);
      } 


      
    } catch (error) {
      setError("Something went wrong. Please try again.");
      toast.error("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.onload = () => resolve(src);
        script.onerror = () =>
          reject(new Error(`Failed to load script ${src}`));
        document.body.appendChild(script);
      });
    };

    const scripts = [
      "/assets/js/jquery-3.7.0.min.js",
      "/assets/js/bootstrap.bundle.min.js",
      "/assets/js/jquery.nice-select.min.js",
      "/assets/js/jquery.magnific-popup.min.js",
      "/assets/js/slick.min.js",
      "/assets/js/typed.umd.js",
      "/assets/js/wow.min.js",
      "/assets/js/plugins.js",
      "/assets/js/main.js",
    ];

    scripts.reduce((promise, script) => {
      return promise.then(() => loadScript(script));
    }, Promise.resolve());

    return () => {
      scripts.forEach((src) => {
        const script = document.querySelector(`script[src="${src}"]`);
        if (script && script.parentNode === document.body) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);

  return (
    <div>
      <div id="preloader">
        <div id="loader"></div>
      </div>
      <div className="my-app home-seven">
        <NavBar />
        <section
          className="section cmn-banner"
          data-background="assets/images/banner/banner-bg.png"
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="cmn-banner__content wow fadeInUp">
                  <h3 className="h3">Change Password</h3>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-center">
                      <li className="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Change Password
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="anime">
            <img
              src="assets/images/anime-one.png"
              alt="Image"
              className="one"
            />
            <img
              src="assets/images/anime-two.png"
              alt="Image"
              className="two"
            />
          </div>
        </section>
   {/* <div className="d-flex justify-content-center align-items-center mt-10">
          <div style={{ width: "300px" }} className="mx-auto">
            <a href="/">
              <img
                style={{ width: "200px" }}
                src="/assets/images/logo.png"
                alt="Logo"
              />
            </a>
          </div>
        </div> */}
        <Toaster position="top-right" reverseOrder={false} />

        <section className="section authentication pb-0">
        <div className="container m-auto password_reset_form">
        <div className="row justify-content-center">
              <div className="col-12 col-lg-10 col-xl-6">
                <div className="authentication__inner wow fadeInUp">
                  <div className="section__header text-start">
                    <h2 className="h3">Change Password</h2>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div
                      className="input-single flex"
                      style={{ position: "relative" }}
                    >
                      <input
                        type={passwordVisible ? "text" : "password"}
                        name="new_password"
                        value={formData.new_password}
                        onChange={handleChange}
                        placeholder="New password"
                        required
                        style={{
                          width: "100%",
                          paddingRight: "40px",
                          textTransform: "none",
                        }}
                      />
                      <button
                        type="button"
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={togglePasswordVisibility}
                      >
                        {passwordVisible ? <FaEyeSlash /> : <IoMdEye />}
                      </button>
                    </div>

                    <div
                      className="input-single flex"
                      style={{ position: "relative" }}
                    >
                      <input
                        type={confirmPasswordVisible ? "text" : "password"}
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        placeholder="Confirm password"
                        required
                        style={{
                          width: "100%",
                          paddingRight: "40px",
                          textTransform: "none",
                        }}
                      />
                      <button
                        type="button"
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        {confirmPasswordVisible ? <FaEyeSlash /> : <IoMdEye />}
                      </button>
                    </div>

                    <div className="form-cta">
                      <button type="submit" className="btn btn--ocotonary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}
