// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// export const setupAxiosInterceptors = (navigate) => {
//     axios.interceptors.response.use(
//         (response) => response,
//         (error) => {
//             if (error.response && error.response.status === 401) {
//                 console.error("Token is invalid or expired");
//                 localStorage.removeItem("token"); // Remove invalid token
//                 navigate("/signin"); // Redirect to /signin
//             }
//             return Promise.reject(error);
//         }
//     );
    
// };



import axios from "axios";

export const setupAxiosInterceptors = (navigate) => {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && error.response.status === 401) {
                console.error("Token is invalid or expired");
                localStorage.removeItem("token"); 
                navigate("/"); 
            }
            return Promise.reject(error);
        }
    );
};
