import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./App.css";
import Main from "./Components/Main";
import Landingpage from "./Components/Landingpage/Landingpage";
import Signin from "../src/Components/Landingpage/Newlandingpage/Signin";
import Signup from "../src/Components/Landingpage/Newlandingpage/Signup";
import axios from "axios";
import "../src/assets/scss/main.scss";
import Aboutus from "./Components/Landingpage/Newlandingpage/Aboutus";
import MainLandingpage from "./Components/Landingpage/Newlandingpage/MainLandingpage";
import Contactus from "./Components/Landingpage/Newlandingpage/Contact";
import Chatbox from "./Components/Dashboardlayout";
import Error from "./Components/Landingpage/Newlandingpage/Error";
import Upgrade from "./Components/Upgrade";

import Privacypolicy from "./Components/Landingpage/Newlandingpage/Privacypolicy";
import Blog from "./Components/Landingpage/Newlandingpage/Blog";
import Singleblog from "./Components/Landingpage/Newlandingpage/Singleblog";
import Decodingmedical from "./Components/Landingpage/Newlandingpage/Decoding-medical";
import Telemedicine from "./Components/Landingpage/Newlandingpage/Telemedicine";
import Navigatingreliable from "./Components/Landingpage/Newlandingpage/Navigatingreliable ";
import Success from "./Components/Landingpage/Newlandingpage/Success";
import Emailverifyforgetpass from "./Components/Landingpage/Newlandingpage/Emailverifyforgetpass";
import Changepassword from "./Components/Landingpage/Newlandingpage/Changepassword";
import Mfa from "./Components/Landingpage/Newlandingpage/Mfa";
import Resetpassword from "./Components/Landingpage/Newlandingpage/Resetpassword";
import Enable2fa from "./Components/Landingpage/Newlandingpage/Enable2fa";
import Verify from "./Components/Landingpage/Newlandingpage/Verify";
import Plandetails from "./Components/Plandetails";
import Setting from "./Components/Setting";
import Dashboardlayout from "./Components/Dashboardlayout";
import Chatbot from "./Components/Chatbot";
import Chatparent from "./Components/Chatparent";
import Firstchat from "./Components/Firstchat";
import ChatApp from "./Components/Chatapp";
import Chatheader from "./Components/Chatheader";
import Oldchat from "./Components/Oldchat";
import Doctors from "./Components/Doctors";
import Pharmacy from "./Components/Pharmacy";
import Laboratory from "./Components/Labortary";
import Feedback from "./Components/Feedback";
import { setupAxiosInterceptors } from "./axiosSetup";

import  { PrivateRoute, PublicRoute } from "./RouteWrappers";
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // null initially
  const navigate = useNavigate();
  console.log("isAuthenticated:", isAuthenticated);

  useEffect(() => {
    setupAxiosInterceptors(navigate);

    const checkToken = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setIsAuthenticated(false);
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/api/check-token/`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        setIsAuthenticated(false);
        localStorage.removeItem("token");
      }
    };

    checkToken();
  }, [navigate]);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Show loading state while checking authentication
  }
  return (
    <div className="">

      <Routes>
          {/* Public Routes */}
          <Route
    path="/"
    element={
      isAuthenticated ? (
        <Dashboardlayout />
      ) : (
        <PublicRoute isAuthenticated={isAuthenticated}>
          <MainLandingpage />
        </PublicRoute>
      )
    }
  >
    {/* Nested Routes */}
    <Route index element={<ChatApp />} /> {/* Default component */}
    <Route path="chat" element={<Chatbot />} />
    <Route path="oldchat" element={<Oldchat />} />
    <Route path="nearest-doctor" element={<Doctors />} />
    <Route path="nearest-pharmacy" element={<Pharmacy />} />
    <Route path="nearest-laboratory" element={<Laboratory />} />
    <Route path="feedback" element={<Feedback />} />
    <Route path="setting" element={<Setting />} />
    <Route path="plan" element={<Plandetails />} />
    <Route path="upgrade" element={<Upgrade />} />
    <Route path="subscription_success" element={<Success />} />
    <Route
        path="resetpassword" element={ <Resetpassword />}/>
          <Route path="enable2fa" element={<Enable2fa />} />

  </Route>

 
         

        <Route
          path="/signin"
          element={
            <PublicRoute isAuthenticated={isAuthenticated}>
              <Signin />
            </PublicRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <PublicRoute isAuthenticated={isAuthenticated}>
              <Signup />
            </PublicRoute>
          }
        />
        <Route
          path="/mfa"
          element={
            <PublicRoute isAuthenticated={isAuthenticated}>
              <Mfa />
            </PublicRoute>
          }
        />
  <Route
         path="/blog"
          element={
            <PublicRoute isAuthenticated={isAuthenticated}>
             <Blog />
            </PublicRoute>
          }
        />
         <Route
        path="/decoding-medical"
          element={
            <PublicRoute isAuthenticated={isAuthenticated}>
            <Decodingmedical />
            </PublicRoute>
          }
        />
           <Route
        path="/telemedicine"
          element={
            <PublicRoute isAuthenticated={isAuthenticated}>
            <Telemedicine />
            </PublicRoute>
          }
        />   <Route
        path="/navigating-relaible"
          element={
            <PublicRoute isAuthenticated={isAuthenticated}>
            <Navigatingreliable />
            </PublicRoute>
          }
        />  
        
         <Route
        path="/verify"
          element={
            <PublicRoute isAuthenticated={isAuthenticated}>
            <Verify />
            </PublicRoute>
          }
        />
          <Route
        path="/404"
          element={
            <PublicRoute isAuthenticated={isAuthenticated}>
            <Error />
            </PublicRoute>
          }
        />
          <Route
        path="/contactus"
          element={
            <PublicRoute isAuthenticated={isAuthenticated}>
            <Contactus />
            </PublicRoute>
          }
        />
            <Route
        path="/forgetpassword"
          element={
            <PublicRoute isAuthenticated={isAuthenticated}>
            <Emailverifyforgetpass />
            </PublicRoute>
          }
        />
            <Route
        path="/changepassword"
          element={
            <PublicRoute isAuthenticated={isAuthenticated}>
            <Changepassword />
            </PublicRoute>
          }
        />
           
          <Route
        path="/privacypolicy"
          element={
            <PublicRoute isAuthenticated={isAuthenticated}>
            <Privacypolicy />
            </PublicRoute>
          }
        />
          <Route
        path="/aboutus"
          element={
            <PublicRoute isAuthenticated={isAuthenticated}>
            <Aboutus />
            </PublicRoute>
          }
        />
          <Route
        path="/main"
          element={
            <PublicRoute isAuthenticated={isAuthenticated}>
            <Main />
            </PublicRoute>
          }
        />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        style={{ maxWidth: "1000px", wordWrap: "break-word" }}
      />
    </div>
  );
}

export default App;
