import React, { useState, useEffect } from "react";
import NavBar from "./Navbar";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "./Footer";

export default function Error() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // Function to dynamically load a script
    const loadScript = (src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return script;
    };

    // List of scripts to load
    const scripts = [
      "/assets/js/jquery-3.7.0.min.js",
      "/assets/js/bootstrap.bundle.min.js",
      "/assets/js/jquery.nice-select.min.js",
      "/assets/js/jquery.magnific-popup.min.js",
      "/assets/js/slick.min.js",
      "/assets/js/typed.umd.js",
      "/assets/js/wow.min.js",
      "/assets/js/plugins.js",
      "/assets/js/main.js",
    ];

    // Load all scripts and keep references to them
    const scriptElements = scripts.map((src) => loadScript(src));

    // Cleanup function to remove scripts when component unmounts
    return () => {
      scriptElements.forEach((script) => {
        if (script && script.parentNode === document.body) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);
  const handleScrollClick = (to) => {
    if (location.pathname !== "/") {
      navigate("/", { state: { scrollTo: to } });
    } else {
      const element = document.getElementById(to);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  return (
    <div>
      <body>
        <div id="preloader">
          <div id="loader"></div>
        </div>
        <div class="my-app home-seven">
          <NavBar />

         
          <section class="section error pb-0 ">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="error__content text-center">
                    <div class="section__header">
                      <h2
                        class="h3 wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="300ms"
                      >
                        404
                      </h2>
                      <p
                        class="wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="600ms"
                      >
                        page not found
                      </p>
                    </div>
                    <div className="flex justify-center items-center">
                      {" "}
                      <img
                        src="assets/images/error-thumb.png"
                        alt="Image"
                        class="wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="900ms"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
          {/* <footer className="footer-two section pb-0 mt-2">
            <div className="container">
              <div className="row items-gap-two">
                <div className="col-12 col-sm-6 col-xl-4 col-xxl-5">
                  <div
                    className="footer-two__single bt wow fadeInUp"
                    data-wow-duration="600ms"
                  >
                    <a href="/" className="logo">
                      <img src="assets/images/logo.png" alt="Image" />
                    </a>
                    <p>
                      Over 3.1 Million+ Users Trust MedicalGPT for Accurate and
                      Reliable Medical Information, Powered by Advanced AI
                      Technology.
                    </p>
                    <div className="social">
                      <a href="index.html" aria-label="social media">
                        <i className="fa-brands fa-facebook-f"></i>
                      </a>
                      <a href="index.html" aria-label="social media">
                        <i className="fa-brands fa-twitter"></i>
                      </a>
                      <a href="index.html" aria-label="social media">
                        <i className="fa-brands fa-linkedin-in"></i>
                      </a>
                      <a href="index.html" aria-label="social media">
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-xl-2 col-xxl-2 offset-xxl-1 text-center text-sm-left">
                  <div
                    className="footer-two__single wow fadeInUp"
                    data-wow-duration="600ms"
                    data-wow-delay="200ms"
                  >
                    <ul className="list-unstyled">
                      <li>
                        <a href="/aboutus">About</a>
                      </li>
                      <li>
                        <a href="/signin">Sign in</a>
                      </li>
                      <li>
                        <a href="/signup">Register</a>
                      </li>
                      <li>
                        <a href="/contactus">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-xl-2 col-xxl-2 text-center text-sm-left">
                  <div
                    className="footer-two__single wow fadeInUp"
                    data-wow-duration="600ms"
                    data-wow-delay="400ms"
                  >
                    <ul className="list-unstyled">
                      <li>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleScrollClick("howitwork");
                          }}
                        >
                          How it works
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleScrollClick("keyfeatuers");
                          }}
                        >
                          Key Features
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleScrollClick("ourmission");
                          }}
                        >
                          Our Vision
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleScrollClick("ourmission");
                          }}
                        >
                          FAQ's
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="copyright text-center">
                    <p>
                      Copyright &copy;
                      <span id="copyYear"></span>
                      <a style={{ marginLeft: "5px" }}>MedicalGPT</a>. Design By
                      <a
                        style={{ marginLeft: "5px" }}
                        href="https://www.datalyticsai.com/"
                      >
                        DatalyticsAI
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </footer> */}
        </div>

        <div class="progress-wrap">
          <svg
            class="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
        <script src="/assets/js/jquery-3.7.0.min.js"></script>
        <script src="/assets/js/bootstrap.bundle.min.js"></script>
        <script src="/assets/js/jquery.nice-select.min.js"></script>
        <script src="/assets/js/jquery.magnific-popup.min.js"></script>
        <script src="/assets/js/slick.min.js"></script>
        {/* <!-- typed js --> */}
        <script src="/assets/js/typed.umd.js"></script>
        {/* <!-- wow js --> */}
        <script src="/assets/js/wow.min.js"></script>
        {/* <!-- ==== / js dependencies end ==== --> */}
        {/* <!-- plugins js --> */}
        <script src="/assets/js/plugins.js"></script>
        {/* <!-- main js --> */}
        <script src="/assets/js/main.js"></script>
      </body>
    </div>
  );
}
