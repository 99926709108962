import React from "react";

export default function Testimonial() {
  return (
    <div class="my-app home-two-light">
      {" "}
      <section class="section pb-0 testimonial">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div
                class="section__header wow fadeInUp"
                data-wow-duration="600ms"
                data-wow-delay="300ms"
              >
                <h4 class="h2">professionals & teams choose MedicalGPT</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="testimonial__slider-wrapper">
          <div class="testimonial__slider">
            <div class="review__single">
              <div class="review-head">
                <div class="review__icons">
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                </div>
                <img src="assets/images/review/icon-one.png" alt="Image" />
              </div>
              <div class="review-content">
                <p class="fw-7">
                  MedicalGPT has streamlined our patient consultations, saving
                  us countless hours every week. We now quickly access reliable
                  medical information and deliver informed care with ease.
                </p>
              </div>
              <div class="review-meta">
                <div class="thumb">
                  <img src="assets/images/review/one.png" alt="Image" />
                </div>
                <div class="content">
                  <p class="h6">Rex A. Munoz</p>
                  <p>CEO, uSERP</p>
                </div>
              </div>
            </div>
            <div class="review__single">
              <div class="review-head">
                <div class="review__icons">
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                </div>
                <img src="assets/images/review/icon-two.png" alt="Image" />
              </div>
              <div class="review-content">
                <p class="fw-7">
                  As a medical student, MedicalGPT has been a game-changer. It
                  helps me quickly verify information, draft reports, and stay
                  ahead in my studies, all in record time.
                </p>
              </div>
              <div class="review-meta">
                <div class="thumb">
                  <img src="assets/images/review/two.png" alt="Image" />
                </div>
                <div class="content">
                  <p class="h6">Nicholas Natteau</p>
                  <p>CEO, uSERP</p>
                </div>
              </div>
            </div>
            <div class="review__single">
              <div class="review-head">
                <div class="review__icons">
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                </div>
                <img src="assets/images/review/icon-three.png" alt="Image" />
              </div>
              <div class="review-content">
                <p class="fw-7">
                  MedicalGPT has revolutionized our medical research process. We
                  generate reports and insights in minutes, greatly reducing our
                  workload and increasing efficiency.
                </p>
              </div>
              <div class="review-meta">
                <div class="thumb">
                  <img src="assets/images/review/two.png" alt="Image" />
                </div>
                <div class="content">
                  <p class="h6">Jeremy Moser</p>
                  <p>CEO, uSERP</p>
                </div>
              </div>
            </div>
            <div class="review__single">
              <div class="review-head">
                <div class="review__icons">
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                </div>
                <img src="assets/images/review/icon-one.png" alt="Image" />
              </div>
              <div class="review-content">
                <p class="fw-7">
                  Our clinic now uses MedicalGPT for patient education and quick
                  reference. It’s like having an extra pair of hands, helping us
                  deliver top-notch care while staying on schedule.
                </p>
              </div>
              <div class="review-meta">
                <div class="thumb">
                  <img src="assets/images/review/three.png" alt="Image" />
                </div>
                <div class="content">
                  <p class="h6">Angela R. Nolan</p>
                  <p>CEO, uSERP</p>
                </div>
              </div>
            </div>
            <div class="review__single">
              <div class="review-head">
                <div class="review__icons">
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                </div>
                <img src="assets/images/review/icon-one.png" alt="Image" />
              </div>
              <div class="review-content">
                <p class="fw-7">
                  MedicalGPT is an essential tool in our telemedicine practice.
                  It helps us provide accurate advice instantly, enhancing
                  patient satisfaction and reducing consultation times.
                </p>
              </div>
              <div class="review-meta">
                <div class="thumb">
                  <img src="assets/images/review/one.png" alt="Image" />
                </div>
                <div class="content">
                  <p class="h6">Rex A. Munoz</p>
                  <p>CEO, uSERP</p>
                </div>
              </div>
            </div>
            {/* <div class="review__single">
              <div class="review-head">
                <div class="review__icons">
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                </div>
                <img src="assets/images/review/icon-two.png" alt="Image" />
              </div>
              <div class="review-content">
                <p class="fw-7">
                  tech ai saves us thousands of dollars a week. We create
                  content briefs and write 50-100 unique SEO-driven pieces per
                  month. With tech.ai, we now craft briefs, outlines, and full
                  first.
                </p>
              </div>
              <div class="review-meta">
                <div class="thumb">
                  <img src="assets/images/review/two.png" alt="Image" />
                </div>
                <div class="content">
                  <p class="h6">Nicholas Natteau</p>
                  <p>CEO, uSERP</p>
                </div>
              </div>
            </div>
            <div class="review__single">
              <div class="review-head">
                <div class="review__icons">
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                </div>
                <img src="assets/images/review/icon-three.png" alt="Image" />
              </div>
              <div class="review-content">
                <p class="fw-7">
                  tech ai saves us thousands of dollars a week. We create
                  content briefs and write 50-100 unique SEO-driven pieces per
                  month. With tech.ai, we now craft briefs, outlines, and full
                  first.
                </p>
              </div>
              <div class="review-meta">
                <div class="thumb">
                  <img src="assets/images/review/two.png" alt="Image" />
                </div>
                <div class="content">
                  <p class="h6">Jeremy Moser</p>
                  <p>CEO, uSERP</p>
                </div>
              </div>
            </div>
            <div class="review__single">
              <div class="review-head">
                <div class="review__icons">
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                </div>
                <img src="assets/images/review/icon-one.png" alt="Image" />
              </div>
              <div class="review-content">
                <p class="fw-7">
                  tech ai saves us thousands of dollars a week. We create
                  content briefs and write 50-100 unique SEO-driven pieces per
                  month. With tech.ai, we now craft briefs, outlines, and full
                  first.
                </p>
              </div>
              <div class="review-meta">
                <div class="thumb">
                  <img src="assets/images/review/three.png" alt="Image" />
                </div>
                <div class="content">
                  <p class="h6">Angela R. Nolan</p>
                  <p>CEO, uSERP</p>
                </div>
              </div>
            </div> */}
          </div>
          <div class="testimonial__arrows">
            <button
              aria-label="previous slide"
              class="slide-btn prev-testimonial"
            >
              <i class="tech-left-angle"></i>
            </button>
            <button aria-label="next slide" class="slide-btn next-testimonial">
              <i class="tech-right-angle"></i>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}
