import React from "react";

export default function Howitwork() {
  return (
    <div>
      {" "}
      <div class="my-app home-two-light">
        <section class="section publish">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-xl-10">
                <div class="publish__inner">
                  <div class="row justify-content-center">
                    <div class="col-12">
                      <div
                        class="section__header wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="300ms"
                      >
                        <h2 class="h2">How It Works</h2>
                      </div>
                    </div>
                  </div>
                  <div class="row items-gap">
                    <div class="col-12 col-sm-6 col-lg-4">
                      <div
                        class="publish__single wow fadeInUp"
                        data-wow-duration="600ms"
                      >
                        <div class="thumb">
                          <i class="fa-solid fa-square-check"></i>
                        </div>
                        <div class="content">
                          <h5 class="h5" style={{ color: "black" }}>
                            Ask Query
                          </h5>

                          <p className="p" style={{ color: "black" }}>
                            Type medical query, Attach PDFs, Word docs, or
                            images to receive instant, accurate answers tailored
                            to your needs.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4">
                      <div
                        class="publish__single wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="300ms"
                      >
                        <div class="thumb">
                          <i class="fa-solid fa-square-check"></i>
                        </div>
                        <div class="content">
                          <h5 class="h5" style={{ color: "black" }}>
                            AI Search
                          </h5>
                          <p className="p" style={{ color: "black" }}>
                            MedicalGPT's LLM model, trained on medical data,
                            processes the input, either through text search or
                            by analyzing the uploaded documents.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4">
                      <div
                        class="publish__single wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="600ms"
                      >
                        <div class="thumb">
                          <i class="fa-solid fa-square-check"></i>
                        </div>
                        <div class="content">
                          <h5 class="h5" style={{ color: "black" }}>
                            Dynamic Output
                          </h5>
                          <p className="p" style={{ color: "black" }}>
                            Provide detailed diagnosis, medication, tests
                            recommendation and insights into the query provided.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4">
                      <div
                        class="publish__single wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="600ms"
                      >
                        <div class="thumb">
                          <i class="fa-solid fa-square-check"></i>
                        </div>
                        <div class="content" style={{ color: "black" }}>
                          <h5 class="h5" style={{ color: "black" }}>
                            Recommendations and Referrals
                          </h5>
                          <p className="p" style={{ color: "black" }}>
                            Get a curated list of doctors, hospitals,
                            pharmacies, and laboratories with essential details
                            (location, availability, and fees).
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div
                    class="section__content-cta text-center wow fadeInUp"
                    data-wow-duration="600ms"
                    data-wow-delay="300ms"
                  >
                    <a href="/contactus" class="btn btn--senary">
                      try a free demo
                    </a>

                    <img src="assets/images/frame-three.png" alt="Image" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
