
import React, { useState, useEffect, useRef } from "react";
import NavBar from "./Navbar";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "./Footer";
import { Toaster, toast } from "react-hot-toast";
import axios from "axios";

export default function Success() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const searchParams = new URLSearchParams(location.search);
  const session_id = searchParams.get("session_id");
  const plan_id = searchParams.get("plan_id");

  

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.onload = () => resolve(src);
        script.onerror = () =>
          reject(new Error(`Failed to load script ${src}`));
        document.body.appendChild(script);
      });
    };

    const scripts = [
      "/assets/js/jquery-3.7.0.min.js",
      "/assets/js/bootstrap.bundle.min.js",
      "/assets/js/jquery.nice-select.min.js",
      "/assets/js/jquery.magnific-popup.min.js",
      "/assets/js/slick.min.js",
      "/assets/js/typed.umd.js",
      "/assets/js/wow.min.js",
      "/assets/js/plugins.js",
      "/assets/js/main.js",
    ];

    scripts
      .reduce((promise, script) => {
        return promise.then(() => loadScript(script));
      }, Promise.resolve())
      .catch((error) => console.error(error));

    return () => {
      scripts.forEach((src) => {
        const script = document.querySelector(`script[src="${src}"]`);
        if (script && script.parentNode === document.body) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");

        const response = await axios({
          method: "POST",
          url:`${process.env.REACT_APP_API_URL}/client_handler/create_credits`
          ,
          headers: { Authorization: `Bearer ${token}` },
          data: {
            session_id: session_id,
            plan_id: plan_id,
          },
        });
          console.log(response,"success")
        if (response.data.status === 200) {
          toast.success(response.data.message );
          console.log("Redirecting to dashboard...");
        }
        else if (response.data.status === 400) {


        }
        else {
          toast.error("Failed to deliver. Please try again.");
        }
      } catch (error) {
        toast.error("An error occurred during the payment process");
      }
    };

    if (session_id && plan_id) {
      fetchData();
    }
  }, [navigate, session_id, plan_id]);
  return (
    <div>
   
        
      
       
        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={{
          
          }}
        />
    
    <div
      className={`flex flex-col mt-2 p-5 justify-center items-center ${
        isSidebarOpen ? "md:ml-[350px] md:w-[calc(100%-350px)]" : "w-full"
      }`}
   
      style={{
        transition: "width 0.3s, margin-left 0.3s",
        backgroundColor: "white",
        borderRadius: "20px",
      
        // maxWidth: "1200px", 
       
      }}
>
  <div className="flex justify-center items-center h-full">
    <div className="text-center " style={{marginTop:"130px"}}>
      <div>
      <div className="row justify-content-center">
                              <div>
                                <div className="section__header">
                                  <h3 style={{ width: "100%" }} className="h3">
                                  Payment Created Successfully
                                  </h3>
                                </div>
                              </div>
                            </div>
       
      </div>
      <div className="flex justify-center items-center mt-5">
        {/* Uncomment the button if needed */}
        {/* <button
          className="btn btn--secondary"
          style={{
            backgroundColor: "#4569e7",
            borderRadius: "10px",
          }}
          onClick={() => navigate("/")}
        >
          Go to Dashboard
          <i
            style={{ marginLeft: "8px" }}
            className="fa-solid fa-arrow-right-to-bracket"
          ></i>
        </button> */}
      </div>
      <div className="flex justify-center items-center mt-10">
        <img
          src="/assets/images/success.png"
          alt="Success"
          className="wow fadeInUp"
          data-wow-duration="600ms"
          data-wow-delay="900ms"
        />
      </div>
    </div>
  </div>
</div>

       

     
      
      
   
    </div>
  );
}
