import React from "react";

export default function Keyfeatuers() {
  return (
    <div>
      {" "}
      <section class="section broadcast pb-0">
        <div class="row justify-content-center">
          <div class="col-12">
            <div
              class="section__header wow fadeInUp"
              data-wow-duration="600ms"
              data-wow-delay="300ms"
            >
              <h2 style={{ color: "black" }} class="h2">
                Key Features
              </h2>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 fix-scroll">
              <div
                class="broadcast__inner wow fadeInUp"
                data-wow-duration="600ms"
                data-background="assets/images/broadcast-bg.png"
              >
                <div class="row align-items-center items-gap-two">
                  <div class="col-12 col-xl-8 col-xxl-7">
                    <div class="section__content broadcast__inner-content">
                      <h2 class="h2">Text Response</h2>
                      <div class="paragraph">
                        <p>
                          Receive detailed, expert answers through our advanced
                          AI models. Access comprehensive medical information
                          directly through text, ensuring clear and precise
                          responses tailored to your needs.
                        </p>
                      </div>
                      {/* <div class="section__content-cta">
                        <a href="contact-us.html" class="btn btn--secondary">
                          request A Demo
                        </a>
                      </div> */}
                    </div>
                  </div>
                  <div class="col-12 col-xl-4 col-xxl-5">
                    <div class="broadcast__thumb text-xl-center">
                      <img
                        src="assets/images/textandspeechresponse.png"
                        alt="Image"
                      />
                    </div>
                  </div>
                </div>
                <div class="anime">
                  <img
                    src="assets/images/anime-three.png"
                    alt="Image"
                    class="anime-one"
                  />
                  <img
                    src="assets/images/anime-two.png"
                    alt="Image"
                    class="anime-two"
                  />
                </div>
              </div>
              <div
                class="broadcast__inner wow fadeInUp"
                data-wow-duration="600ms"
                data-background="assets/images/broadcast-bg.png"
              >
                <div class="row align-items-center items-gap-two">
                  <div class="col-12 col-xl-4 col-xxl-5">
                    <div class="broadcast__thumb text-xl-center">
                      <img src="assets/images/medical.png" alt="Image" />
                    </div>
                  </div>
                  <div class="col-12 col-xl-8 col-xxl-7">
                    <div class="section__content broadcast__inner-content">
                      <h2 class="h2">AI-Powered Insight</h2>
                      <div class="paragraph">
                        <p>
                          Leverage advanced AI technology that delivers accurate
                          and up-to-date medical information. Our system
                          interprets complex data to provide highly relevant and
                          dependable guidance for your healthcare needs.
                        </p>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div class="anime">
                  <img
                    src="assets/images/anime-three.png"
                    alt="Image"
                    class="anime-one"
                  />
                  <img
                    src="assets/images/anime-two.png"
                    alt="Image"
                    class="anime-two"
                  />
                </div>
              </div>
              <div
                class="broadcast__inner wow fadeInUp"
                data-wow-duration="600ms"
                data-background="assets/images/broadcast-bg.png"
              >
                <div class="row align-items-center items-gap-two">
                  <div class="col-12 col-xl-8 col-xxl-7">
                    <div class="section__content broadcast__inner-content">
                      <h2 class="h2">Healthcare Professional Guidance</h2>
                      <div class="paragraph">
                        <p>
                          MedicalGPT provides expert insights and evidence-based
                          recommendations, supporting healthcare providers in
                          making informed decisions and helping patients better
                          understand their health concerns.
                        </p>
                      </div>
                      {/* <div class="section__content-cta">
                        <a href="contact-us.html" class="btn btn--secondary">
                          request A Demo
                        </a>
                      </div> */}
                    </div>
                  </div>
                  <div class="col-12 col-xl-4 col-xxl-5">
                    <div class="broadcast__thumb text-xl-center">
                      <img src="assets/images/trustedsources.png" alt="Image" />
                    </div>
                  </div>
                </div>
                <div class="anime">
                  <img
                    src="assets/images/anime-three.png"
                    alt="Image"
                    class="anime-one"
                  />
                  <img
                    src="assets/images/anime-two.png"
                    alt="Image"
                    class="anime-two"
                  />
                </div>
              </div>
              <div
                class="broadcast__inner wow fadeInUp"
                data-wow-duration="600ms"
                data-background="assets/images/broadcast-bg.png"
              >
                <div class="row align-items-center items-gap-two">
                  <div class="col-12 col-xl-4 col-xxl-5">
                    <div class="broadcast__thumb text-xl-center">
                      <img src="assets/images/tracking.png" alt="Image" />
                    </div>
                  </div>
                  <div class="col-12 col-xl-8 col-xxl-7">
                    <div class="section__content broadcast__inner-content">
                      <h2 class="h2">Advanced Medical Query Processing</h2>
                      <div class="paragraph">
                        <p>
                          Ability to process both text-based medical queries and
                          document-based input (e.g., lab reports, doctor
                          prescriptions).
                        </p>
                      </div>
                      {/* <div class="section__content-cta">
                        <a href="register.html" class="btn btn--secondary">
                          sign up now
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div class="anime">
                  <img
                    src="assets/images/anime-three.png"
                    alt="Image"
                    class="anime-one"
                  />
                  <img
                    src="assets/images/anime-two.png"
                    alt="Image"
                    class="anime-two"
                  />
                </div>
              </div>

              <div
                class="broadcast__inner wow fadeInUp"
                data-wow-duration="600ms"
                data-background="assets/images/broadcast-bg.png"
              >
                <div class="row align-items-center items-gap-two">
                  <div class="col-12 col-xl-8 col-xxl-7">
                    <div class="section__content broadcast__inner-content">
                      <h2 class="h2">Comprehensive Medical Referrals</h2>
                      <div class="paragraph">
                        <p>
                          Delivers a curated list of nearby doctors, hospitals,
                          laboratories, and pharmacies for follow-up treatments
                          and diagnostics.
                        </p>
                      </div>
                      {/* <div class="section__content-cta">
                        <a href="contact-us.html" class="btn btn--secondary">
                          request A Demo
                        </a>
                      </div> */}
                    </div>
                  </div>
                  <div class="col-12 col-xl-4 col-xxl-5">
                    <div class="broadcast__thumb text-xl-center">
                      <img src="assets/images/Recommendation.png" alt="Image" />
                    </div>
                  </div>
                </div>
                <div class="anime">
                  <img
                    src="assets/images/anime-three.png"
                    alt="Image"
                    class="anime-one"
                  />
                  <img
                    src="assets/images/anime-two.png"
                    alt="Image"
                    class="anime-two"
                  />
                </div>
              </div>
              <div
                class="broadcast__inner wow fadeInUp"
                data-wow-duration="600ms"
                data-background="assets/images/broadcast-bg.png"
              >
                <div class="row align-items-center items-gap-two">
                  <div class="col-12 col-xl-4 col-xxl-5">
                    <div class="broadcast__thumb text-xl-center">
                      <img src="assets/images/attachment.png" alt="Image" />
                    </div>
                  </div>
                  <div class="col-12 col-xl-8 col-xxl-7">
                    <div class="section__content broadcast__inner-content">
                      <h2 class="h2">Medical Data Upload Support</h2>
                      <div class="paragraph">
                        <p>
                          Supports a wide range of file types including PDFs,
                          Word documents, images, and more, allowing users to
                          submit test reports, prescriptions, or other medical
                          files for analysis.
                        </p>
                      </div>
                      {/* <div class="section__content-cta">
                        <a href="register.html" class="btn btn--secondary">
                          sign up now
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div class="anime">
                  <img
                    src="assets/images/anime-three.png"
                    alt="Image"
                    class="anime-one"
                  />
                  <img
                    src="assets/images/anime-two.png"
                    alt="Image"
                    class="anime-two"
                  />
                </div>
              </div>

              <div
                class="broadcast__inner wow fadeInUp"
                data-wow-duration="600ms"
                data-background="assets/images/broadcast-bg.png"
              >
                <div class="row align-items-center items-gap-two">
                  <div class="col-12 col-xl-8 col-xxl-7">
                    <div class="section__content broadcast__inner-content">
                      <h2 class="h2">Recommendations and Diagnostic Tests</h2>
                      <div class="paragraph">
                        <p>
                          Automatic generation of recommended diagnostic tests,
                          medications, or follow-up consultations based on input
                          symptoms or medical data.
                        </p>
                      </div>
                      {/* <div class="section__content-cta">
                        <a href="contact-us.html" class="btn btn--secondary">
                          request A Demo
                        </a>
                      </div> */}
                    </div>
                  </div>
                  <div class="col-12 col-xl-4 col-xxl-5">
                    <div class="broadcast__thumb text-xl-center">
                      <img
                        src="assets/images/personalizedlesson.png"
                        alt="Image"
                      />
                    </div>
                  </div>
                </div>
                <div class="anime">
                  <img
                    src="assets/images/anime-three.png"
                    alt="Image"
                    class="anime-one"
                  />
                  <img
                    src="assets/images/anime-two.png"
                    alt="Image"
                    class="anime-two"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
