import React, { useState, useEffect } from "react";
// import Navbar from "./Navbar";
import Fqa from "./Faq";
// import bannerbg from "../../../../public/assets/images/banner/banner-bg.png";
import bannerbg from "../../../../src/assets/images/banner/banner-bg.png";

import animeone from "../../../../src/assets/images/anime-one.png";
import animetwo from "../../../../src/assets/images/anime-two.png";

import aboutthumb from "../../../../src/assets/images/bannerone.png";
import bose from "../../../../src/assets/images/sponsor/bose.png";

import amazon from "../../../../src/assets/images/sponsor/amazon.png";

import microsoft from "../../../../src/assets/images/sponsor/microsoft.png";

import netflix from "../../../../src/assets/images/sponsor/netflix.png";

import samsung from "../../../../src/assets/images/sponsor/samsung.png";

import toyota from "../../../../src/assets/images/sponsor/toyota.png";
import sponsorbg from "../../../../src/assets/images/sponsor/sponsor-bg.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import one from "../../../../src/assets/images/eraser/one.png";
import two from "../../../../src/assets/images/eraser/two.png";
import three from "../../../../src/assets/images/eraser/three.png";
import four from "../../../../src/assets/images/eraser/four.png";
// import Customerreview from "./Customerreview";
import updatebg from "../../../../src/assets/images/update-cta-bg.png";
import Navbar from "./Navbar";
import Footer from "../Footer";
// import Footer from "./Footer";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-scroll";

export default function Aboutus() {
  const navigate = useNavigate();
  const location = useLocation();
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  // const settingsone = {
  //   dots: false,
  //   infinite: true,
  //   arrows: false,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   autoplaySpeed: 2000,
  //   autoplay: true,
  // };
  const handleScrollClick = (to) => {
    if (location.pathname !== "/") {
      navigate("/", { state: { scrollTo: to } });
    } else {
      const element = document.getElementById(to);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  const slides = [
    {
      src: one,
      title: "Effortless Design",
      text: "Our advanced AI tools make complex tasks like designing and editing backgrounds remarkably simple.",
    },
    {
      src: two,
      title: "Precision Guaranteed",
      text: "Benefit from highly accurate results in every project, ensuring your content is flawless.",
    },
    {
      src: three,
      title: "Efficient Collaboration",
      text: "Streamline your workflow and design effectively with Vividify’s intuitive features.",
    },
    {
      src: four,
      title: "Seamless Sharing",
      text: "Share your creations effortlessly across platforms, maximizing your reach and impact.",
    },
    {
      src: one,
      title: "Effortless Design",
      text: "Our advanced AI tools make complex tasks like designing and editing backgrounds remarkably simple.",
    },
    {
      src: two,
      title: "Precision Guaranteed",
      text: "Benefit from highly accurate results in every project, ensuring your content is flawless.",
    },
    {
      src: three,
      title: "Efficient Collaboration",
      text: "Streamline your workflow and design effectively with Vividify’s intuitive features.",
    },
    {
      src: four,
      title: "Seamless Sharing",
      text: "Share your creations effortlessly across platforms, maximizing your reach and impact.",
    },
  ];
  const images = [
    bose,
    amazon,
    microsoft,
    netflix,
    samsung,
    toyota,
    bose,
    amazon,
    microsoft,
    netflix,
    samsung,
    toyota,
  ];
  useEffect(() => {
    // Function to dynamically load a script
    const loadScript = (src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return script;
    };

    // List of scripts to load
    const scripts = [
      "/assets/js/jquery-3.7.0.min.js",
      "/assets/js/bootstrap.bundle.min.js",

      "/assets/js/jquery.nice-select.min.js",
      "/assets/js/jquery.magnific-popup.min.js",
      "/assets/js/slick.min.js",
      "/assets/js/typed.umd.js",
      "/assets/js/wow.min.js",
      "/assets/js/plugins.js",
      "/assets/js/main.js",
    ];

    // Load all scripts and keep references to them
    const scriptElements = scripts.map((src) => loadScript(src));

    // Cleanup function to remove scripts when component unmounts
    return () => {
      scriptElements.forEach((script) => {
        if (script && script.parentNode === document.body) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);
  return (
    <div class="">
      <div>
        <body>
          {/* <!-- preloader --> */}
          <div id="preloader">
            <div id="loader"></div>
          </div>
          {/* <!-- ==== page wrapper start ==== --> */}
          <div class="my-app home-seven">
            <Navbar />

            {/* <!-- ==== header start ==== --> */}
            {/* <Navbar /> */}
            {/* <!-- ==== / header end ==== --> */}
            {/* <!-- ==== mobile menu start ==== --> */}

            {/* <!-- ==== / mobile menu end ==== --> */}
            {/* <!-- ==== banner start ==== --> */}
            <section
              class="section cmn-banner"
              style={{
                backgroundImage: `url(${bannerbg})`,
              }}
            >
              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <div
                      class="cmn-banner__content wow fadeInUp"
                      data-wow-duration="600ms"
                      data-wow-delay="300ms"
                    >
                      <h3 class="h3">About Medical GPT</h3>
                      <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-center">
                          <li className="li" class="breadcrumb-item">
                            <a href="/">Home</a>
                          </li>
                          {/* <li class='breadcrumb-item active' aria-current='page'>
                            Our Teams
                          </li> */}
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              <div class="anime">
                <img src={animeone} alt="Image" class="one" />
                <img src={animetwo} alt="Image" class="two" />
              </div>
            </section>
            {/* <!-- ==== / banner end ==== -->
            <!-- ==== clone start ==== --> */}
            <section class="section clone about-main">
              <div class="container">
                <div class="row items-gap align-items-center">
                  <div class="col-12 col-lg-6">
                    <div
                      class="about__thumb wow fadeInLeft"
                      data-wow-duration="600ms"
                      data-wow-delay="300ms"
                    >
                      <img src={aboutthumb} alt="Image" />
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div
                      class="clone__content section__content wow fadeInUp"
                      data-wow-duration="600ms"
                      data-wow-delay="600ms"
                    >
                      <h2 class="h2">
                        Revolutionizing Medical Knowledge with AI
                      </h2>
                      <div class="paragraph">
                        {/* <p class="p fw-5 text-lg">
                          Vividify’s Suite of Tools Empowers Effortless Content
                          Creation.
                        </p> */}
                        <p className="p">
                          MedicalGPT leverages advanced AI to provide accurate
                          answers to your medical, scientific, and biological
                          queries. Upload files and get detailed, reliable
                          insights in seconds. Designed for professionals and
                          enthusiasts alike, it ensures quick access to critical
                          information, streamlining research and decision-making
                          processes. Experience the future of medical knowledge
                          with MedicalGPT.
                        </p>
                      </div>
                      <ul>
                        <li>
                          <i class="li fa-solid fa-check"></i>
                           Comprehensive Knowledge Base
                        </li>
                        <li>
                          <i class="li fa-solid fa-check"></i>
                          AI Powered Insight
                        </li>
                        <li>
                          <i class="li fa-solid fa-check"></i>
                          Test and Diagnosis Recommendations                        </li>
                      </ul>
                      {/* <div class="tour__content-cta">
                        <div class="trust">
                          <div class="review">
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star"></i>
                          </div>
                          <p class="p fw-7">Rated Excellent on Trustpilot</p>
                        </div>
                        <div class="action">
                          <a href="/contactus" class="btn btn--primary">
                            request A Demo - its free
                          </a>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- ==== / clone end ==== -->
            <!-- ==== sponsor start ==== --> */}

            {/* <!-- ==== / sponsor end ==== -->
            <!-- ==== eraser start ==== --> */}
            <section class="section eraser eraser-two ">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-12">
                    <div class="section__header--secondary ">
                      <div class="row align-items-center items-gap">
                        <div class="col-12 col-lg-8 ">
                          <h2
                            class="h2 wow fadeInUp"
                            data-wow-duration="600ms"
                            data-wow-delay="300ms"
                          >
                            Unlock the Future of HealthCare with Medical GPT
                          </h2>
                        </div>
                        <div class="col-12 col-lg-4">
                          <div class="cmn-pagination cmn-pagination-light eraser-pagination"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 ">
                    <div class="eraser__slider">
                      <div class="eraser__slider-item">
                        <div class="thumb">
                          <img
                            src="assets/images/eraser/digital.png"
                            style={{ width: "25%" }}
                            alt="Image"
                          />
                        </div>
                        <div class="content">
                          <h5 class="h5">24/7 Availability</h5>
                          <p>
                            Get access to medical consultation anytime,
                            anywhere. Medical GPT availability provide solutions
                            to your problems.
                          </p>
                        </div>
                      </div>
                      <div class="eraser__slider-item">
                        <div class="thumb">
                          <img
                            src="assets/images/eraser/veracity.png"
                            style={{ width: "25%" }}
                            alt="Image"
                          />
                        </div>
                        <div class="content">
                          <h5 class="h5">Advance Research</h5>
                          <p>
                            Access latest medical trends and research anytime.
                            MedicalGPT helps researchers with precise, efficient
                            analysis.
                          </p>
                        </div>
                      </div>
                      <div class="eraser__slider-item">
                        <div class="thumb">
                          <img
                            src="assets/images/eraser/gear.png"
                            style={{ width: "25%" }}
                            alt="Image"
                          />
                        </div>
                        <div class="content">
                          <h5 class="h5">Data-Driven Insight</h5>
                          <p>
                            Powered by advanced LLM model, MedicalGPT offers
                            evidence-based insights to improve healthcare
                            decisions
                          </p>
                        </div>
                      </div>
                      <div class="eraser__slider-item">
                        <div class="thumb">
                          <img
                            src="assets/images/eraser/tech-support.png"
                            style={{ width: "25%" }}
                            alt="Image"
                          />
                        </div>
                        <div class="content">
                          <h5 class="h5">Health Recommendations</h5>
                          <p>
                            MedicalGPT offers tailored advice and treatment
                            suggestions based on individual patient data and
                            health history.
                          </p>
                        </div>
                      </div>
                      <div class="eraser__slider-item">
                        <div class="thumb">
                          <img
                            src="assets/images/eraser/digital.png"
                            style={{ width: "25%" }}
                            alt="Image"
                          />
                        </div>
                        <div class="content">
                          <h5 class="h5">Effortless Information Access</h5>
                          <p>
                            Our advanced AI technology makes finding accurate
                            medical information quick and easy.
                          </p>
                        </div>
                      </div>
                      <div class="eraser__slider-item">
                        <div class="thumb">
                          <img
                            src="assets/images/eraser/veracity.png"
                            style={{ width: "25%" }}
                            alt="Image"
                          />
                        </div>
                        <div class="content">
                          <h5 class="h5">Trusted Accuracy</h5>
                          <p>
                            Rely on highly accurate answers backed by credible
                            sources, ensuring trustworthy information every
                            time.
                          </p>
                        </div>
                      </div>
                      <div class="eraser__slider-item">
                        <div class="thumb">
                          <img
                            src="assets/images/eraser/gear.png"
                            style={{ width: "25%" }}
                            alt="Image"
                          />
                        </div>
                        <div class="content">
                          <h5 class="h5">Efficient Support</h5>
                          <p>
                            Streamline your healthcare queries with MedicalGPT’s
                            intuitive interface, making it simple to get the
                            answers you need.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- ==== eraser end ==== -->
            <!-- ==== faq start ==== --> */}
            {/* <Fqa /> */}
            {/* <!-- ==== / faq end ==== -->
            <!-- ==== review start ==== --> */}
            {/* <Customerreview /> */}

            {/* <!-- ==== / review end ==== -->
            <!-- ==== update cta start ==== --> */}
            {/* <section class="section update-cta pb-0 ">
              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <div
                      class="update-cta__inner bg-img wow fadeInUp"
                      data-wow-duration="600ms"
                      data-wow-delay="300ms"
                      style={{
                        backgroundImage: `url(${updatebg})`,
                      }}
                    >
                      <div class="section__header">
                        <h2 class="h2 text-white">Get updates</h2>
                        <p>
                          Sign up to our newsletter to receive information about
                          new products, special offers and updates.
                        </p>
                      </div>
                      <form action="#" method="post">
                        <div class="mail-group-two">
                          <input
                            type="email"
                            name="subscribe-news-two"
                            id="subscribeNewsTwo"
                            placeholder="enter your email"
                            required
                          />
                          <button
                            type="submit"
                            aria-label="subscribe to our newsletter"
                            class="btn btn--nonary"
                          >
                            <span>join now</span>
                            <i class="fa-regular fa-paper-plane"></i>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* <!-- ==== / update cta end ==== -->
            <!-- ==== footer start ==== --> */}
           
            <div>
              {" "}
              <footer class="footer-two section pb-0 mt-10">
                <div class="container">
                  <div class="row items-gap-two">
                    <div class="col-12 col-sm-6 col-xl-4 col-xxl-5">
                      <div
                        class="footer-two__single bt  wow fadeInUp"
                        data-wow-duration="600ms"
                      >
                        <a href="index.html" class="logo"  style={{width:"40%"}}>
                          <img src="assets/images/logo.png" alt="Image" />
                        </a>
                        <p>
                        MedicalGPT for Accurate and Reliable Medical Information, Powered by Advanced AI Technology.
                        </p>
                        <div class="social">
                          <a href="index.html" aria-label="social media">
                            <i class="fa-brands fa-facebook-f"></i>
                          </a>
                          <a href="index.html" aria-label="social media">
                            <i class="fa-brands fa-twitter"></i>
                          </a>
                          <a href="index.html" aria-label="social media">
                            <i class="fa-brands fa-linkedin-in"></i>
                          </a>
                          <a href="index.html" aria-label="social media">
                            <i class="fa-brands fa-instagram"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-xl-2 col-xxl-2 offset-xxl-1">
                      <div
                        class="footer-two__single  wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="200ms"
                      >
                        {/* <h5 class="h5">About tech AI</h5> */}
                        <ul>
                          <li>
                            <a href="/aboutus">About</a>
                          </li>
                          {/* <li>
                        <a href="blog">Blog</a>
                      </li> */}
                          <li>
                            <a href="/signin">Sign in</a>
                          </li>
                          <li>
                            <a href="/signup">Register</a>
                          </li>
                          <li>
                            <a href="/contactus">Contact</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-xl-2 col-xxl-2">
                      <div
                        class="footer-two__single  wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="400ms"
                      >
                        {/* <h5 class="h5">Free tools</h5> */}
                        <ul>
                          <li>
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                handleScrollClick("howitwork");
                              }}
                              href="#"
                            >
                              How it works
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                handleScrollClick("keyfeatuers");
                              }}
                              href="#"
                            >
                              Key Features
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleScrollClick("ourmission");
                              }}
                            >
                              Our Vision
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleScrollClick("ourmission");
                              }}
                            >
                              FAQ's
                            </a>
                          </li>

                          {/* <li>
                        <a href="use-case.html">Art Generator</a>
                      </li> */}
                        </ul>
                      </div>
                    </div>
                    {/* <div class="col-12 col-sm-6 col-xl-2 col-xxl-2">
                  <div
                    class="footer-two__single  wow fadeInUp"
                    data-wow-duration="600ms"
                    data-wow-delay="400ms"
                  >
                    <h5 class="h5">Meet Stoky</h5>
                    <ul>
                      <li>
                        <a href="about-us.html"> About Stoky</a>
                      </li>
                      <li>
                        <a href="use-case.html">Careers</a>
                      </li>
                      <li>
                        <a href="use-case.html">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="use-case.html">Sitemap</a>
                      </li>
                      <li>
                        <a href="use-case.html">Art Generator</a>
                      </li>
                    </ul>
                  </div>
                </div> */}
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="copyright text-center">
                        <p>
                          Copyright &copy;
                          <span id="copyYear"></span>
                          <a
                            style={{ marginLeft: "5px" }}
                            // href="https://www.medicalgpt.info/"
                          >
                            MedicalGPT
                          </a>
                          . Design By
                          <a
                            style={{ marginLeft: "5px" }}
                            href="https://www.datalyticsai.com/"
                          >
                            DatalyticsAI
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </div>

            {/* <!-- ==== / footer end ==== --> */}

            {/* <!-- ==== / page wrapper end ==== -->
        <!-- scroll to top --> */}
            <div class="progress-wrap ">
              <svg
                class="progress-circle svg-content"
                width="100%"
                height="100%"
                viewBox="-1 -1 102 102"
              >
                <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
              </svg>
            </div>

            {/* <!-- ==== js dependencies start ==== -->
        <!-- jquery --> */}
            <script src="/assets/js/jquery-3.7.0.min.js"></script>
            {/* <!-- bootstrap five js --> */}
            <script src="/assets/js/bootstrap.bundle.min.js"></script>
            <script src="/assets/js/jquery.nice-select.min.js"></script>

            {/* <!-- nice select js --> */}
            {/* <!-- magnific popup js --> */}
            <script src="/assets/js/jquery.magnific-popup.min.js"></script>
            {/* <!-- slick js --> */}
            <script src="/assets/js/slick.min.js"></script>
            {/* <!-- typed js --> */}
            <script src="/assets/js/typed.umd.js"></script>
            {/* <!-- wow /js --> */}
            <script src="/assets/js/wow.min.js"></script>
            {/* <!-- ==== / js dependencies end ==== -->
        <!-- plugins js --> */}
            <script src="/assets/js/plugins.js"></script>
            {/* <!-- main js --> */}
            <script src="/assets/js/main.js"></script>
          </div>
        </body>
      </div>
    </div>
  );
}
