import React, { useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { AiOutlineLock } from "react-icons/ai";

export default function Privacypolicy() {
  const [openAccordion, setOpenAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };
  return (
    <div class="my-app home-seven">
      <Navbar />
      <section class="section faq pb-0 mt-14">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-xl-6">
              <div class="flex section__header gap-2 justify-center items-center">
                <h3 class="h3">Our privacy policy</h3>
                {/* <AiOutlineLock className="mt-3" color="blue" size={32} /> */}
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-xl-10">
              <div class="accordion" id="accordion">
                <div
                  className="accordion-item wow fadeInUp"
                  data-wow-duration="600ms"
                  data-wow-delay="600ms"
                >
                  <h5 className="accordion-header" id="headingOne">
                    <button
                      className={`accordion-button ${
                        openAccordion === 1 ? "open" : ""
                      }`}
                      type="button"
                      onClick={() => toggleAccordion(1)}
                      style={{
                        color: openAccordion === 1 ? "blue" : "black",
                      }}
                    >
                      What types of personal information does MedicalGPT
                      collect?
                    </button>
                  </h5>
                  {openAccordion === 1 && (
                    <div className="accordion-collapse">
                      <div className="accordion-body">
                        <p style={{ color: "black" }}>
                          MedicalGPT collects personal information to enhance
                          user experience and deliver accurate medical insights.
                          This includes your name, email address, and contact
                          details, as well as any health-related information you
                          provide. We also gather usage data, such as IP
                          addresses and browser types, to improve our services.
                          We are committed to protecting your privacy, employing
                          encryption and industry-standard security measures to
                          secure sensitive information, including passwords and
                          health-related data.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="accordion-item wow fadeInUp"
                  data-wow-duration="600ms"
                  data-wow-delay="600ms"
                >
                  <h5 className="accordion-header" id="headingTwo">
                    <button
                      className={`accordion-button ${
                        openAccordion === 2 ? "open" : ""
                      }`}
                      type="button"
                      onClick={() => toggleAccordion(2)}
                      style={{
                        color: openAccordion === 2 ? "blue" : "black",
                      }}
                    >
                      Can I opt out of data collection?
                    </button>
                  </h5>
                  {openAccordion === 2 && (
                    <div className="accordion-collapse">
                      <div className="accordion-body">
                        <p style={{ color: "black" }}>
                          Users may limit certain types of data collection by
                          modifying account settings or contacting our support
                          team. It is important to note that some data
                          collection is essential for the core functionality of
                          MedicalGPT, including the delivery of accurate medical
                          information based on user queries. Opting out of
                          specific data collection practices may impact the
                          quality or functionality of the service. All user
                          passwords and health-related data will remain
                          confidential and protected through encryption,
                          irrespective of data collection preferences.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="accordion-item wow fadeInUp"
                  data-wow-duration="600ms"
                  data-wow-delay="600ms"
                >
                  <h5 className="accordion-header" id="headingTwo">
                    <button
                      className={`accordion-button ${
                        openAccordion === 5 ? "open" : ""
                      }`}
                      type="button"
                      onClick={() => toggleAccordion(5)}
                      style={{
                        color: openAccordion === 5 ? "blue" : "black",
                      }}
                    >
                      How long does MedicalGPT retain my information?
                    </button>
                  </h5>
                  {openAccordion === 5 && (
                    <div className="accordion-collapse">
                      <div className="accordion-body">
                        <p style={{ color: "black" }}>
                          MedicalGPT retains personal information only as long
                          as necessary to provide our services or as mandated by
                          legal requirements. For instance, basic account
                          information is kept for the duration of account
                          activity, while health-related queries and responses
                          are retained only for the duration of the active
                          session. All stored data, including personal
                          information, passwords, and health-related
                          information, is encrypted to ensure ongoing privacy
                          and security.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="accordion-item wow fadeInUp"
                  data-wow-duration="600ms"
                  data-wow-delay="600ms"
                >
                  <h5 className="accordion-header" id="headingTwo">
                    <button
                      className={`accordion-button ${
                        openAccordion === 3 ? "open" : ""
                      }`}
                      type="button"
                      onClick={() => toggleAccordion(3)}
                      style={{
                        color: openAccordion === 3 ? "blue" : "black",
                      }}
                    >
                      How can I contact MedicalGPT regarding my privacy?
                    </button>
                  </h5>
                  {openAccordion === 3 && (
                    <div className="accordion-collapse">
                      <div className="accordion-body">
                        <p style={{ color: "black" }}>
                          For any questions or concerns regarding privacy or
                          data collection practices, please contact MedicalGPT
                          at [info@datalyticsai.com] or through the contact form
                          on our website. We are dedicated to addressing any
                          issues related to personal information and will
                          respond to inquiries promptly. All communications
                          concerning privacy will be handled with the utmost
                          confidentiality, and sensitive information, including
                          passwords and health-related data, will remain
                          encrypted and secure.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {/* <div
                  className="accordion-item wow fadeInUp"
                  data-wow-duration="600ms"
                  data-wow-delay="600ms"
                >
                  <h5 className="accordion-header" id="headingTwo">
                    <button
                      className={`accordion-button ${
                        openAccordion === 4 ? "open" : ""
                      }`}
                      type="button"
                      onClick={() => toggleAccordion(4)}
                      style={{
                        color: openAccordion === 4 ? "blue" : "black",
                      }}
                    >
                      How can I contact MedicalGPT regarding my privacy?
                    </button>
                  </h5>
                  {openAccordion === 4 && (
                    <div className="accordion-collapse">
                      <div className="accordion-body">
                        <p style={{ color: "black" }}>
                          For any questions or concerns regarding privacy or
                          data collection practices, please contact MedicalGPT
                          at [info@datalyticsai.com] or through the contact form
                          on our website. We are dedicated to addressing any
                          issues related to personal information and will
                          respond to inquiries promptly. All communications
                          concerning privacy will be handled with the utmost
                          confidentiality, and sensitive information, including
                          passwords and health-related data, will remain
                          encrypted and secure
                        </p>
                      </div>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div></div>
      <Footer />
    </div>
  );
}
