

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Navbar from "./Navbar";
import Result from "./Result";
import InputBox from "./InputBox";
import { Menu } from "@headlessui/react";
import { Slider } from "@mui/material";
import slide1 from "./Landingpage/landing/slide1.jpg";
import slide2 from "./Landingpage/landing/slide2.jpg";
import slide3 from "./Landingpage/landing/slide3.jpg";

export default function Main() {
  const [submittedQuestion, setSubmittedQuestion] = useState("");
  const { t, i18n } = useTranslation();
  const [showIntro, setShowIntro] = useState(true);

  const handleQuestionSubmit = (question) => {
    setSubmittedQuestion(question);
    setShowIntro(false);
  };

  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    changeLanguage(language);
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const handleReset = () => {
    setSubmittedQuestion("");
    setShowIntro(true);
  };

  useEffect(() => {
    const intervalId = setInterval(changeText, 2000);
    return () => clearInterval(intervalId);
  }, []);

  const texts = ["Healthcare", "Biology", "Medicine"];
  const [slideIndex, setSlideIndex] = useState(0);

  const changeText = () => {
    setSlideIndex((prevIndex) => (prevIndex + 1) % 3);
  };

  return (
    <div className="flex flex-col min-h-screen ">
      <div className="flex justify-end  pr-8"></div>

      <div className="   w-full h-full flex flex-col items-center justify-center+ ">
        {showIntro && (
          <div
            className="lg:text-2xl gap-3   text-2xl md:w-[400px] lg:w-[900px] text-center   md:p-6 font-semibold m-auto w-[300px] flex"
            style={{ fontSize: "80px" }}
          >
            <h4 style={{ fontSize: "50px" }} className="h2">
              ASK QUESTIONS ABOUT
            </h4>
            <div
              id="textContainer"
              class=" main flex  gap-3 text-center justify-center fs-5 pxitems-center mb-4 text-red-500"
              // style={{ marginTop: "50px" }}
            >
              <h4
                style={{ marginTop: "-30px", fontSize: "50px" }}
                className="h2"
              >
                {texts[slideIndex]}
              </h4>
            </div>
          </div>
        )}

        <div
          className="main flex justify-center   rounded"
          style={{ marginTop: "-60px" }}
        >
          <Result
            question={submittedQuestion}
            onSubmitQuestion={handleQuestionSubmit}
            onReset={handleReset}
          />
        </div>
      </div>
    </div>
  );
}
