


// // import React from "react";
// // import { Link } from "react-router-dom";
// // import useBreadcrumbs from "use-react-router-breadcrumbs";
// // import { FaAngleRight } from "react-icons/fa"; 

// // const routes = [
// //   { path: "/", breadcrumb: "Dashboard" },
// //   { path: "/chat", breadcrumb: "Chat" },
// //   { path: "/neariest-doctor", breadcrumb: "Doctors" },
// //   { path: "/neariest-pharmacy", breadcrumb: "Pharmacy" },
// //   { path: "/neariest-laboratory", breadcrumb: "Lab" },
// // ];

// // export default function ChatHeader() {
// //   const breadcrumbs = useBreadcrumbs(routes);

// //   return (
// //     <div clasdashboardsName="flex items-center space-x-2 text-gray-600 text-sm">
// //       {breadcrumbs.map(({ match, breadcrumb }, index) => (
// //         <span key={match.pathname} className="flex items-center">
// //           {index > 0 && <FaAngleRight className="mx-1 text-gray-500" />} 
// //           <Link
// //             to={match.pathname === "/" ? "/chat" : match.pathname} 
// //             className="hover:underline"
// //           >
// //             {breadcrumb}
// //           </Link>
// //         </span>
// //       ))}
// //     </div>
// //   );
// // }

// import React from "react";
// import { Link, useLocation } from "react-router-dom";
// import useBreadcrumbs from "use-react-router-breadcrumbs";
// import { FaAngleRight } from "react-icons/fa"; 

// const routes = [
//   { path: "/", breadcrumb: "Dashboard" },
//   { path: "/chat", breadcrumb: "Chat" },
//   { path: "/nearest-doctor", breadcrumb: "Doctors" },
//   { path: "/nearest-pharmacy", breadcrumb: "Pharmacy" },
//   { path: "/nearest-laboratory", breadcrumb: "Lab" },
// ];

// export default function ChatHeader() {
//   const breadcrumbs = useBreadcrumbs(routes);
//   const location = useLocation();

//   // Extract chat_id from the query parameters of the current URL
//   const searchParams = new URLSearchParams(location.search);
//   const chatId = searchParams.get("chat_id");

//   return (
//     <div className="flex items-center space-x-2 text-gray-600 text-sm">
//       {breadcrumbs.map(({ match, breadcrumb }, index) => (
//         <span key={match.pathname} className="flex items-center">
//           {index > 0 && <FaAngleRight className="mx-1 text-gray-500" />} 
//           <Link
//             to={
//               match.pathname === "/" 
//                 ? `/chat${chatId ? `?id=${chatId}` : ""}` 
//                 : match.pathname
//             } 
//             className="hover:underline"
//           >
//             {breadcrumb}
//           </Link>
//         </span>
//       ))}
//     </div>
//   );
// }


// Chat Header 






// import React from "react";
// import { Link } from "react-router-dom";
// import useBreadcrumbs from "use-react-router-breadcrumbs";
// import { FaAngleRight } from "react-icons/fa"; 

// const routes = [
//   { path: "/", breadcrumb: "Dashboard" },
//   { path: "/chat", breadcrumb: "Chat" },
//   { path: "/neariest-doctor", breadcrumb: "Doctors" },
//   { path: "/neariest-pharmacy", breadcrumb: "Pharmacy" },
//   { path: "/neariest-laboratory", breadcrumb: "Lab" },
// ];

// export default function ChatHeader() {
//   const breadcrumbs = useBreadcrumbs(routes);

//   return (
//     <div clasdashboardsName="flex items-center space-x-2 text-gray-600 text-sm">
//       {breadcrumbs.map(({ match, breadcrumb }, index) => (
//         <span key={match.pathname} className="flex items-center">
//           {index > 0 && <FaAngleRight className="mx-1 text-gray-500" />} 
//           <Link
//             to={match.pathname === "/" ? "/chat" : match.pathname} 
//             className="hover:underline"
//           >
//             {breadcrumb}
//           </Link>
//         </span>
//       ))}
//     </div>
//   );
// }

import React from "react";
import { Link, useLocation } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { FaAngleRight } from "react-icons/fa"; 

const routes = [
  { path: "/", breadcrumb: "Dashboard" },
  { path: "/chat", breadcrumb: "Chat" },
  { path: "/nearest-doctor", breadcrumb: "Doctors" },
  { path: "/nearest-pharmacy", breadcrumb: "Pharmacy" },
  { path: "/nearest-laboratory", breadcrumb: "Lab" },
];

export default function ChatHeader() {
  const breadcrumbs = useBreadcrumbs(routes);
  const location = useLocation();

  // Extract chat_id from the query parameters of the current URL
  const searchParams = new URLSearchParams(location.search);
  const chatId = searchParams.get("id");

  return (
    <div className="flex items-center space-x-2 text-gray-600 text-sm">
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <span key={match.pathname} className="flex items-center">
          {index > 0 && <FaAngleRight className="mx-1 text-gray-500" />} 
          <Link
            to={
              match.pathname === "/" 
                ? `/chat${chatId ? `?id=${chatId}` : ""}` 
                : match.pathname
            } 
            className="hover:underline"
          >
            {breadcrumb}
          </Link>
        </span>
      ))}
    </div>
  );
}

