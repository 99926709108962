import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import bannerbg from "../../../assets/images/banner/banner-bg.png";

import animeone from "../../../assets/images/anime-one.png";
import animetwo from "../../../assets/images/anime-two.png";
import animethree from "../../../assets/images/anime-three.png";
import arrowcontact from "../../../assets/images/arrow-contact.png";
import updatecta from "../../../assets/images/update-cta-bg.png";
import Navbar from "./Navbar";
import updatebg from "../../../../src/assets/images/update-cta-bg.png";
import { useNavigate, useLocation } from "react-router-dom";

export default function Contactus() {
  const navigate = useNavigate();
  const location = useLocation();
  const handleScrollClick = (to) => {
    if (location.pathname !== "/") {
      navigate("/", { state: { scrollTo: to } });
    } else {
      const element = document.getElementById(to);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  useEffect(() => {
    // Function to dynamically load a script
    const loadScript = (src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return script;
    };

    // List of scripts to load
    const scripts = [
      "/assets/js/jquery-3.7.0.min.js",
      "/assets/js/bootstrap.bundle.min.js",

      "/assets/js/jquery.nice-select.min.js",
      "/assets/js/jquery.magnific-popup.min.js",
      "/assets/js/slick.min.js",
      "/assets/js/typed.umd.js",
      "/assets/js/wow.min.js",
      "/assets/js/plugins.js",
      "/assets/js/main.js",
    ];

    // Load all scripts and keep references to them
    const scriptElements = scripts.map((src) => loadScript(src));

    // Cleanup function to remove scripts when component unmounts
    return () => {
      scriptElements.forEach((script) => {
        if (script && script.parentNode === document.body) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);
  return (
    <div>
      <div id="preloader">
        <div id="loader"></div>
      </div>
      <body>
        {/* <!-- ==== page wrapper start ==== --> */}
        <div class="my-app home-seven">
          {/* <!-- ==== header start ==== --> */}
          <Navbar />
          {/* <!-- ==== / header end ==== -->
            <!-- ==== mobile menu start ==== --> */}

          {/* <!-- ==== / mobile menu end ==== -->
            <!-- ==== banner start ==== --> */}
          <section
            class="section cmn-banner"
            style={{
              backgroundImage: `url(${bannerbg})`,
            }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div
                    class="cmn-banner__content wow fadeInUp"
                    data-wow-duration="600ms"
                    data-wow-delay="300ms"
                  >
                    <h3 class="h3">Contact Us</h3>
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb justify-content-center">
                        <li class="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          Contact Us
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div class="anime">
              <img src={animeone} alt="Image" class="one" />
              <img src={animetwo} alt="Image" class="two" />
            </div>
          </section>
          {/* <!-- ==== / banner end ==== -->
            <!-- ==== contact start ==== --> */}
          <section class="section contact-main">
            <div class="container">
              <div class=" row items-gap align-items-center">
                {/* <div className='flex justify-between'> */}{" "}
                <div class="col-12 col-lg-6 col-xxl-5">
                  <div
                    class="contact-main__content wow fadeInUp"
                    data-wow-duration="600ms"
                    data-wow-delay="300ms"
                  >
                    <h2 class="h3">
                      Have something in mind?
                      <span>Let's talk.</span>
                    </h2>
                    <p>
                      We value your input and are here to assist with any
                      questions, feedback, or support you may need. Contact us
                      at info@datalyticsai.com.
                    </p>
                    {/* <div class="arrow">
                      <img src={arrowcontact} alt="Image" />
                    </div>
                    <div class="cta-contact">
                      <a href="tel:223-087-9756">
                        Schedule a call
                        <i class="fa-solid fa-angle-right"></i>
                      </a>
                      <span>OR</span>
                      <a href="mailto:support@techai.com">
                        Request a feature
                        <i class="fa-solid fa-angle-right"></i>
                      </a>
                    </div> */}
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xxl-6 offset-xxl-1">
                  <div
                    class="contact-main__form wow fadeInUp"
                    data-wow-duration="600ms"
                    data-wow-delay="300ms"
                  >
                    <form action="#" method="post">
                      <div
                        class="group-input wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="600ms"
                      >
                        <input
                          type="text"
                          name="contact-name"
                          id="contactName"
                          placeholder="enter full name"
                        />
                      </div>
                      <div
                        class="group-input wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="900ms"
                      >
                        <input
                          type="email"
                          name="contact-email"
                          id="contactEmail"
                          placeholder="enter Your Email"
                        />
                      </div>
                     
                      <div
                        class="group-input wow fadeInUp"
                        data-wow-duration="600ms"
                        data-wow-delay="1500ms"
                      >
                        <textarea
                          name="contact-message"
                          id="contactMessage"
                          placeholder="Write a message"
                        ></textarea>
                      </div>
                     
                      <div class="form-cta justify-content-start">
                        <button type="submit" class="btn btn--nonary">
                          Send Message
                          <i class="fa-solid fa-paper-plane"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- ==== / contact end ==== -->
            <!-- ==== update cta start ==== --> */}
          {/* <section class="section update-cta pb-0">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div
                    class="update-cta__inner bg-img wow fadeInUp"
                    data-wow-duration="600ms"
                    data-wow-delay="300ms"
                    style={{
                      backgroundImage: `url(${updatebg})`,
                    }}
                  >
                    <div class="section__header">
                      <h2 class="h2 text-white">Get updates</h2>
                      <p>
                        Sign up to our newsletter to receive information about
                        new products, special offers and updates.
                      </p>
                    </div>
                    <form action="#" method="post">
                      <div class="mail-group-two">
                        <input
                          type="email"
                          name="subscribe-news-two"
                          id="subscribeNewsTwo"
                          placeholder="enter your email"
                          required
                        />
                        <button
                          type="submit"
                          aria-label="subscribe to our newsletter"
                          class="btn btn--nonary"
                        >
                          <span>join now</span>
                          <i class="fa-regular fa-paper-plane"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <Footer />
        
        </div>
        {/* 
            <!-- ==== / update cta end ==== -->
            <!-- ==== footer start ==== --> */}

        <div class="progress-wrap">
          <svg
            class="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
        {/* <!-- ==== js dependencies start ==== -->
        <!-- jquery --> */}
        <script src="/assets/js/jquery-3.7.0.min.js"></script>
        {/* <!-- bootstrap five js --> */}
        <script src="/assets/js/bootstrap.bundle.min.js"></script>
        {/* <!-- nice select js --> */}
        <script src="/assets/js/jquery.nice-select.min.js"></script>
        {/* <!-- magnific popup js --> */}
        <script src="/assets/js/jquery.magnific-popup.min.js"></script>
        {/* <!-- slick js --> */}
        <script src="/assets/js/slick.min.js"></script>
        {/* <!-- typed js --> */}
        <script src="/assets/js/typed.umd.js"></script>
        {/* <!-- wow js --> */}
        <script src="/assets/js/wow.min.js"></script>
        {/* <!-- ==== / js dependencies end ==== --> */}
        {/* <!-- plugins js --> */}
        <script src="/assets/js/plugins.js"></script>
        {/* <!-- main js --> */}
        <script src="/assets/js/main.js"></script>
      </body>
    </div>
  );
}
