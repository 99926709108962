import React, { useState, useEffect } from "react";
import NavBar from "./Navbar";
import axios from "axios";
import { IoMdEye } from "react-icons/io";
import { FaEyeSlash } from "react-icons/fa";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "./Footer";

export default function Signin() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmpasswordVisible, setconfirmPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email_or_username: "",
    password: "",
  });
  const [error, setError] = useState("");

  const toggleconfirmPasswordVisibility = () => {
    setconfirmPasswordVisible(!confirmpasswordVisible);
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted");
    setLoading(true); 
    try {

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/api/login_v1`
        ,
        {
          email_or_username: formData.email_or_username,
          password: formData.password,
        }
      );

      console.log("Full Response data:", response);

      if (response.status === 200) {
        try {
          const { message, email, enable_2fa ,display_popup } = response.data.details;
          console.log("enable_2fa type:", enable_2fa);
          localStorage.setItem("email", email);
          // // Show success message
          // toast.success(message);
          localStorage.setItem("display_popup", JSON.stringify(display_popup));

          if (response.data.details.enable_2fa) {
            console.log("Navigating to MFA...");
            setTimeout(() => {
              navigate("/mfa");
            }, 1000);
          } else {
            const { token } = response.data.details;
            // const { display_popup } = response.data.display_popup;
            const access_token = token.access_token;
            localStorage.setItem("token", access_token);
            // localStorage.setItem("display_popup", display_popup);
          
           
            console.log("Navigating to Chat...");
            setTimeout(() => {
              navigate("/");
            }, 1000);
          }
        } catch (error) {
          console.log("Error in 2mfa", error);
        }
      }
    } catch (error) {
      if (error.response) {
        // Check for 400 status specifically
        if (error.response.status === 400) {
          const errorMessage =
            error.response.data.details.message || "An error occurred.";
          toast.error(errorMessage);
        } else {
          setError("Login failed, please check your credentials.");
        }
        console.error(error.response.data);
      }
    }
    finally {
      setLoading(false); // Set loading to false when request completes
    }
  };

  const handleScrollClick = (to) => {
    if (location.pathname !== "/") {
      navigate("/", { state: { scrollTo: to } });
    } else {
      const element = document.getElementById(to);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  useEffect(() => {
    const loadScript = (src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return script;
    };

    // List of scripts to load
    const scripts = [
      "/assets/js/jquery-3.7.0.min.js",
      "/assets/js/bootstrap.bundle.min.js",

      "/assets/js/jquery.nice-select.min.js",
      "/assets/js/jquery.magnific-popup.min.js",
      "/assets/js/slick.min.js",
      "/assets/js/typed.umd.js",
      "/assets/js/wow.min.js",
      "/assets/js/plugins.js",
      "/assets/js/main.js",
    ];

    // Load all scripts and keep references to them
    const scriptElements = scripts.map((src) => loadScript(src));

    // Cleanup function to remove scripts when component unmounts
    return () => {
      scriptElements.forEach((script) => {
        if (script && script.parentNode === document.body) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);
  return (
    <div>
      <div id="preloader">
        <div id="loader"></div>
      </div>
      <div className="my-app home-seven">
        <NavBar />
        <div className="mobile-menu d-block d-xl-none">
          <nav className="mobile-menu__wrapper">
            <div className="mobile-menu__header">
              <div className="nav__logo">
                <a href="/" aria-label="home page" title="logo">
                  <img src="assets/images/logo-four.png" alt="Image" />
                </a>
              </div>
              <button
                aria-label="close mobile menu"
                className="close-mobile-menu"
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="mobile-menu__list"></div>
            <div className="mobile-menu__social social">
              <a href="index.html" aria-label="social media">
                <i className="fa-brands fa-facebook-f"></i>
              </a>
              <a href="index.html" aria-label="social media">
                <i className="fa-brands fa-twitter"></i>
              </a>
              <a href="index.html" aria-label="social media">
                <i className="fa-brands fa-linkedin-in"></i>
              </a>
              <a href="index.html" aria-label="social media">
                <i className="fa-brands fa-instagram"></i>
              </a>
            </div>
          </nav>
        </div>
        <div className="mobile-menu__backdrop"></div>
        <section
          className="section cmn-banner"
          data-background="assets/images/banner/banner-bg.png"
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div
                  className="cmn-banner__content wow fadeInUp"
                  data-wow-duration="600ms"
                  data-wow-delay="300ms"
                >
                  <h3 className="h3">Sign In</h3>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-center">
                      <li className="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Sign In
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="anime">
            <img
              src="assets/images/anime-one.png"
              alt="Image"
              className="one"
            />
            <img
              src="assets/images/anime-two.png"
              alt="Image"
              className="two"
            />
          </div>
        </section>
        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={{
            style: {
              marginTop: "50px",
            },
          }}
        />
        <section className="section authentication pb-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10 col-xl-6">
                <div
                  className="authentication__inner wow fadeInUp"
                  data-wow-duration="600ms"
                  data-wow-delay="300ms"
                >
                  <div className="section__header text-start">
                    <h2 className="h3">Welcome Back</h2>
                  </div>
                  <form onSubmit={handleSubmit} action="#" method="post">
                    <div className="input-single">
                      <input
                        type="email_or_username"
                        id="email_or_username"
                        name="email_or_username"
                        value={formData.email_or_username}
                        onChange={handleChange}
                        placeholder="Username or Email address"
                        style={{ textTransform: "none" }}
                        required
                      />
                    </div>
                    <div
                      className="input-single flex"
                      style={{ position: "relative" }}
                    >
                      <input
                        type={passwordVisible ? "text" : "password"}
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        placeholder="Enter password"
                        required
                        style={{
                          width: "100%",
                          paddingRight: "40px",
                          textTransform: "none",
                        }}
                      />
                      <button
                        type="button"
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          padding: 0,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="password-toggle-button"
                        onClick={togglePasswordVisibility}
                      >
                        {passwordVisible ? <FaEyeSlash /> : <IoMdEye />}
                      </button>
                    </div>

                    <a href="/forgetpassword">Forget password</a>
                    <div className="form-cta">
                      <button
                        type="submit"
                        aria-label="post comment"
                        className="btn btn--ocotonary"
                      >
                       {loading ? (
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ color: "#ffffff" }}></span>
                        ) : (
                          "Sign In"
                        )}
                      </button>
                      <p>
                        Register Your account
                        <a style={{ marginLeft: "5px" }} href="/signup">
                          Sign up
                        </a>
                      </p>
                    </div>
                  </form>
                  {error && <p className="error">{error}</p>}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
     
      </div>
      <div className="progress-wrap">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
      <script src="/assets/js/jquery-3.7.0.min.js"></script>
      <script src="/assets/js/bootstrap.bundle.min.js"></script>
      <script src="/assets/js/jquery.nice-select.min.js"></script>
      <script src="/assets/js/jquery.magnific-popup.min.js"></script>
      <script src="/assets/js/slick.min.js"></script>
      <script src="/assets/js/typed.umd.js"></script>
      <script src="/assets/js/wow.min.js"></script>
      <script src="/assets/js/plugins.js"></script>
      <script src="/assets/js/main.js"></script>
    </div>
  );
}
