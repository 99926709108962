import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

export default function Blog() {
  useEffect(() => {
    // Function to dynamically load a script
    const loadScript = (src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return script;
    };

    // List of scripts to load
    const scripts = [
      "/assets/js/jquery-3.7.0.min.js",
      "/assets/js/bootstrap.bundle.min.js",

      "/assets/js/jquery.nice-select.min.js",
      "/assets/js/jquery.magnific-popup.min.js",
      "/assets/js/slick.min.js",
      "/assets/js/typed.umd.js",
      "/assets/js/wow.min.js",
      "/assets/js/plugins.js",
      "/assets/js/main.js",
    ];

    // Load all scripts and keep references to them
    const scriptElements = scripts.map((src) => loadScript(src));
    return () => {
      scriptElements.forEach((script) => {
        if (script && script.parentNode === document.body) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);
  const truncateText = (text, maxLength) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  return (
    <div>
      <div id="preloader">
        <div id="loader"></div>
      </div>
      <body>
        <div class="my-app home-seven">
          <Navbar />

          <section
            class="section cmn-banner"
            data-background="assets/images/banner/banner-bg.png"
          >
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div
                    class="cmn-banner__content wow fadeInUp"
                    data-wow-duration="600ms"
                    data-wow-delay="300ms"
                  >
                    <h3 class="h3">Blog </h3>
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb justify-content-center">
                        <li class="breadcrumb-item">
                          <a href="index.html">Home</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          Blog
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div class="anime">
              <img src="assets/images/anime-one.png" alt="Image" class="one" />
              <img src="assets/images/anime-two.png" alt="Image" class="two" />
            </div>
          </section>

          <section class="section pb-0 news-section">
            <div class="container">
              <div class="row items-gap">
                <div class="col-12 col-md-6 col-xl-4">
                  <div
                    class="news__slider-item wow fadeInUp"
                    data-wow-duration="600ms"
                    data-wow-delay="300ms"
                  >
                    <div class="thumb">
                      <a href="blog-single">
                        <img src="assets/images/blog/six.png" alt="Image" />
                      </a>
                      <div class="publish-date">
                        <h4 class="h4">21</h4>
                        <p>JAN</p>
                      </div>
                    </div>
                    <div class="content">
                      {/* <div class="tag">
                        <a href="blog-single">tech</a>
                      </div> */}
                      <h4 class="h4">
                        <a href="blog-single">
                          {truncateText(
                            "How AI is Revolutionizing Personalized Healthcare",
                            40
                          )}
                          {/* How AI is Revolutionizing Personalized Healthcare */}
                        </a>
                      </h4>
                      <div class="cta">
                        <a href="blog-single">
                          <i class="tech-arrow-top"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-xl-4">
                  <div
                    class="news__slider-item wow fadeInUp"
                    data-wow-duration="600ms"
                    data-wow-delay="300ms"
                  >
                    <div class="thumb">
                      <a href="decoding-medical">
                        <img src="assets/images/blog/five.png" alt="Image" />
                      </a>
                      <div class="publish-date">
                        <h4 class="h4">21</h4>
                        <p>JAN</p>
                      </div>
                    </div>
                    <div class="content">
                      {/* <div class="tag">
                        <a
                          href="decoding-medical
                                        
                                        "
                        >
                          tech
                        </a>
                      </div> */}
                      <h4 class="h4">
                        <a href="decoding-medical">
                          {truncateText(
                            "Decoding Medical Symptoms: When to Seek Help",
                            40
                          )}
                        </a>
                      </h4>
                      <div class="cta">
                        <a href="decoding-medical">
                          <i class="tech-arrow-top"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-xl-4">
                  <div
                    class="news__slider-item wow fadeInUp"
                    data-wow-duration="600ms"
                    data-wow-delay="300ms"
                  >
                    <div class="thumb">
                      <a href="telemedicine">
                        <img src="assets/images/blog/ten.png" alt="Image" />
                      </a>
                      <div class="publish-date">
                        <h4 class="h4">21</h4>
                        <p>JAN</p>
                      </div>
                    </div>
                    <div class="content">
                      {/* <div class="tag">
                        <a href="telemedicine">tech</a>
                      </div> */}
                      <h4 class="h4">
                        <a href="telemedicine">
                          {truncateText(
                            "The Evolution of Telemedicine: How AI is Shaping the Future of Virtual Care",
                            40
                          )}
                        </a>
                      </h4>
                      <div class="cta">
                        <a href="telemedicine">
                          <i class="tech-arrow-top"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-xl-4">
                  <div
                    class="news__slider-item wow fadeInUp"
                    data-wow-duration="600ms"
                    data-wow-delay="300ms"
                  >
                    <div class="thumb">
                      <a href="navigating-relaible">
                        <img src="assets/images/blog/nine.png" alt="Image" />
                      </a>
                      <div class="publish-date">
                        <h4 class="h4">21</h4>
                        <p>JAN</p>
                      </div>
                    </div>
                    <div class="content">
                      {/* <div class="tag">
                        <a href="navigating-relaible">tech</a>
                      </div> */}
                      <h4 class="h4">
                        <a href="navigating-relaible">
                          {truncateText(
                            " Navigating Reliable Medical Information in the Digital Age",
                            40
                          )}
                        </a>
                      </h4>

                      <div class="cta">
                        <a href="navigating-relaible">
                          <i class="tech-arrow-top"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class="col-12 col-md-6 col-xl-4">
                  <div
                    class="news__slider-item wow fadeInUp"
                    data-wow-duration="600ms"
                    data-wow-delay="300ms"
                  >
                    <div class="thumb">
                      <a href="blog-single">
                        <img src="assets/images/blog/eight.png" alt="Image" />
                      </a>
                      <div class="publish-date">
                        <h4 class="h4">21</h4>
                        <p>JAN</p>
                      </div>
                    </div>
                    <div class="content">
                      <div class="tag">
                        <a href="blog-single">tech</a>
                      </div>
                      <h4 class="h4">
                        <a href="blog-single">
                          The standard chunk of Lorem Ipsum used since the 1500s
                          is
                        </a>
                      </h4>
                      <div class="cta">
                        <a href="blog-single">
                          <i class="tech-arrow-top"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-xl-4">
                  <div
                    class="news__slider-item wow fadeInUp"
                    data-wow-duration="600ms"
                    data-wow-delay="300ms"
                  >
                    <div class="thumb">
                      <a href="blog-single">
                        <img src="assets/images/blog/nine.png" alt="Image" />
                      </a>
                      <div class="publish-date">
                        <h4 class="h4">21</h4>
                        <p>JAN</p>
                      </div>
                    </div>
                    <div class="content">
                      <div class="tag">
                        <a href="blog-single">tech</a>
                      </div>
                      <h4 class="h4">
                        <a href="blog-single">
                          making this the first true generat on the Internet
                        </a>
                      </h4>
                      <div class="cta">
                        <a href="blog-single">
                          <i class="tech-arrow-top"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </section>

          <Footer />
        </div>

        <div class="progress-wrap">
          <svg
            class="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
        {/* <!-- ==== js dependencies start ==== -->
        <!-- jquery --> */}
        <script src="assets/js/jquery-3.7.0.min.js"></script>
        {/* <!-- bootstrap five js --> */}
        <script src="assets/js/bootstrap.bundle.min.js"></script>
        {/* <!-- nice select js --> */}
        <script src="assets/js/jquery.nice-select.min.js"></script>
        {/* <!-- magnific popup js --> */}
        <script src="assets/js/jquery.magnific-popup.min.js"></script>
        {/* <!-- slick js --> */}
        <script src="assets/js/slick.min.js"></script>
        {/* <!-- typed js --> */}
        <script src="assets/js/typed.umd.js"></script>
        {/* <!-- wow js --> */}
        <script src="assets/js/wow.min.js"></script>
        {/* <!-- ==== / js dependencies end ==== --> */}
        {/* <!-- plugins js --> */}
        <script src="assets/js/plugins.js"></script>
        {/* <!-- main js --> */}
        <script src="assets/js/main.js"></script>
      </body>
    </div>
  );
}
